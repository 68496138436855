import React, { useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import VerticalBar from "../../../../../Components/VerticalBar/VerticalBar";
import { dummy_sections, graph_filters } from "../data";
import { RiArrowDropDownLine } from "react-icons/ri";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import graph1 from "../../../../../Dexta_assets/graph1.png";
import graph2 from "../../../../../Dexta_assets/graph2.png";
import graph3 from "../../../../../Dexta_assets/graph3.png";
import DropdownListInterpret from "../../../../../Components/Dropdown/DropdownInterpret";

const InterpretResults = ({ helpModal, setHelp }) => {
  const [selectedGraphFilter, setSelectedGraphFilter] = useState(
    "Your candidate pool average"
  );
  const [graphDropdown, setGraphDropdown] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const graphRef = useRef(null);
  const scoresArray = [10, 20, 30, 40, 50, 60, 70, 80];
  const handleGraphFilterClick = (title) => {
    setSelectedGraphFilter(title);
    setGraphDropdown(false);
  };
  return (
    <main className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-80 absolute inset-0"
        onClick={() => setHelp(false)}
      ></div>
      <div className="bg-white rounded-lg 2xl:w-2/6 xl:w-2/4 lg:w-2/4 h-5/6 overflow-hidden overflow-y-auto mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="bg-white rounded-lg enable-scrollbar2">
          <Scrollbars
            autoHeight
            autoHide
            autoHeightMax="760px"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="px-10 py-8">
              <div className="mt-4 text-left md:text-left">
                <p
                  className="text-xl text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  How to Interpret Test Results
                </p>
              </div>
              <div
                className="text-left text-sm md:text-left mt-5"
                style={{ fontFamily: "Silka" }}
              >
                <p className="text-coalColor mt-0">
                  The test results page provides a detailed overview of a
                  candidate's performance across multiple test modules. Below is
                  a guide to help you understand and interpret the results
                  displayed on the page.
                </p>
              </div>
              <div className="text-left md:text-left mt-5">
                <p
                  className="text-xl text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Key Elements Explained
                </p>
              </div>
              <ol
                class="list-decimal pl-10 mt-5 text-lg text-coalColor"
                style={{ fontFamily: "Archia Bold" }}
              >
                <li>
                  <div className="flex items-center">Overall Scores</div>
                  <div className="pt-10 pb-5 md:w-5/6 bg-[#DBD9C0] mt-4 mx-auto justify-center rounded flex flex-row sm:gap-14 md:gap-20">
                    <VerticalBar
                      heightOfCell="h-[19rem]"
                      EmptyCellColor="bg-[#FFF8E8]"
                      FilledCellColor="bg-[#FF5812]"
                      FilledCellHeight={65}
                      tooltip1Text="John Doe's"
                      tooltip2Text="total score"
                      tooltip3Text={65 + "%"}
                      paddingTooltip="py-3"
                      marginTopTooltip="-mt-[100px]"
                      widthtooltip="w-[7rem]"
                    />
                    <VerticalBar
                      heightOfCell="h-[19rem]"
                      EmptyCellColor="bg-[#FFF8E8]"
                      FilledCellHeight={68}
                      tooltip1Text="Your candidate"
                      FilledCellColor="bg-[#C0FF06]"
                      tooltip2Text="pool average score"
                      tooltip3Text={68 + "%"}
                      paddingTooltip="py-3"
                      marginTopTooltip="-mt-[100px]"
                      widthtooltip="w-[7rem]"
                    />
                    <VerticalBar
                      heightOfCell="h-[19rem]"
                      EmptyCellColor="bg-[#FFF8E8]"
                      FilledCellHeight={74}
                      tooltip1Text="Highest"
                      FilledCellColor="bg-[#FFB500]"
                      tooltip2Text="candidate score"
                      tooltip3Text={74 + "%"}
                      paddingTooltip="py-3"
                      marginTopTooltip="-mt-[100px]"
                      widthtooltip="w-[7rem]"
                    />
                  </div>
                  <ul
                    className="list-disc pl-6 mt-2 text-base"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Total Average Score:</strong> Represents the
                      aggregate performance of the candidate across all modules.
                    </li>
                    <li>
                      <strong>Candidate Pool Average Score:</strong> The average
                      score achieved by all your candidates who took the same
                      test.
                    </li>
                    <li>
                      <strong>Highest Candidate Score:</strong> Indicates the
                      highest score achieved by any candidate in your candidate
                      pool.
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="flex items-center mt-5">
                    Module-Specific Results{" "}
                  </div>
                  <div
                    className="grid sm:grid-cols-1 px-10 md:grid-cols-2 gap-3 mt-3"
                    style={{ fontFamily: "Silka" }}
                  >
                    {dummy_sections?.slice(0, 4).map((i, index) => (
                      <div>
                        <div className="bg-[#DBD9C0] h-[15rem] rounded flex flex-col">
                          <p
                            className="px-5 mt-3 h-20 text-base"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.name}
                          </p>
                          <div className="grid grid-cols-2 px-5 gap-3">
                            <p
                              className={`border rounded-2xl text-[11px] bg-white my-auto text-center ${
                                i?.experience === "Basic" && "border-[#0B5B23]"
                              } ${
                                i?.experience === "Intermediate" &&
                                "border-[#FFB500]"
                              } ${
                                i?.experience === "Advanced" &&
                                "border-[#FF5812]"
                              } ${i?.experience === "" && "border-[#C0FF06]"}`}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.experience != "" ? i?.experience : "custom"}
                            </p>
                            <div>
                              <p
                                className="text-center text-[11px] py-1 flex"
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.time}
                              </p>
                            </div>
                          </div>
                          <div className="flex-row gap-20 mt-20 px-10 flex justify-center ">
                            <VerticalBar
                              heightOfCell="h-14"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FF5812]"
                              FilledCellHeight={50}
                              tooltip1Text="Scored"
                              tooltip3Text={50 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                            <VerticalBar
                              heightOfCell="h-14"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FFB500]"
                              FilledCellHeight={60}
                              tooltip1Text="Highest score"
                              tooltip3Text={60 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <ul
                    className="list-disc pl-6 mt-2 text-base"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Module Title:</strong> The title and level of the
                      module (e.g., "Robotics Process Automation L1").
                    </li>
                    <li>
                      <strong>Proficiency Level:</strong> Indicated by tags like
                      "Basic", "Intermediate", or "Advanced".
                    </li>
                    <li>
                      <strong>Candidate's Score:</strong> "Number of correct
                      answers achieved by the candidate against the total number
                      of questions in the test, shown as a percentage.
                    </li>
                    <li>
                      <strong>Average Score: </strong> The average percentage
                      score of all candidates for that particular module.
                    </li>
                    <li>
                      <strong>Time Spent:</strong> The time spent on each
                      module, given in minutes (e.g., 2 mins /10 mins).
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="flex items-center mt-5">
                    Module Specific Comparative Data Points
                  </div>
                  <div className="grid grid-cols-12">
                    <p
                      className="text-coalColor text-lg py-4 col-span-4"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      {/* {data?.candidate?.candidates?.firstName}'s Test results */}
                      Module Scores
                    </p>
                    <div
                      className="my-auto w-full sm:justify-center md:justify-end col-span-8"
                      style={{ fontFamily: "Silka" }}
                    >
                      <div className="relative md:w-full">
                        <CustomButton
                          label={selectedGraphFilter}
                          id="graphID"
                          borderCustom="border border-black graphClass sm:max-md:text-xs"
                          paddingY="0.3rem"
                          iconR={RiArrowDropDownLine}
                          iconWidth="w-6 h-6"
                          justifyContent="justify-between"
                          paddingx="px-2"
                          onClickButton={() => setGraphDropdown(!graphDropdown)}
                        />
                        {graphDropdown && (
                          <div
                            className="absolute z-40 border border-coalColor graphClass right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            ref={graphRef}
                          >
                            {graph_filters?.map((i) => (
                              <DropdownListInterpret
                                key={i?.title}
                                title={i?.title}
                                onClick={() => handleGraphFilterClick(i?.title)}
                                user_package={user_package_check}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-3 px-10 mt-3">
                    {dummy_sections?.map((i, index) => (
                      <div>
                        <div className="bg-[#DBD9C0] h-[15rem] rounded flex flex-col">
                          <p
                            className="px-5 mt-3 h-20 text-base"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.name}
                          </p>
                          <div className="grid grid-cols-2 px-5 gap-3">
                            <p
                              className={`border rounded-2xl text-[11px] bg-white my-auto text-center ${
                                i?.experience === "Basic" && "border-[#0B5B23]"
                              } ${
                                i?.experience === "Intermediate" &&
                                "border-[#FFB500]"
                              } ${
                                i?.experience === "Advanced" &&
                                "border-[#FF5812]"
                              } ${i?.experience === "" && "border-[#C0FF06]"}`}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.experience != "" ? i?.experience : "custom"}
                            </p>
                            <div>
                              <p
                                className="text-center text-xs py-1 flex"
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.time}
                              </p>
                            </div>
                          </div>
                          <div className="flex-row gap-20 mt-20 px-10 flex justify-center ">
                            <VerticalBar
                              heightOfCell="h-14"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FF5812]"
                              FilledCellHeight={65}
                              tooltip1Text="Scored"
                              tooltip3Text={65 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                            {i?.experience !== "" && (
                              <>
                                {selectedGraphFilter ===
                                  "Your best performing candidate" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-14"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#FFB500]"
                                    FilledCellHeight={74}
                                    tooltip1Text="Highest score"
                                    tooltip3Text={74 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                                {selectedGraphFilter ===
                                  "Your candidate pool average" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-14"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#C0FF06]"
                                    FilledCellHeight={68}
                                    tooltip1Text="Average"
                                    tooltip3Text={68 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                                {selectedGraphFilter ===
                                  "Dexta candidate pool average" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-14"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#252E3A]"
                                    FilledCellHeight={72}
                                    tooltip1Text="Dexta average"
                                    tooltip3Text={72 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                                {selectedGraphFilter ===
                                  "Dexta best performing candidate" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-14"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#A16207]"
                                    FilledCellHeight={85}
                                    tooltip1Text="Dexta Best"
                                    tooltip3Text={85 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="text-base mt-3" style={{ fontFamily: "Silka" }}>
                    On the top right corner of the results page, there is a
                    dropdown menu that allows you to compare the candidate's
                    score for each module against different benchmarks. These
                    options provide additional context for assessing the
                    candidate's performance at a module specific level:
                  </p>
                  <ul
                    className="list-disc pl-6 text-base mt-5"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Your Best Performing Candidate</strong>
                      <ul class="custom-list pl-5 mt-2">
                        <li>
                          Compares the candidate's score against the highest
                          score achieved by your best performing candidate for a
                          specific module
                        </li>
                        <li>
                          Helps to identify how the candidate score compares to
                          the top performer for each module
                        </li>
                      </ul>
                    </li>
                    <li className="mt-3">
                      <strong> Your Candidate Pool Average</strong>
                      <ul class="custom-list pl-5 mt-2">
                        <li>
                          Compares the candidate's scores to the average score
                          of your candidate pool.
                        </li>
                        <li>
                          Provides insight into how the candidate performs
                          relative to your general pool of candidates.
                        </li>
                      </ul>
                    </li>
                    <li className="mt-3">
                      <strong>Dexta Candidate Pool Average</strong>
                      <ul class="custom-list pl-5 mt-2">
                        <li>
                          Compares the candidate's scores to a broader pool
                          average provided by Dexta.
                        </li>
                        <li>
                          Allows for a comparison against a larger, potentially
                          more diverse set of candidates, providing a wider
                          context for the candidate's performance.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="flex items-center mt-5">
                    Performance & Completion Time Insights
                  </div>
                  <p className="text-base mt-3" style={{ fontFamily: "Silka" }}>
                    Provides visual representations of the candidate's
                    performance and completion time in the form of box plots and
                    distribution curves.
                  </p>
                  <img src={graph1} className="mt-3 rounded-lg" />
                  <ul
                    className="list-disc pl-6 text-base mt-5"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Performance Insights</strong>
                      <ul class="custom-list pl-5 mt-2">
                        <li>
                          <strong>Box Plot:</strong> Shows the distribution of
                          scores within the candidate pool, including minimum,
                          first quartile (Q1), median, third quartile (Q3), and
                          maximum scores.
                        </li>
                        <li>
                          <strong>Distribution Curve:</strong> Overlays a
                          Gaussian distribution to give a sense of where the
                          candidate's score lies within the overall
                          distribution.
                        </li>
                        <li>
                          <strong>Candidate's Score Marker:</strong> Indicates
                          the candidate's score on the distribution curve and
                          box plot, helping to visualize their performance
                          relative to others.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <img src={graph2} className="mt-3 rounded-lg" />
                  <ul
                    className="list-disc pl-6 text-base mt-5"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Completion Time Insights</strong>
                      <ul class="custom-list pl-5 mt-2">
                        <li>
                          <strong>Box Plot:</strong> Displays the distribution
                          of completion times, providing insight into how
                          quickly or slowly the candidate completed the test
                          compared to others.
                        </li>
                        <li>
                          <strong>Distribution Curve:</strong> Shows a Gaussian
                          distribution of completion times to contextualize the
                          candidate's performance.
                        </li>
                        <li>
                          <strong>Candidate's Completion Time Marker:</strong>{" "}
                          Highlights the candidate's completion time on the
                          distribution curve and box plot.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="flex items-center">Anti-Cheating Monitor</div>
                  <img src={graph3} className="mt-3" />
                  <ul
                    className="list-disc pl-6 mt-2 text-base"
                    style={{ fontFamily: "Silka" }}
                  >
                    <li>
                      <strong>Device Used: </strong> Indicates the type of
                      device used for the test (e.g., Desktop).
                    </li>
                    <li>
                      <strong>Location: </strong> The location from which the
                      test was completed.
                    </li>
                    <li>
                      <strong>IP Address Check: </strong> Indicates whether the
                      test was completed once from the IP address.
                    </li>
                    <li>
                      <strong>Webcam Enabled:</strong> States whether the webcam
                      was enabled during the test.
                    </li>
                    <li>
                      <strong>Full-Screen Mode Active:</strong> Indicates if
                      full-screen mode was active throughout the test.
                    </li>
                  </ul>
                </li>
              </ol>
              <div className="text-left md:text-left mt-5">
                <p
                  className="text-xl text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Interpretation Guide
                </p>
                <ol
                  class="list-decimal pl-10 mt-5 text-lg text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  <li>
                    <div className="flex items-center">
                      Understanding Overall Performance
                    </div>
                    <ul
                      className="list-disc pl-6 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        Compare the candidate's total score to the candidate
                        pool average and the highest candidate score to gauge
                        relative performance.
                      </li>
                      <li>
                        A total score below the candidate pool average suggests
                        areas for improvement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="flex items-center">
                      Evaluating Module Performance
                    </div>
                    <ul
                      className="list-disc pl-6 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        Review individual module scores to identify strengths
                        and weaknesses.
                      </li>
                      <li>
                        Compare the candidate's score in each module to the
                        average score to see how they performed relative to
                        peers.
                      </li>
                      <li>
                        Consider the time spent on each module as it may
                        indicate the difficulty or familiarity with the content.{" "}
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="flex items-center">
                      Using Comparative Data Points
                    </div>
                    <ul
                      className="list-disc pl-6 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        Use the dropdown options to switch between different
                        benchmarks and gain a comprehensive view of the
                        candidate's performance.
                      </li>
                      <li>
                        Comparing against the best performing candidate can
                        highlight top-tier performance expectations.
                      </li>
                      <li>
                        Comparing against the candidate pool average and Dexta's
                        candidate pool average provides a broader understanding
                        of where the candidate stands.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="flex items-center">
                      Analyzing Performance & Completion Time Insights
                    </div>
                    <ul
                      className="list-disc pl-6 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        Use the box plots and distribution curves to see how the
                        candidate's scores and completion times compare to the
                        overall distribution.
                      </li>
                      <li>
                        Identifying whether the candidate's scores and times
                        fall within, above, or below the average can provide
                        deeper insights into their performance.
                      </li>
                      <li>
                        Completion time insights help understand if the
                        candidate completed the test much faster or slower than
                        average, which could indicate either strong knowledge or
                        a rushed approach.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <div className="flex items-center">
                      Anti-Cheating Measures
                    </div>
                    <ul
                      className="list-disc pl-6 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        Verify that all anti-cheating measures were
                        appropriately followed, ensuring the integrity of the
                        test results.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </main>
  );
};

export default InterpretResults;
