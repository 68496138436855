import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateUserDetails } from "../hooks/updateUserDetails";
import axios from "axios";
import CheckoutPayment from "./checkoutPayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast, ToastContainer, Zoom } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

export default function PayPlan({
  paymentOpen,
  setPaymentOpen,
  refetch,
  shouldUpdate,
  setShouldUpdate,
  subscriptionID,
}) {
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const [countryModal, setCountryModal] = useState(false);
  const [searchCountry, setsearchCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [paymentDone, setPaymentDone] = useState(false);
  const [loading, setIsloading] = useState(false);
  const countryRef = useRef(null);
  const [user, setUser] = useState({
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "",
  });

  //#region handling input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  //#endregion

  useEffect(() => {
    if (paymentDone === true) {
      handleSubmit();
    }
  }, [paymentDone]);

  //#region Handling submit
  const handleSubmit = () => {
    let data = {
      id: userID,
      data2: JSON.stringify({
        city: user?.city,
        country: user?.country,
        line1: user?.address1,
        line2: user?.address2,
        state: user?.state,
        postal_code: user?.zip,
      }),
    };
    try {
      mutate(data);
    } catch (error) {
      //onError will automatically detect
    }
  };

  const { mutate, isLoading: mutateLoading } = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/subscriptions/updateUserDetail");
      setPaymentDone(false);
      setIsloading(false);
      setUser({
        address1: "",
        address2: "",
        city: "",
        zip: "",
        state: "",
        country: "",
      });
      setShouldUpdate(false);
      toast.success(`Subscription Successful`);
    },
  });
  //#endregion

  //#region filtering countries based on user input
  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );
  //#endregion

  //#region handle outside click
  useEffect(() => {
    function handleOutsideCountry(event) {
      if (
        countryRef.current &&
        !countryRef.current.contains(event.target) &&
        event.target.id !== "country" &&
        !event.target.closest(".countryClass")
      ) {
        setCountryModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideCountry);
    return () => {
      document.removeEventListener("mousedown", handleOutsideCountry);
    };
  }, [countryRef]);
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countryNames = response.data.map((country) => country.name.common);
      setCountries(countryNames);
    };
    getCountry();
  }, []);
  //#endregion

  return (
    <Transition.Root show={paymentOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setPaymentOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-[#F0F0F0] text-left shadow-xl transition-all w-full h-full">
                <div className="bg-[#F0F0F0] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start justify-between py-4 w-1/3 mx-auto">
                    <h1
                      className="text-2xl font-bold select-text"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      Billing details & payment
                    </h1>
                    <IoMdClose
                      className="w-4 h-4 cursor-pointer"
                      onClick={() => setPaymentOpen(false)}
                    />
                  </div>
                  <div className="sm:flex py-4 w-1/3 mx-auto">
                    <form
                      className="w-full"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      {/* focus:shadow transition duration-500 focus:scale-105 */}
                      <p
                        className="text-[0.93rem] text-[#30313d]"
                        style={{ fontFamily: "Silka" }}
                      >
                        Address Line1 (Optional)
                      </p>
                      <input
                        style={{ fontFamily: "Silka" }}
                        class="transform mt-1 shadow rounded w-full py-3 px-3 text-gray-700 leading-tight"
                        name="address1"
                        type="text"
                        placeholder="Address Line1 (Optional)"
                        value={user.address1}
                        onChange={handleInputChange}
                      />
                      <p
                        style={{ fontFamily: "Silka" }}
                        className="text-[0.93rem] mt-4 text-[#30313d]"
                      >
                        Address Line2 (Optional)
                      </p>
                      <input
                        style={{ fontFamily: "Silka" }}
                        class="transform mt-0.5 shadow  rounded w-full py-3 px-3 text-gray-700 leading-tight"
                        name="address2"
                        type="text"
                        placeholder="Address Line2 (Optional)"
                        value={user.address2}
                        onChange={handleInputChange}
                      />
                      <div
                        className="flex flex-row gap-3 w-full"
                        style={{ fontFamily: "Silka" }}
                      >
                        <div className="w-1/2">
                          <p className="text-[0.93rem] mt-4 text-[#30313d]">
                            City (Optional)
                          </p>
                          <input
                            class="transform mt-0.5 shadow  rounded w-full py-3 px-3 text-gray-700 leading-tight"
                            name="city"
                            type="text"
                            placeholder="City (Optional)"
                            value={user.city}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-1/2">
                          <p className="text-[0.93rem] mt-4 text-[#30313d]">
                            Zip (Optional)
                          </p>
                          <input
                            class="transform mt-0.5  shadow  rounded w-full py-3 px-3 text-gray-700 leading-tight"
                            name="zip"
                            type="text"
                            placeholder="Zip (Optional)"
                            value={user.zip}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div
                        className="flex flex-row w-full gap-3"
                        style={{ fontFamily: "Silka" }}
                      >
                        <div className="w-1/2">
                          <p className="text-[0.93rem] mt-4 text-[#30313d]">
                            State (Optional)
                          </p>
                          <input
                            class="transform shadow mt-0.5 w-full  rounded py-3 px-3 text-gray-700 leading-tight"
                            name="state"
                            type="text"
                            placeholder="State (Optional)"
                            value={user.state}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="w-1/2">
                          <div className="w-full relative">
                            <p className="text-[0.93rem] mt-4 text-[#30313d]">
                              Country (Optional)
                            </p>
                            <input
                              class="transform countryClass shadow rounded w-full py-3 px-3 text-gray-700 leading-tight"
                              name="country"
                              type="text"
                              placeholder="Country"
                              value={user.country}
                              onClick={() => setCountryModal(!countryModal)}
                              onChange={(e) => {
                                handleInputChange(e);
                                setsearchCountry(e.target.value);
                              }}
                            />
                            {countryModal && (
                              <div
                                id="education-drop"
                                className="absolute countryClass right-0 top-full h-[300px] mt-2 overflow-scroll bg-white rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                                style={{ zIndex: 999 }}
                              >
                                {filteredCountries.map((i, index) => (
                                  <ul
                                    key={index}
                                    className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                    ref={countryRef}
                                    onClick={() => {
                                      setUser({ ...user, country: i });
                                      setCountryModal(false);
                                    }}
                                  >
                                    <li>
                                      <a className="block px-5 py-2 text-sm font-medium">
                                        {i}
                                      </a>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutPayment
                    paymentDone={paymentDone}
                    setPaymentDone={setPaymentDone}
                    loading={loading}
                    setIsloading={setIsloading}
                    setPaymentOpen={setPaymentOpen}
                    refetch={refetch}
                    setShouldUpdate={setShouldUpdate}
                    shouldUpdate={shouldUpdate}
                    subscriptionID={subscriptionID}
                  />
                </Elements>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
