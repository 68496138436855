import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "../../../Components/Loading/Loading.css";
import "../../../Components/Loading/Loading3.css";
import { resendVerification } from "../MyAssessments/Assessments-main/hooks/resendVerification";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "../MyAssessments/Assessments-main/tooltip.css";
import http from "../../../http";
import { getallcandidates } from "../MyAssessments/Assessments-main/hooks/getallcadidates";
import { useRef } from "react";
import { deleteUser } from "../MyAssessments/Assessments-main/hooks/deleteUser";
import { useDispatch } from "react-redux";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import {
  setCandidateToFalse,
  setCandidateToTrue,
} from "../../../redux/reducers/CandidateBack/CandidateSlice";
import Premium from "../../../Components/Modals/Premium";
import { useSelector } from "react-redux";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import { setTourStepData } from "../../../redux/reducers/TourSteps/TourStepsSlice";
import { settourtofalse } from "../../../redux/reducers/toggleTour/ToggleSlice";
import {
  setTourToCompleted,
  setTourToIncomplete,
} from "../../../redux/reducers/TourCompleted/TourCompletedSlice";
import { stepsCandidates, assessment_statuses } from "./data";
import AssessmentsIcon from "../../../Dexta_assets/navbar2.png";
import TextFieldSmall from "../../../Components/TextFieldSmall/TextFieldSmall";
import SearhBar from "../../../Dexta_assets/searchBar.png";
import dropdownnavbar from "../../../Dexta_assets/dropdownnavbar.png";
import eye from "../../../Dexta_assets/eye.png";
import bin from "../../../Dexta_assets/bin.png";
import DeleteModal from "../../../Components/ConfirmationModals/DeleteModal";
import DeleteIcon from "../../../Dexta_assets/deleteIcon.png";
import TablePagination from "../../../Components/TablePagination.js/TablePagination";
import { RiFileListLine } from "react-icons/ri";
import { getAccess } from "../MyAssessments/Assessments-main/hooks/getAccess";
import GeneralModal from "../../../Components/Modals/GeneralModal";
import useWindowSize from "../../../Helpers/useWindowSize";

const Candidates = () => {
  const [userData, setUserData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const [selecteditem, setselecteditem] = useState("All");
  const [selectedEntries, setSelectedEntries] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const candidateRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [candidateID, setCandidateID] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [assessmentID, setAssessmentId] = useState(0);
  const dispatch = useDispatch();
  const [order, setOrder] = useState("DESC");
  const [category, setCategory] = useState("");
  const [incompleteModal, setIncompleteModal] = useState(false);
  const [reminderLoading, setReminderLoading] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumOpen, setPremiumOpen] = useState(false);
  const tourStepCheck = useSelector((state) => state.tourStep.settourStep);
  const [stepNumber, setStepNumber] = useState(1);
  const [show, setShow] = useState(false);
  const [tourExitedManually, setTourExitedManually] = useState(false);
  const [tourCompleted, setTourCompleted] = useState(false);
  const tourCompletedState = useSelector(
    (state) => state.tourCompleted.setCompleted
  );
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [isAscending, setIsAscending] = useState(Array(7).fill(true));
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  const [searchCandidates, setSearchCandidates] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [assessmentDropdown, setAssessmentDropdown] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingVerify, setLoadingVerify] = useState(true);
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  //#region Fetch user Data
  const getUserData = async () => {
    setIsLoading(true);
    const response = await http.get(`/users/${userID}`);
    setUserData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  //#endregion

  //#region Verification
  const handleResendVerification = () => {
    try {
      mutate();
      toast.promise(resolveAfter3Sec, {
        pending: "Send Email",
        success: "Email Sent",
        error: "error",
      });
    } catch (err) {}
  };

  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));

  const { mutate, isLoading: mutateLoad } = useMutation(resendVerification, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/resend-confirm-email");
    },
    onError: (error) => {},
  });
  //#endregion

  //#region mutation callback to check if cancel clicked or not
  useEffect(() => {
    // Function to handle button click
    const handleSkipButtonClick = () => {
      dispatch(setTourStepData(99));
    };
    const observerCallback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const button = document.querySelector(".introjs-skipbutton");
          if (button) {
            button.addEventListener("click", handleSkipButtonClick);
            observer.disconnect();
          }
        }
      }
    };
    const observer = new MutationObserver(observerCallback);
    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      observer.disconnect();
      const button = document.querySelector(".introjs-skipbutton");
      if (button) {
        button.removeEventListener("click", handleSkipButtonClick);
      }
    };
  }, []);
  //#endregion

  //#region fetch candidates list
  const {
    data: dataR,
    isLoading: isAssessmentsLoading,
    error: AssessmentsError,
  } = useQuery(
    [
      "assessment",
      selectedStatus,
      selectedEntries,
      selectedPage,
      order,
      category,
      searchCandidates,
      currentPage,
    ],
    () =>
      getallcandidates(
        selectedStatus,
        selectedEntries,
        selectedPage,
        order,
        category,
        searchCandidates,
        currentPage
      )
  );

  //#endregion

  //#region Delete user from assessment
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } =
    useMutation(deleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
        toast.success("User has been deleted.");
        setDeleteModal(false);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    });

  const handleDeleteUser = () => {
    let data = {
      ID: {
        assessmentID: assessmentID,
      },
      ID2: {
        candidateID: candidateID,
      },
    };

    try {
      deleteUserMutate(data);
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region Function to handle hover event
  const handleHover = (index) => {
    setHoveredColumn(index);
  };

  const handleClear = () => {
    setHoveredColumn(null);
  };

  const handleIconClick = (index) => {
    setIsAscending((prev) => {
      const newIsAscending = [...prev];
      newIsAscending[index] = !newIsAscending[index];
      return newIsAscending;
    });
  };
  //#endregion

  //#region Handling row click
  const HandleassessmentClick = (id, status, candy) => {
    dispatch(setCandidateToTrue(true));
    navigate(`/details/${id}?user_ID=${candy}`);
  };
  //#endregion

  //#region Send reminder email to candidate
  const reminderFunction = async (candidateID, assessmentID) => {
    setReminderLoading(true);
    try {
      const response = await http.get(
        `/assessment/send-reminder/${assessmentID}/candidate/${candidateID}`
      );
      if (response.status == 200) {
        toast.success("The candidate has been sent a reminder.", {
          style: { width: "400px" },
        });
        setReminderLoading(false);
      }
    } catch (error) {
      console.error(error.response.data);
    }
  };
  //#endregion

  //#region Displaying tour of candidate
  useEffect(() => {
    if (tourStepCheck === 3) {
      setTimeout(() => {
        setShow(true);
      }, 2000);
    }
  }, [tourStepCheck]);
  //#endregion

  //#region handling exit functions
  const onExit = () => {
    setTourExitedManually(true);
    console.log("candidate exit");
  };

  const onExitCandidate = () => {
    dispatch(setTourStepData(99));
    console.log("candidate exit");
  };
  //#endregion

  //#region Handle outside click
  useEffect(() => {
    function handleOutsideAssessmentDropdown(event) {
      if (
        candidateRef.current &&
        !candidateRef.current.contains(event.target) &&
        event.target.id !== "assessment" &&
        !event.target.closest(".assessmentClass")
      ) {
        setAssessmentDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideAssessmentDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideAssessmentDropdown
      );
    };
  }, [candidateRef]);
  //#endregion

  //#region Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //#endregion

  //#region static load
  useEffect(() => {
    setTimeout(() => {
      setLoadingVerify(false);
    }, 1000);
  }, [loadingVerify]);
  //#endregion

  // useEffect(() => {
  //   // Create a style element
  //   const style = document.createElement("style");
  //   style.type = "text/css";
  //   style.innerHTML = `
  //     @media (max-width: 1366px) {
  //       .introjs-tooltip {
  //         top: auto !important;
  //       }
  //     }
  //   `;

  //   // Append the style to the head
  //   document.head.appendChild(style);

  //   // Cleanup the style element when the component unmounts
  //   return () => {
  //     document.head.removeChild(style);
  //   };
  // }, []);

  //#region Fetching and Filtering Assessments
  const {
    data: dataAccess,
    isLoading: accessLoading,
    error: accessError,
  } = useQuery(["/assessment/user-can-create", userID], () =>
    getAccess(userID)
  );
  //#endregion

  return (
    <>
      {tourStepCheck === 3 && (
        <Steps
          enabled={show}
          options={{
            hideNext: false,
            exitOnOverlayClick: false,
            exitOnFinish: false,
            doneLabel: "Next",
            exitOnEsc: false,
          }}
          steps={stepsCandidates}
          onComplete={() => {
            dispatch(setTourToCompleted(true));
            setShow(false);
            if (user_role === "recruiter") {
              dispatch(setTourStepData(6));
            } else {
              dispatch(setTourStepData(4));
            }
            dispatch(settourtofalse(false));
            dispatch(setTourToIncomplete(false));
          }}
          onExit={(tour) => {
            if (!tourCompletedState) {
              if (!tourExitedManually) {
                onExit();
              } else {
                onExitCandidate();
              }
            }
          }}
          initialStep={0}
          onBeforeChange={(value) => {
            setStepNumber(value);
          }}
        />
      )}
      {isLoading ? (
        <div class="loader-container-1">
          <div class="loader-1"></div>
        </div>
      ) : (
        <>
          <ToastContainer
            position="top-center"
            transition={Zoom}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
          <GeneralModal
            generalModal={generalModal}
            setGeneralModal={setGeneralModal}
            heading={heading}
            description={description}
          />
          <div className="bg-[#F6F7F7]">
            <div className="lg:container lg:px-0 sm:px-4 bg-[#F6F7F7] mx-auto pb-10">
              <div className="pt-[7rem] sm:px-0 md:px-2">
                {!userData?.confirmed &&
                  !isAssessmentsLoading &&
                  !loadingVerify && (
                    <div
                      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-10 rounded-lg relative"
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-red-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold">
                            Please verify your email address
                          </p>
                          <p className="text-sm">
                            To complete registration, please click the link in
                            the email we have sent you to your provided email
                            address.{" "}
                          </p>
                          <p
                            onClick={() => handleResendVerification()}
                            className="underline cursor-pointer"
                          >
                            Click here to send again.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="flex justify-between">
                  <div className="flex flex-row gap-2">
                    <img src={AssessmentsIcon} className="w-4 h-4 my-auto" />
                    <h1
                      className="sm:text-xl md:text-2xl my-auto font-bold select-text text-coalColor"
                      id="step3"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      My Candidates
                    </h1>
                  </div>
                  {user_role !== "hiring-manager" && (
                    <div className="flex relative" id="cad1">
                      <button
                        className={`inline-flex items-center border ${
                          !userData?.confirmed || user_role === "hiring-manager"
                            ? "bg-[#D3D5D8] text-[#7C8289]"
                            : "bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border-coalColor"
                        } px-4 lg:mt-4 md:mt-0 py-3 text-base font-medium rounded-md `}
                        onClick={() => {
                          if (!dataAccess?.isAllowed) {
                            setHeading("You’ve reached your limit!");
                            setdescription(
                              user_package_check === "free"
                                ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                                : "Pro subscription allows 5 Active Tests, either change the status of an Active Test to ‘Draft’ or upgrade your subscription package"
                            );
                            setGeneralModal(true);
                          } else {
                            localStorage.setItem("current_module", "general");
                            localStorage.setItem("assessment_ID", "null");
                            navigate("/create-test");
                          }
                        }}
                        disabled={
                          !userData?.confirmed || user_role === "hiring-manager"
                        }
                      >
                        <RiFileListLine
                          alt="Add Transaction Icon"
                          className="w-4 my-auto h-5 mr-2 icon-image"
                        />
                        Create new test
                      </button>
                      {!userData?.confirmed && (
                        <div className="tooltip w-[15rem] z-40 text-center absolute top-full left-0 opacity-0 pointer-events-none">
                          Please verify your email first 👍
                        </div>
                      )}
                      {user_role === "hiring-manager" && (
                        <div className="tooltip w-[15rem] z-40 text-center absolute top-full left-0 opacity-0 pointer-events-none">
                          You do not have permissions to create test
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex justify-between sm:gap-4 md:gap-0 mt-5">
                  <div className="md:w-1/5" id="cad4">
                    <TextFieldSmall
                      label="Search candidates"
                      value={searchCandidates}
                      onChangeValue={(e) => setSearchCandidates(e.target.value)}
                      backgroundTextColor="bg-[#F6F7F7]"
                      thickBorder="border-[1px]"
                      topTextWeight="font-medium"
                      image={!isMobile ? SearhBar : null}
                      placeholder="Search candidates"
                      textSize="text-[12px]"
                      paddingY="py-3"
                      dimension="w-4 h-4"
                    />
                  </div>
                  <div className="flex flex-row gap-5">
                    <div className="flex flex-col gap-3 relative" id="cad3">
                      <TextFieldSmall
                        id="assessment"
                        label="Tests"
                        value={selecteditem}
                        textSize="text-[13px]"
                        backgroundTextColor="bg-[#F6F7F7]"
                        paddingY="py-[11px]"
                        thickBorder="border-[1px]"
                        topTextWeight="font-medium"
                        image={dropdownnavbar}
                        dimension="w-2.5 h-1.5"
                        onClick={() =>
                          setAssessmentDropdown(!assessmentDropdown)
                        }
                        addionalClasses="cursor-pointer user-select-none caret-transparent"
                      />
                      {assessmentDropdown && (
                        <div
                          id="education-drop"
                          className=" absolute z-40 border border-coalColor py-2 px-2 assessmentClass right-0 top-full h-[10rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                          ref={candidateRef}
                        >
                          {assessment_statuses.map((i) => (
                            <ul
                              key={i.name}
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              style={{ fontFamily: "Silka" }}
                              onClick={() => {
                                setselecteditem(i?.name);
                                setSelectedStatus(i?.value);
                                setAssessmentDropdown(false);
                              }}
                            >
                              <li>
                                <a className="block px-5 py-2 text-sm font-medium">
                                  {i?.name}
                                </a>
                              </li>
                            </ul>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="relative overflow-x-auto shadow-md px-4 bg-white sm:rounded-lg mt-10"
                  id="cad2"
                >
                  <table className="w-full sm:text-xs md:text-sm sm:text-left md:text-left text-white ">
                    <thead
                      className="sm:text-xs md:text-lg text-coalColor bg-white"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      <tr>
                        {["Name", "Email", "Test", "Score", "Status"].map(
                          (header, index) => (
                            <th
                              key={index}
                              scope="col"
                              className={`sm:px-3 md:px-6 sm:py-2 md:py-5 ${
                                hoveredColumn === index ? "hovered-column" : ""
                              }`}
                              onMouseEnter={() => handleHover(index)}
                              onMouseLeave={handleClear}
                              style={index === 0 ? { width: "25%" } : {}}
                            >
                              <div className="flex flex-row">
                                {header}
                                {index < 5 && ( // Exclude last two columns
                                  <a
                                    className="my-auto mt-2"
                                    href="#"
                                    onClick={() => handleIconClick(index)}
                                  >
                                    {hoveredColumn === index ? (
                                      isAscending[index] ? (
                                        <FaArrowDown
                                          className="ml-2"
                                          onClick={() => {
                                            setCategory(header.toLowerCase());
                                            setOrder("DESC");
                                          }}
                                        />
                                      ) : (
                                        <FaArrowUp
                                          className="ml-2"
                                          onClick={() => {
                                            setCategory(header.toLowerCase());
                                            setOrder("ASC");
                                          }}
                                        />
                                      )
                                    ) : (
                                      <svg
                                        className="w-4 h-4 ms-1.5"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                      ></svg>
                                    )}
                                  </a>
                                )}
                              </div>
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {isAssessmentsLoading ? (
                        <tr>
                          <td colSpan="7" className="text-center">
                            <div className="bg-white">
                              <div class="loader-container-3">
                                <div class="loader-3"></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {dataR?.data?.map((i, index) => (
                            <tr
                              key={index}
                              className="bg-white odd:bg-[#F6F7F7] text-coalColor cursor-pointer"
                              style={{ fontFamily: "Silka" }}
                              onClick={(e) => {
                                const isActionsColumn =
                                  e.target.closest(".clickable");
                                if (isActionsColumn) {
                                  HandleassessmentClick(
                                    i?.assessments?.id,
                                    i?.status,
                                    i?.candidates?.id
                                  );
                                }
                              }}
                            >
                              <td className="md:px-6 sm:px-3 sm:py-3 clickable">
                                {i?.candidates?.firstName}{" "}
                                {i?.candidates?.lastName}
                              </td>
                              <td className="md:px-6 sm:px-3 sm:py-3 clickable">
                                {i?.candidates?.email}
                              </td>
                              <td className="md:px-6 sm:px-3 sm:py-3 clickable">
                                {i?.assessments?.name}
                              </td>
                              <td className="md:px-6 sm:px-3 sm:py-3 clickable">
                                {Number(i?.score).toFixed(0)}
                                {i?.score != null ? "%" : null}
                              </td>
                              <td className="md:px-6 sm:px-3 sm:py-3 clickable">
                                {i.status === "completed" && "Completed"}
                                {i.status === "started" && "Started"}
                                {i.status === "InProgress" && "In Progress"}
                                {i.status === "invited" && "Invited"}
                              </td>
                              <td className="md:px-6 sm:px-3 sm:py-3 w-20">
                                <div className="flex flex-row w-20">
                                  <div className="flex flex-row gap-4 my-auto">
                                    <img
                                      src={eye}
                                      className="w-6 h-3 my-auto flex align-center"
                                      onClick={() =>
                                        navigate(
                                          `/details/${i?.assessments?.id}?user_ID=${i?.candidates?.id}`
                                        )
                                      }
                                    />
                                    {user_role !== "hiring-manager" && (
                                      <img
                                        src={bin}
                                        className="w-3 h-4 my-auto flex align-center"
                                        onClick={() => {
                                          setAssessmentId(i?.assessments?.id);
                                          setCandidateID(i?.candidates?.id);
                                          setDeleteModal(true);
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  {dataR?.data?.length === 0 && (
                    <div className="px-6 mt-3">
                      <div className="border border-[#FF5812] py-4 rounded">
                        <p
                          className="text-alertRed text-center"
                          style={{ fontFamily: "Silka Bold" }}
                        >
                          No candidates found
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="mt-8 ml-2">
                    <TablePagination
                      totalCount={
                        dataR?.meta?.pageCount === 0
                          ? 1
                          : dataR?.meta?.pageCount
                      }
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {deleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          onClick={handleDeleteUser}
          header="Delete your candidate"
          icon={DeleteIcon}
          loading={deleteUserLoading}
          buttonText="Delete candidate"
          description=" Are you sure you want to delete your candidate? This action cannot be undone."
        />
      )}

      {/* Displaying error incase user didnt submmit his assessment */}
      {incompleteModal && (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
          style={{ zIndex: 999 }}
        >
          <div class="relative p-4 w-full max-w-lg max-h-full">
            <div class="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-[#4A9CB9]/90 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:text-white"
                data-modal-hide="popup-modal"
                onClick={() => setIncompleteModal(false)}
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
              <div class="p-4 md:p-5 text-center">
                <svg
                  class="mx-auto mb-4 text-[#4A9CB9] w-12 h-12 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h3 class="mb-5 text-lg font-medium text-gray-800 ">
                  This candidate has not completed their assessment yet.
                  Therefore there are no test results to show.
                </h3>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Candidates;
