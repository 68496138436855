import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoMdClose } from "react-icons/io";
import { Currencies, type, LoadingMap } from "./data";
import { useQuery } from "@tanstack/react-query";
import { getPackageDetailsUser } from "../hooks/getPackageDetails";
import ReactHtmlParser from "react-html-parser";
import styles from "./styling.module.css";
import styles2 from "./styling2.module.css";
import styles3 from "./styling3.module.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./modals.css";
import { getwebfeatures } from "../hooks/getwebfeatures";
import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { upgradePackage } from "../../Pages/Profile/Settings/hooks/upgradePackage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getUserPackage } from "../../Pages/Profile/Settings/hooks/getUserPackage";
import Loader from "react-loader-spinner";
import { useSelector, useDispatch } from "react-redux";
import { createSubscription } from "../../Pages/Profile/Settings/hooks/createSubscription";
import PayPlan from "./Payforplan";
import { setPlanDetails } from "../../redux/reducers/PlanDetails/PlanDetailsSlice";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";
import { toast, ToastContainer, Zoom } from "react-toastify";
import star from "../../Dexta_assets/star.png";
import GeneralModal from "./GeneralModal";

export default function Plans({ plansModal, setPlansModal }) {
  const cancelButtonRef = useRef(null);
  const [selecteditem, setSelectedItem] = useState("gbp");
  const [selectedType, setSelectedType] = useState("month");
  const userID = localStorage.getItem("CP-USER-ID");
  const [selectedSign, setSelectedSign] = useState("£");
  const queryClient = useQueryClient();
  const [loadingPackage, setLoadingPackage] = useState(0);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [paymentOpen, setPaymentOpen] = useState(false);
  const dispatch = useDispatch();
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("Confirm Package Upgrade");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [description, setdescription] = useState(
    "By proceeding with the upgrade, your package will be updated, and the required amount will be deducted from your account"
  );
  const [packageDetails, setPackageDetails] = useState({
    id: null,
    selectedItem: null,
    selectedType: null,
    stripeProductID: null,
    stripePriceID: null,
    stripeYearlyPriceID: null,
  });

  //#region Fetching packages data from api
  const { data, isLoading, error } = useQuery({
    queryKey: ["packages", selecteditem, selectedType],
    queryFn: () => getPackageDetailsUser(selecteditem, selectedType),
  });
  //#endregion

  //#region Fetching packages data from api
  const {
    data: featureData,
    isLoading: featureLoading,
    error: featureError,
  } = useQuery({ queryKey: ["web-features"], queryFn: () => getwebfeatures() });
  //#endregion

  //#region Upgrade Subscription Status
  const UpdgradePackage = (details) => {
    const {
      id,
      selectedItem,
      selectedType,
      stripeProductID,
      stripePriceID,
      stripeYearlyPriceID,
    } = details;
    const postData = JSON.stringify({
      subscription: subscriptionData?.id,
      package: id,
      stripeProductID: stripeProductID,
      stripePriceID:
        selectedType === "year" ? stripeYearlyPriceID : stripePriceID,
      currency: selectedItem,
      interval: selectedType,
      description: "Upgrading Subscription",
    });
    try {
      packageMutate(postData);
    } catch (err) {
      console.error(err.message);
    }
  };

  const { mutate: packageMutate, isLoading: upgradeLoading } = useMutation(
    upgradePackage,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("/subscriptions/upgrade");
        setGeneralModal(false);
        dispatch(setPackageData(res?.code));
        toast.success("You have successfully changed your package", {
          style: { width: "450px" },
        });
        setLoadingPackage(0);
      },
    }
  );

  const { mutate: subscriptionMutate, isLoading: subcriptionLoading } =
    useMutation(createSubscription, {
      onSuccess: () => {
        queryClient.invalidateQueries("/subscriptions");
      },
    });

  //#endregion

  //#region Fetch user Subscription Details
  const {
    data: subscriptionData,
    isLoading: subscriptionLoading,
    refetch,
  } = useQuery(["/subscriptions/subscriptionsByUserId", userID], () =>
    getUserPackage(userID)
  );

  useEffect(() => {
    refetch();
  }, [paymentOpen, refetch]);

  useMemo(() => {
    if (subscriptionData?.interval) {
      setSelectedType(subscriptionData?.interval);
    }
  }, [subscriptionLoading]);
  //#endregion

  //#region check upgrade or downgrade
  const getText = (i) => {
    const userSubscription = subscriptionData;
    const userSubscriptionPackage = subscriptionData?.package;
    if (i.id == 1 && i.id == userSubscriptionPackage?.id) {
      return i.code === "free" ? i.name : undefined;
    } else if (i.id == userSubscriptionPackage?.id) {
      if (userSubscription?.interval == selectedType) {
        return i?.name;
      }
      return selectedType === "month" ? "Downgrade" : "";
    } else {
      if (i?.code == "free") {
        return "Downgrade";
      }
      if (i?.code == "pro" && userSubscriptionPackage?.code == "free") {
        return "Upgrade";
      }
      if (i?.code == "pro" && userSubscriptionPackage?.code == "Enterprise") {
        return "Downgrade";
      }
      if (i?.code == "Enterprise" && userSubscriptionPackage?.code != i?.code) {
        return "Upgrade";
      }
    }
  };
  //#endregion

  document.title = "Plans | Dexta";

  return (
    <Transition.Root show={plansModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setPlansModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <PayPlan
          paymentOpen={paymentOpen}
          setPaymentOpen={setPaymentOpen}
          refetch={refetch}
          shouldUpdate={shouldUpdate}
          setShouldUpdate={setShouldUpdate}
          subscriptionID={shouldUpdate ? subscriptionData?.id : null}
        />
        <GeneralModal
          generalModal={generalModal}
          setGeneralModal={setGeneralModal}
          heading={heading}
          description={description}
          generalCase={true}
          onButtonClick={() => UpdgradePackage(packageDetails)}
          buttonText="Upgrade"
          loadingBtn={upgradeLoading || subscriptionLoading}
          loadingText="Upgrading"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-bodyColor text-left shadow-xl transition-all w-full h-full">
                <div className="bg-bodyColor px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start justify-between py-4">
                    <div></div>
                    <h1
                      className="text-3xl font-bold select-text"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Pick a plan that works for you
                    </h1>
                    <IoMdClose
                      className="w-5 h-5 cursor-pointer"
                      onClick={() => setPlansModal(false)}
                    />
                  </div>
                </div>
                <div className="px-4 py-3 flex md:w-3/4 mt-4 mx-auto flex-col">
                  <div className="flex md:flex-row sm:flex-col justify-between w-full">
                    <div
                      className="flex flex-row items-center border-black py-1 px-2 mt-2 border-2 bg-white rounded-lg font-medium"
                      style={{ fontFamily: "Silka" }}
                    >
                      {Currencies.map((i) => (
                        <button
                          type="button"
                          className={`text-black flex hover:text-white hover:bg-coalColor ${
                            selecteditem === i.value
                              ? "bg-coalColor text-white"
                              : "bg-white text-coalColor"
                          } focus:outline-none font-medium rounded-lg sm:text-xs md:text-sm sm:px-6 md:px-8 sm:py-3 md:py-4 text-center`}
                          onClick={() => {
                            setSelectedItem(i.value);
                            setSelectedSign(i?.sign);
                          }}
                        >
                          {i.title}
                        </button>
                      ))}
                    </div>
                    <div
                      className="flex flex-row items-center sm:w-3/5 md:w-auto md:justify-end border-black md:py-0 sm:py-1 sm:pl-4 md:pl-2 px-2 mt-2 border-2 rounded-lg font-medium"
                      style={{ fontFamily: "Silka" }}
                    >
                      {type.map((i) => (
                        <button
                          type="button"
                          className={`text-black flex hover:text-white hover:bg-coalColor ${
                            selectedType === i.value
                              ? "bg-coalColor text-white"
                              : "bg-white text-black"
                          } focus:outline-none font-medium rounded-lg sm:text-xs md:text-sm sm:px-6 md:px-8 sm:py-3 md:py-4 text-center`}
                          onClick={() => setSelectedType(i.value)}
                        >
                          {i.title}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="mt-5 grid md:grid-cols-3 gap-3">
                    {isLoading ? (
                      <>
                        {LoadingMap?.map((i) => (
                          <div
                            className={`p-5 border rounded-lg border-gray-300 shadow-[0_3px_10px_rgb(0,0,0,0.2)] bg-white loading-card`}
                          >
                            <div className="h-[130px]">
                              <div className="flex justify-between">
                                <SkeletonTheme
                                  baseColor="#e2e2e2"
                                  highlightColor="#bdbdbd"
                                >
                                  <h1 className="text-4xl font-bold w-1/2">
                                    <Skeleton count={1} />
                                  </h1>
                                </SkeletonTheme>
                              </div>
                              <SkeletonTheme
                                baseColor="#e2e2e2"
                                highlightColor="#bdbdbd"
                              >
                                <p className="mt-4 text-sm">
                                  <Skeleton count={1} />
                                </p>
                              </SkeletonTheme>
                            </div>
                            <div className="flex flex-row">
                              <SkeletonTheme
                                baseColor="#e2e2e2"
                                highlightColor="#bdbdbd"
                              >
                                <p className="text-4xl w-1/4">
                                  <Skeleton count={1} />
                                </p>
                              </SkeletonTheme>
                            </div>
                            <SkeletonTheme
                              baseColor="#e2e2e2"
                              highlightColor="#bdbdbd"
                            >
                              <p className="italic text-gray-500 text-xs mt-2">
                                <Skeleton count={1} />
                              </p>
                            </SkeletonTheme>

                            <SkeletonTheme
                              baseColor="#e2e2e2"
                              highlightColor="#bdbdbd"
                            >
                              <div className="w-full mt-5">
                                <Skeleton count={1} height={50} />
                              </div>
                            </SkeletonTheme>

                            <div className="mt-10">
                              <SkeletonTheme
                                baseColor="#e2e2e2"
                                highlightColor="#bdbdbd"
                              >
                                <div className="w-1/2">
                                  {" "}
                                  <Skeleton count={4} />
                                </div>
                              </SkeletonTheme>
                            </div>
                            <hr className="w-full mt-5 bg-gray-500 border-1" />
                            <div className="mt-6 px-2">
                              <SkeletonTheme
                                baseColor="#e2e2e2"
                                highlightColor="#bdbdbd"
                              >
                                <div>
                                  {" "}
                                  <Skeleton count={4} />
                                </div>
                              </SkeletonTheme>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {data?.data?.map((i) => (
                          <div
                            className={`border rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] loadingplan ${
                              i?.subscribeBy === null &&
                              "bg-white shadow-[0_3px_10px_rgb(0,0,0,0.2)]"
                            }`}
                          >
                            <div
                              className={`grid grid-cols-12 sm:px-3 2xl:px-5 py-3 rounded-t-lg ${
                                i?.code == "free" && "bg-[#C0FF06]"
                              } ${i?.code == "pro" && "bg-[#FFB500]"} ${
                                i?.code == "Enterprise" &&
                                "bg-[#FF5812] text-white"
                              }`}
                            >
                              <div className="col-span-9 flex flex-col">
                                <div className="flex flex-row">
                                  <h1
                                    className="xl:text-lg 2xl:text-2xl font-bold"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    {i?.name === "Free forever"
                                      ? "Explore for free"
                                      : i?.name}
                                  </h1>
                                  {i?.discountPrice > 0 &&
                                    i?.code !== "free" && (
                                      <p
                                        className="bg-coalColor my-auto rounded-lg text-xs 3xl:text-base text-white flex flex-row px-3 ml-2 3xl:ml-5"
                                        style={{ fontFamily: "Silka" }}
                                      >
                                        <img
                                          src={star}
                                          className="w-3 h-3 mr-2 3xl:mt-1"
                                        />
                                        SAVE
                                        <span className="ml-1">
                                          {/* {selectedSign !== "SAR" &&
                                            selectedSign} */}
                                          {Intl.NumberFormat("en-US", {
                                            style: "decimal",
                                            minimumFractionDigits: 0,
                                          }).format(
                                            Math.floor(i?.discountPrice)
                                          )}
                                          {/* {selectedSign === "SAR" && (
                                            <span className="ml-1">
                                              {selectedSign}
                                            </span>
                                          )} */}
                                        </span>
                                      </p>
                                    )}
                                  {i?.code === "pro" &&
                                    i?.prices[0]?.interval === "month" && (
                                      <p
                                        className="bg-coalColor my-auto text-xs 3xl:text-base rounded-lg text-white flex flex-row px-3 ml-2 3xl:ml-5"
                                        style={{ fontFamily: "Silka" }}
                                      >
                                        <img
                                          src={star}
                                          className="w-3 h-3 mr-2 3xl:mt-1"
                                        />
                                        Most popular
                                      </p>
                                    )}
                                </div>
                                <p
                                  className="mt-2 xl:text-sm 2xl:text-base"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {i.subHeading}
                                </p>
                              </div>
                              <div className="col-span-3 flex flex-col">
                                <div className="flex flex-col ml-auto">
                                  <p
                                    className="flex items-center"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    {selectedType === "year" ? (
                                      <span
                                        className={`text-base 3xl:text-2xl font-bold tracking-tight ${
                                          i?.code == "Enterprise"
                                            ? "text-white"
                                            : "text-coalColor"
                                        }`}
                                      >
                                        {selectedSign !== "SAR" && selectedSign}
                                        {i?.prices[0]?.price
                                          ? Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 0,
                                            }).format(
                                              Math.floor(
                                                i.prices[0]?.price / 12
                                              )
                                            )
                                          : 0}
                                        {selectedSign === "SAR" && (
                                          <span className="ml-1">
                                            {selectedSign}
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        className={`text-base 3xl:text-2xl font-bold tracking-tight ${
                                          i?.code == "Enterprise"
                                            ? "text-white"
                                            : "text-coalColor"
                                        }`}
                                      >
                                        {selectedSign !== "SAR" && selectedSign}
                                        {i?.prices[0]?.price
                                          ? Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 0,
                                            }).format(
                                              Math.floor(i?.prices[0]?.price)
                                            )
                                          : 0}
                                        {selectedSign === "SAR" && (
                                          <span className="ml-1">
                                            {selectedSign}
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </p>
                                  <span
                                    className={`xl:text-xs 2xl:text-sm text-coalColor ${
                                      i?.code == "Enterprise" && "text-white"
                                    }`}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    {selectedType === "year"
                                      ? "upfront"
                                      : "per month"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="p-5">
                              <div
                                className="text-coalColor xl:text-xs 2xl:text-sm mt-5 space-y-1"
                                style={{ fontFamily: "Silka Light" }}
                              >
                                {i?.code == "free" && (
                                  <>
                                    <p>
                                      Run
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        1 Active Test
                                      </b>
                                    </p>
                                    <p>
                                      Send Test to
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        5 Candidates
                                      </b>
                                    </p>
                                    <p>
                                      Managed by
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        1 Account User
                                      </b>
                                    </p>
                                  </>
                                )}
                                {i?.code == "pro" && (
                                  <>
                                    <p>
                                      Run
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        5 Active Tests
                                      </b>
                                    </p>
                                    <p>
                                      Send Test to
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        Unlimited Candidates
                                      </b>
                                    </p>
                                    <p>
                                      Managed by
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        5 Account Users
                                      </b>
                                    </p>
                                  </>
                                )}
                                {i?.code == "Enterprise" && (
                                  <>
                                    <p>
                                      Run
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        Unlimited Active Tests
                                      </b>
                                    </p>
                                    <p>
                                      Send Test to
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        Unlimited Candidates
                                      </b>
                                    </p>
                                    <p>
                                      Managed by
                                      <b style={{ fontFamily: "Silka Bold" }}>
                                        {" "}
                                        Unlimited Account Users
                                      </b>
                                    </p>
                                  </>
                                )}
                              </div>
                              <div
                                className="mt-6 px-2 md:h-[27rem]"
                                style={{ fontFamily: "Silka" }}
                              >
                                <div
                                  className={
                                    i?.code == "free"
                                      ? styles["html-content"]
                                      : i?.code == "pro"
                                      ? styles2["html-content"]
                                      : styles3["html-content"]
                                  }
                                >
                                  {ReactHtmlParser(i?.description)}
                                </div>
                              </div>
                              {getText(i) !== "Downgrade" &&
                                getText(i) !== "" && (
                                  <div className="h-5 xl:mt-10 2xl:mt-0">
                                    {selectedType === "year" ? (
                                      <p className="italic text-gray-500 text-xs text-center mt-2">
                                        1-year commitment, pay {}
                                        {selectedSign !== "SAR" && selectedSign}
                                        {i.prices[0]?.price
                                          ? Intl.NumberFormat("en-US", {
                                              style: "decimal",
                                              minimumFractionDigits: 0,
                                            }).format(i.prices[0]?.price)
                                          : 0}
                                        {selectedSign === "SAR" && (
                                          <span className="ml-1">
                                            {selectedSign}
                                            {}
                                          </span>
                                        )}
                                        {} upfront
                                      </p>
                                    ) : (
                                      <>
                                        {(getText(i) !== "Downgrade" &&
                                          getText(i) !== "") ||
                                          (i?.subscribeBy !== null &&
                                            subscriptionData?.package?.name ===
                                              i?.name &&
                                            subscriptionData?.package?.prices[0]
                                              ?.interval ===
                                              i?.prices[0]?.interval && (
                                              <p className="italic text-gray-500 text-xs text-center mt-2">
                                                Zero commitment, cancel anytime
                                              </p>
                                            ))}
                                      </>
                                    )}
                                  </div>
                                )}
                              {getText(i) !== "Downgrade" &&
                                getText(i) !== "" && (
                                  <button
                                    style={{ fontFamily: "Silka" }}
                                    type="button"
                                    className={`text-black flex mt-5 
   ${
     i?.subscribeBy !== null && subscriptionData?.package?.name === i?.name
       ? "bg-white text-gray-400 border cursor-not-allowed border-gray-400"
       : "bg-coalColor hover:bg-coalColor/90 hover:text-white text-white"
   }
    focus:outline-none font-medium rounded-md text-md px-8 py-3 text-center justify-center w-full`}
                                    disabled={
                                      i?.subscribeBy !== null &&
                                      subscriptionData?.package?.name ===
                                        i?.name &&
                                      subscriptionData?.package?.prices[0]
                                        ?.interval === i?.prices[0]?.interval
                                    }
                                    onClick={() => {
                                      if (user_package_check === "free") {
                                        dispatch(
                                          setPlanDetails({
                                            name: i?.name,
                                            id: i?.id,
                                            selectedType,
                                            productID: i?.stripeProductID,
                                            priceID: i?.stripePriceID,
                                            yearlyID: i?.stripeYearlyPriceID,
                                            selectedSign,
                                            selectedAmount: i?.prices[0]?.price,
                                            selecteditem,
                                          })
                                        );
                                        setPaymentOpen(true);
                                      } else {
                                        dispatch(
                                          setPlanDetails({
                                            name: i?.name,
                                            id: i?.id,
                                            selectedType,
                                            productID: i?.stripeProductID,
                                            priceID: i?.stripePriceID,
                                            yearlyID: i?.stripeYearlyPriceID,
                                            selectedSign,
                                            selectedAmount: i?.prices[0]?.price,
                                            selecteditem,
                                          })
                                        );
                                        setPaymentOpen(true);
                                        setShouldUpdate(true);
                                      }
                                    }}
                                  >
                                    {getText(i)}
                                    {/* {(upgradeLoading || subscriptionLoading) &&
                                    loadingPackage === i?.id ? (
                                      <span className="flex items-center justify-center">
                                        <Loader
                                          type="Oval"
                                          color="white"
                                          height={20}
                                          width={20}
                                        />
                                        <span className="ml-2">
                                          {" "}
                                          {getText(i) === "Downgrade"
                                            ? "Downgrading"
                                            : "Upgrading"}
                                        </span>
                                      </span>
                                    ) : (
                                      <>{getText(i)}</>
                                    )} */}
                                  </button>
                                )}
                              {getText(i) !== "Downgrade" &&
                                getText(i) !== "" && (
                                  <hr className="w-full mt-5 bg-gray-500 border-1" />
                                )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <p className="mx-auto my-20" style={{ fontFamily: "Silka" }}>
                    <b>Fair usage statement:</b> Unlimited evaluation for
                    internal or direct recruitment only. <b>Contact us</b> for
                    recruiting agencies or sourcing platforms pricing.
                  </p>
                </div>
                <div className="bg-white mb-10 flex md:w-3/4 text-black mx-auto px-4 py-3 rounded-lg shadow fade-in-image">
                  <table className="text-left w-full">
                    <thead>
                      <tr>
                        <th className="w-[70%]"></th>
                        {data?.data?.map((subscription, inx) => (
                          <th
                            key={inx}
                            className={`w-[10%] py-3 text-left px-4`}
                          >
                            {subscription?.name === "Free forever"
                              ? "Explore for free"
                              : subscription?.name}
                          </th>
                        ))}
                      </tr>
                      <tr className="">
                        <th className="w-[70%]"></th>
                        {data?.data?.map((_, subIndex) => (
                          <th
                            key={`check-${subIndex}`}
                            colSpan="1"
                            className="w-1/6 px-4"
                          ></th>
                        ))}
                      </tr>
                    </thead>
                    {featureData?.map((category, index) => (
                      <React.Fragment key={`category-${index}`}>
                        <thead>
                          <tr className="hover:bg-[#DBD9C0]">
                            <th
                              className="w-[70%] text-coalColor px-5 py-3 border-b"
                              colSpan="1"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              {category?.category_name}
                            </th>
                            {data?.data?.map((_, subIndex) => (
                              <th
                                key={`sub-${subIndex}`}
                                colSpan="1"
                                className="w-1/6 px-4 border-b"
                                style={{ fontFamily: "Archia Bold" }}
                              ></th>
                            ))}
                          </tr>
                        </thead>
                        <tbody style={{ fontFamily: "Silka" }}>
                          {category?.features?.map((feature, featureIndex) => (
                            <tr
                              key={`feature-${featureIndex}`}
                              className="hover:bg-[#DBD9C0]"
                            >
                              <td className="w-[70%] sm:text-sm md:text-base text-black px-5 py-3">
                                {feature?.name}
                              </td>
                              <td className="w-1/6 px-4 py-3">
                                {feature?.isFree ? <GiCheckMark /> : null}
                              </td>
                              <td className="w-1/6 px-4 py-3">
                                {feature?.isPro ? <GiCheckMark /> : null}
                              </td>
                              <td className="w-1/6 px-4 py-3">
                                {feature?.isEnterprise ? <GiCheckMark /> : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </React.Fragment>
                    ))}
                  </table>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
