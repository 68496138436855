import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import "../../../../Components/Loading/Loading5.css";
import "../../../../Components/Loading/Loading6.css";
import * as moment from "moment";
import { getAssessments } from "./hooks/getAssessments";
import "../../../../Components/Loading/Loading3.css";
import http from "../../../../http";
import { resendVerification } from "./hooks/resendVerification";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "./tooltip.css";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import { useSelector, useDispatch } from "react-redux";
import {
  settourtofalse,
  settourtotrue,
} from "../../../../redux/reducers/toggleTour/ToggleSlice";
import TextFieldSmall from "../../../../Components/TextFieldSmall/TextFieldSmall";
import { assessments, steps } from "./data";
import { setTourStepData } from "../../../../redux/reducers/TourSteps/TourStepsSlice";
import { setTourToIncomplete } from "../../../../redux/reducers/TourCompleted/TourCompletedSlice";
import AssessmentsIcon from "../../../../Dexta_assets/navbar1.png";
import SearhBar from "../../../../Dexta_assets/searchBar.png";
import dropdownnavbar from "../../../../Dexta_assets/dropdownnavbar.png";
import eye from "../../../../Dexta_assets/eye.png";
import bin from "../../../../Dexta_assets/bin.png";
import createIcon from "../../../../Dexta_assets/createIcon.png";
import TablePagination from "../../../../Components/TablePagination.js/TablePagination";
import { deleteAssessment } from "./hooks/deleteAssessment";
import DeleteIcon from "../../../../Dexta_assets/deleteIcon.png";
import DeleteModal from "../../../../Components/ConfirmationModals/DeleteModal";
import { RiFileListLine } from "react-icons/ri";
import { getAccess } from "./hooks/getAccess";
import GeneralModal from "../../../../Components/Modals/GeneralModal";
import "./general.css";

const Home = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selecteditem, setselecteditem] = useState("active");
  const [userData, setUserData] = useState("");
  const [stepNumber, setStepNumber] = useState(1);
  const [show, setShow] = useState(false);
  const userID = localStorage.getItem("CP-USER-ID");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(true);
  const [searchAssessment, setSearchAssessment] = useState("");
  const [assessmentDropdown, setAssessmentDropdown] = useState(false);
  const assessmentRef = useRef(null);
  const [selectedEntries, setSelectedEntries] = useState(10);
  const tourStepCheck = useSelector((state) => state.tourStep.settourStep);
  const [firsttourcompleted, setFirstTourCompleted] = useState(false);
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [selectstatus, setSelectedStatus] = useState("Active tests");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState(0);
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");

  //#region static loading for verification
  useEffect(() => {
    setTimeout(() => {
      setLoadingVerify(false);
    }, 1000);
  }, [loadingVerify]);
  //#endregion

  //#region Handling refs
  useEffect(() => {
    function handleOutsideAssessmentDropdown(event) {
      if (
        assessmentRef.current &&
        !assessmentRef.current.contains(event.target) &&
        event.target.id !== "assessment" &&
        !event.target.closest(".assessmentClass")
      ) {
        setAssessmentDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideAssessmentDropdown);
    return () => {
      document.removeEventListener(
        "mousedown",
        handleOutsideAssessmentDropdown
      );
    };
  }, [assessmentRef]);
  //#endregion

  //#region putting api params in one object
  let filter = {
    userID: localStorage.getItem("CP-USER-ID"),
    filterType: selecteditem,
    search: searchAssessment,
    page: currentPage,
    entries: selectedEntries,
  };
  //#endregion

  //#region Fetching and Filtering Assessments
  const {
    data: dataR,
    isLoading: isAssessmentsLoading,
    error: AssessmentsError,
  } = useQuery(["assessment", filter], () => getAssessments(filter));
  //#endregion

  //#region Fetching and Filtering Assessments
  const {
    data: dataAccess,
    isLoading: accessLoading,
    error: accessError,
  } = useQuery(["/assessment/user-can-create", userID], () =>
    getAccess(userID)
  );
  //#endregion

  //#region Fetching data and handling first time login
  const getUserData_2 = async () => {
    setIsLoading(true);
    const response = await http.get(`/users/${userID}`);
    setUserData(response.data);
    if (response.data.isUserOnboard === true) {
      navigate("/dashboard");
    } else {
      navigate("/step-1");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUserData_2();
  }, []);
  //#endregion

  //#region Send verification email
  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 3000));
  const { mutate, isLoading: mutateLoad } = useMutation(resendVerification, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/resend-confirm-email");
    },
    onError: (error) => {},
  });
  const handleResendVerification = () => {
    try {
      mutate();
      toast.promise(resolveAfter3Sec, {
        pending: "Send Email",
        success: "Email Sent",
        error: "error",
      });
    } catch (err) {}
  };
  //#endregion

  //#region handling Exit
  const onExit = () => {
    setShow(false);
    dispatch(settourtofalse(true));
    dispatch(setTourStepData(99));
    dispatch(setTourToIncomplete(false));
    // console.log("exit got called on assessments")
  };
  //#endregion

  //#region change current page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  //#endregion

  //#region Handle delete assessment
  const handleDeleteAccount = () => {
    let data = {
      assessmentID: assessmentId,
    };
    try {
      deleteMutate(data);
      setTimeout(() => {
        toast.success("Test has been deleted");
      }, 500);
    } catch (err) {}
  };

  //#region Deleting Assessment
  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteAssessment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
        setDeleteModal(false);
      },
    }
  );
  //#endregion

  //#endregion

  document.title = "Dashboard | Dexta";

  return (
    <>
      <Steps
        enabled={tourStepCheck === 11}
        steps={steps}
        initialStep={0}
        options={{
          hideNext: false,
          exitOnOverlayClick: false,
          exitOnFinish: false,
          doneLabel: "Next",
          exitOnEsc: false,
        }}
        onComplete={() => {
          setFirstTourCompleted(true);
          setShow(false);
          dispatch(setTourStepData(2));
          dispatch(settourtofalse(false));
          dispatch(setTourToIncomplete(false));
        }}
        onExit={(tour) => {
          if (!firsttourcompleted) {
            onExit();
          }
        }}
        onBeforeChange={(value) => {
          setStepNumber(value);
        }}
      />
      {isLoading ? (
        <div class="loader-container-1">
          <div class="loader-1"></div>
        </div>
      ) : (
        <>
          <ToastContainer
            position="top-center"
            transition={Zoom}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <GeneralModal
            generalModal={generalModal}
            setGeneralModal={setGeneralModal}
            heading={heading}
            description={description}
          />
          <div className="bg-[#F6F7F7]">
            <div className="lg:container lg:px-0 sm:px-4 bg-[#F6F7F7] mx-auto pb-10">
              <div className="pt-[7rem] sm:px-0 md:px-2">
                {!userData?.confirmed && !isLoading && !loadingVerify && (
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-10 rounded-lg relative"
                    role="alert"
                  >
                    <div className="flex">
                      <div className="py-1">
                        <svg
                          className="fill-current h-6 w-6 text-red-500 mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold">
                          Please verify your email address
                        </p>
                        <p className="text-sm">
                          To complete registration, please click the link in the
                          email we have sent you to your provided email address.{" "}
                        </p>
                        <p
                          onClick={() => handleResendVerification()}
                          className="underline cursor-pointer"
                        >
                          Click here to send again.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="flex flex-row gap-2">
                    <img src={AssessmentsIcon} className="w-4 h-4 my-auto" />
                    <h1
                      className="sm:text-xl md:text-2xl my-auto font-bold select-text text-coalColor"
                      id="step3"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      My Tests
                    </h1>
                  </div>
                  {user_role !== "hiring-manager" && (
                    <div className="flex relative">
                      <button
                        id="assessment1"
                        className={`inline-flex items-center border ${
                          !userData?.confirmed || user_role === "hiring-manager"
                            ? "bg-[#D3D5D8] text-[#7C8289]"
                            : "bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border-coalColor"
                        } px-4 lg:mt-4 md:mt-0 py-3 text-base font-medium rounded-md `}
                        onClick={() => {
                          if (!dataAccess?.isAllowed) {
                            setHeading("You’ve reached your limit!");
                            setdescription(
                              user_package_check === "free"
                                ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                                : "Pro subscription allows 5 Active Tests, either change the status of an Active Test to ‘Draft’ or upgrade your subscription package"
                            );
                            setGeneralModal(true);
                          } else {
                            localStorage.setItem("current_module", "general");
                            localStorage.setItem("assessment_ID", "null");
                            navigate("/create-test");
                          }
                        }}
                        disabled={
                          !userData?.confirmed || user_role === "hiring-manager"
                        }
                      >
                        <RiFileListLine
                          alt="Add Transaction Icon"
                          className="w-4 my-auto h-5 mr-2 icon-image"
                        />
                        Create new test
                      </button>
                      {!userData?.confirmed && (
                        <div className="tooltip w-[15rem]  text-center absolute top-full left-0 opacity-0 pointer-events-none">
                          Please verify your email first 👍
                        </div>
                      )}
                      {user_role === "hiring-manager" && (
                        <div className="tooltip w-[15rem]  text-center absolute top-full left-0 opacity-0 pointer-events-none">
                          You do not have permissions to create test
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex gap-4">
                  <div
                    className="flex flex-col gap-3 mt-5 relative"
                    id="assessment3"
                  >
                    <TextFieldSmall
                      id="assessment"
                      label="Tests"
                      value={selectstatus}
                      textSize="text-[13px]"
                      backgroundTextColor="bg-[#F6F7F7]"
                      paddingY="py-[11px]"
                      thickBorder="border-[1px]"
                      topTextWeight="font-medium"
                      image={dropdownnavbar}
                      dimension="w-2.5 h-1.5"
                      onClick={() => setAssessmentDropdown(!assessmentDropdown)}
                      addionalClasses="cursor-pointer user-select-none caret-transparent"
                    />
                    {assessmentDropdown && (
                      <div
                        id="education-drop"
                        className=" absolute z-40 border border-coalColor py-2 px-2 assessmentClass right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                        ref={assessmentRef}
                      >
                        {assessments.map((i) => (
                          <ul
                            key={i.title}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            style={{ fontFamily: "Silka" }}
                            onClick={() => {
                              setselecteditem(i?.title);
                              setSelectedStatus(i?.name);
                              setAssessmentDropdown(false);
                            }}
                          >
                            <li>
                              <a className="block px-5 py-2 text-sm font-medium">
                                {i?.title === ""
                                  ? "All"
                                  : i?.title.charAt(0).toUpperCase() +
                                    i?.title.slice(1)}
                              </a>
                            </li>
                          </ul>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    className="flex flex-col mt-5 w-[15rem] my-auto"
                    id="assessment4"
                  >
                    <TextFieldSmall
                      label="Search tests"
                      value={searchAssessment}
                      onChangeValue={(e) => setSearchAssessment(e.target.value)}
                      backgroundTextColor="bg-[#F6F7F7]"
                      thickBorder="border-[1px]"
                      topTextWeight="font-medium"
                      image={SearhBar}
                      placeholder="Search tests"
                      textSize="text-[12px]"
                      paddingY="py-3"
                      dimension="w-4 h-4"
                    />
                  </div>
                </div>
                <div
                  className="relative overflow-x-auto shadow-md px-4 bg-white sm:rounded-lg mt-10"
                  id="assessment2"
                >
                  <table className="w-full sm:text-xs md:text-sm sm:text-left md:text-left text-white ">
                    <thead
                      className="sm:text-xs md:text-lg text-coalColor bg-white"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      <tr>
                        <th
                          scope="col"
                          className="sm:px-3 w-[35%] md:px-6 sm:py-2"
                        >
                          Role Name
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[10%] sm:py-2"
                        >
                          Candidates
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 sm:mt-3 lg:mt-6 sm:py-2 flex flex-col"
                        >
                          Progress
                          <div
                            className="grid grid-cols-3 w-[15rem] text-[10px] font-normal text-[#252E3A]"
                            style={{ fontFamily: "Silka" }}
                          >
                            <p>Completed</p>
                            <p>Inprogress</p>
                            <p>Not completed</p>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[15%] sm:py-2 "
                        >
                          Last Activity
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[15%] sm:py-2"
                        >
                          Date Created
                        </th>
                      </tr>
                    </thead>

                    <tbody className="rounded-lg">
                      {isAssessmentsLoading ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <div className="bg-white">
                              <div class="loader-container-2">
                                <div class="loader-2"></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {dataR?.data?.map((i, index) => (
                            <tr
                              key={index}
                              className={`bg-white odd:bg-[#F6F7F7] text-black cursor-pointer`}
                              onClick={(e) => {
                                const isActionsColumn =
                                  e.target.closest(".clickable");
                                if (isActionsColumn) {
                                  navigate(`/invite-candidates/${i.id}`);
                                }
                              }}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.status === "draft" && (
                                <th
                                  scope="row"
                                  className="md:px-6 sm:px-2 sm:py-2 clickable font-medium whitespace-nowrap"
                                >
                                  {i?.name}{" "}
                                  <span className="text-orange-500 text-xs">
                                    (draft)
                                  </span>
                                </th>
                              )}

                              {i?.status === "archived" && (
                                <th
                                  scope="row"
                                  className="md:px-6 sm:px-2 sm:py-2 clickable font-medium whitespace-nowrap"
                                >
                                  {i?.name}{" "}
                                  <span className="text-yellow-700 text-xs">
                                    (archived)
                                  </span>
                                </th>
                              )}

                              {i?.status === "active" && (
                                <th
                                  scope="row"
                                  className="md:px-6 sm:px-2 sm:py-2 clickable font-medium whitespace-nowrap"
                                >
                                  {i?.name}
                                </th>
                              )}
                              <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                {i?.candidates}
                              </td>
                              {i?.stats.length === 0 && (
                                <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                  <div className="grid grid-cols-3 text-xs font-normal text-[#252E3A] mx-auto">
                                    <div className=" text-left px-4 font-bold">
                                      <div className="mt-[7px]">-</div>
                                    </div>
                                    <div className=" text-left px-4 font-bold">
                                      <div className="mt-[7px]">-</div>
                                    </div>
                                    <div className=" text-left px-4 font-bold">
                                      <div className="mt-[7px]">-</div>
                                    </div>
                                  </div>
                                </td>
                              )}

                              {i?.stats.map((j, ind) => (
                                <td
                                  className="md:px-6 sm:px-2 sm:py-2 clickable"
                                  key={ind}
                                >
                                  <div className="grid grid-cols-3 text-xs font-normal text-[#252E3A] mx-auto">
                                    {parseInt(j?.CandidateCompleted) === 0 ? (
                                      <div className=" text-left px-4 font-bold">
                                        <div className="mt-[7px]">-</div>
                                      </div>
                                    ) : (
                                      <div className="bg-primaryGreen w-8 h-8 border border-[#7C8289] rounded-full text-center">
                                        <div className="mt-[7px]">
                                          {j?.CandidateCompleted}
                                        </div>
                                      </div>
                                    )}

                                    {parseInt(j?.CandidateInProgress) === 0 ? (
                                      <div className=" text-left px-4 font-bold">
                                        <div className="mt-[7px]">-</div>
                                      </div>
                                    ) : (
                                      <div className="bg-[#FFB500] w-8 h-8 border border-[#7C8289] rounded-full text-center">
                                        <div className="mt-[7px]">
                                          {j?.CandidateInProgress}
                                        </div>
                                      </div>
                                    )}

                                    {parseInt(j?.CandidateNotStarted) === 0 ? (
                                      <div className=" text-left px-4 font-bold">
                                        <div className="mt-[7px]">-</div>
                                      </div>
                                    ) : (
                                      <div className="bg-[#FF5812] w-8 h-8 border border-[#7C8289] rounded-full text-white text-center">
                                        <div className="mt-[7px]">
                                          {j?.CandidateNotStarted}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </td>
                              ))}
                              <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                {moment(i?.createdAt).fromNow()}
                              </td>
                              <td className="md:px-6 sm:px-2 sm:py-2 clickable">
                                {moment(i?.createdAt).format("Do MMMM, YYYY")}
                              </td>
                              <td className="md:px-6 sm:px-2 sm:py-2 w-20 gap-3 align-center flex flex-row mt-2">
                                <img
                                  src={eye}
                                  className="w-6 h-3 my-auto flex"
                                  onClick={() =>
                                    navigate(`/invite-candidates/${i.id}`)
                                  }
                                />
                                {user_role !== "hiring-manager" && (
                                  <img
                                    src={bin}
                                    className="w-3 h-4 my-auto flex object-cover"
                                    onClick={() => {
                                      setAssessmentId(i?.id);
                                      setDeleteModal(true);
                                    }}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                  {dataR?.data?.length === 0 && (
                    <div className="px-6 mt-3">
                      <div className="border border-[#FF5812] py-4 rounded">
                        <p
                          className="text-alertRed text-center"
                          style={{ fontFamily: "Silka Bold" }}
                        >
                          No tests created
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="mt-8 ml-2">
                    <TablePagination
                      totalCount={
                        dataR?.meta?.pageCount === 0
                          ? 1
                          : dataR?.meta?.pageCount
                      }
                      onPageChange={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {deleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          onClick={handleDeleteAccount}
          header="Delete your test"
          icon={DeleteIcon}
          loading={deleteLoading}
          description=" Are you sure you want to delete your test? This action cannot be undone."
        />
      )}
    </>
  );
};

export default Home;
