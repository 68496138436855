import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useState } from "react";
import TextFieldCustom from "../../../../Components/TextField/TextFieldCustom";
import Right from "../../../../Assets/rightarrow.png";
import { Link } from "react-router-dom";
import NavbarPreviews from "./Navbar/Navbar";
import { GoArrowRight } from "react-icons/go";
import TextField from "../../../../Components/Dexta/TextField/TextField";

const WelcomeScreen = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [isHovered, setIsHovered] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    user_email: "",
  });

  //#region Handling input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  //#endregion

  //#region Styling of continue button
  const buttonStyle = {
    background: isHovered ? previewData[1] : previewData[1],
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: previewData[2],
    border: `1px solid ${previewData[2]}`,
    fontFamily: "Silka",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };
  //#endregion

  //#region closing tab on onClick
  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };
  //#endregion

  return (
    <>
      <NavbarPreviews previewData={previewData} fixed={true} marginTop={true} />
      <div className="flex justify-center h-screen">
        <div className="m-auto md:w-2/5 rounded-md bg-white">
          <div className="rounded-lg">
            <div className="p-8">
              <div className="flex flex-col items-center">
                <img
                  src={previewData[0]}
                  className=" object-contain w-[100px] h-10 mr-auto"
                />
                <div className="flex flex-col mr-auto">
                  <div
                    className="flex flex-row items-center"
                    style={{ fontFamily: "Silka" }}
                  >
                    <p className="text-black text-left font-bold mr-2 my-auto">
                      Test{" "}
                      <span className="font-bold my-auto text-gray-400">
                        Personality Screening
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <p
                className="mt-5 text-black font-bold"
                style={{ fontFamily: "Silka" }}
              >
                Please confirm who you are
              </p>
              <div className="mt-5">
                <TextField
                  type="text"
                  name="firstName"
                  label="First name"
                  value={user.firstName}
                  rounded="rounded-md"
                  border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    user?.firstName && "border-coalColor"
                  }`}
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="Enter your first name"
                />
              </div>
              <div className="mt-5">
                <TextField
                  type="text"
                  name="lastName"
                  label="Last name"
                  value={user.lastName}
                  rounded="rounded-md"
                  border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    user?.lastName && "border-coalColor"
                  }`}
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="Enter your last name"
                />
              </div>
              <div className="mt-5">
                <TextField
                  type="text"
                  name="user_email"
                  label="Email"
                  value={user.user_email}
                  rounded="rounded-md"
                  border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    user?.user_email && "border-coalColor"
                  }`}
                  onChange={handleChange}
                  onBlur={handleChange}
                  placeholder="Enter your email"
                />
              </div>
              <div className="flex flex-row p-2 mt-3">
                <label
                  className="relative flex cursor-pointer items-center rounded-full"
                  data-ripple-dark="true"
                >
                  <input
                    id="ripple-on"
                    type="checkbox"
                    className={`peer relative h-5 w-5 cursor-pointer checked:bg-coalColor appearance-none rounded border-[1px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                  />
                  <div
                    className={`pointer-events-none absolute top-2/4 left-2/4 text-primaryGreen -translate-y-2/4 -translate-x-2/4 opacity-0 transition-opacity peer-checked:opacity-100`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-3.5 w-3.5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                </label>
                <p
                  style={{ fontFamily: "Silka" }}
                  className="ml-2 sm:text-xs md:text-sm text-[#6B6B6B]"
                >
                  I have read and I accept the{" "}
                  <Link
                    to={process.env.REACT_APP_STATIC_SITE + "/PrivacyPolicy"}
                    style={{
                      color: previewData[2],
                    }}
                  >
                    privacy policy
                  </Link>
                </p>
              </div>
              <div className="flex relative">
                <button
                  className={`inline-flex items-center w-full justify-center px-4 mt-5 py-4 text-white text-base font-medium rounded-md`}
                  style={buttonStyle}
                  onMouseEnter={handleHover}
                  onMouseLeave={handleLeave}
                  type="submit"
                  onClick={closeTab}
                >
                  Continue
                  <GoArrowRight
                    alt="Add Transaction Icon"
                    className="w-5 h-5 ml-2 icon-image"
                  />
                </button>
                <div className="tooltip w-[8rem] font-medium text-center">
                  Primary color
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeScreen;
