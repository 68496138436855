import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getUserDetails } from "../hooks/getUserDetails";
import axios from "axios";
import Loader from "react-loader-spinner";
import { FaChevronRight } from "react-icons/fa";
import { updateUserDetails } from "../hooks/updateUserDetails";
import closeIcon from "../../Dexta_assets/closeModal.png";

export default function BillingAddress({
  billingAddressOpen,
  setBillingAddressOpen,
}) {
  const queryClient = useQueryClient();
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [staticLoading, setStaticLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [user, setUser] = useState({
    address1: "",
    address2: "",
    city: "",
    zip: "",
    state: "",
    country: "",
  });
  const [countryModal, setCountryModal] = useState(false);
  const { data, isLoading, error } = useQuery({
    queryKey: ["getUserDetail", userID],
    queryFn: () => getUserDetails(userID),
    retry: false,
  });
  const [searchCountry, setsearchCountry] = useState("");
  const countryRef = useRef(null);
  const [updateEnabled, setUpdateEnabled] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  useEffect(() => {
    setStaticLoading(true);
    setTimeout(() => {
      setStaticLoading(false);
    }, 2000);
  }, [billingAddressOpen]);

  useEffect(() => {
    setError(error?.response?.data?.message[0] ? true : false);
  });

  //#region updating in fields
  useEffect(() => {
    setUser({
      address1: data?.address?.line1,
      address2: data?.address?.line2,
      city: data?.address?.city,
      zip: data?.address?.postal_code,
      state: data?.address?.state,
      country: data?.address?.country,
    });
  }, [data]);
  //#endregion

  //#region Fetching Countries from API
  useEffect(() => {
    const getCountry = async () => {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const countryNames = response.data.map((country) => country.name.common);
      setCountries(countryNames);
    };
    getCountry();
  }, []);
  //#endregion

  //#region filtering countries based on user input
  const filteredCountries = countries.filter((item) =>
    item.toLowerCase().includes(searchCountry.toLowerCase())
  );
  //#endregion

  //#region handle outside click
  useEffect(() => {
    function handleOutsideCountry(event) {
      if (
        countryRef.current &&
        !countryRef.current.contains(event.target) &&
        event.target.id !== "country" &&
        !event.target.closest(".countryClass")
      ) {
        setCountryModal(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideCountry);
    return () => {
      document.removeEventListener("mousedown", handleOutsideCountry);
    };
  }, [countryRef]);
  //#endregion

  //#region handle submit
  const handleSubmit = () => {
    let data = {
      id: userID,
      data2: JSON.stringify({
        city: user?.city,
        country: user?.country,
        line1: user?.address1,
        line2: user?.address2,
        state: user?.state,
        postal_code: user?.zip,
      }),
    };
    try {
      mutate(data);
    } catch (error) {
      //onError will automatically detect
    }
  };
  //#endregion

  //#region update data
  const { mutate, isLoading: mutateLoading } = useMutation(updateUserDetails, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/subscriptions/updateUserDetail");
      setUpdateEnabled(false);
    },
  });
  //#endregion

  return (
    <Transition.Root show={billingAddressOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setBillingAddressOpen(false);
          setUpdateEnabled(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform rounded-lg bg-white text-left transition-all sm:my-8 sm:w-11/12 md:max-w-md"
                style={{ height: "500px", zIndex: 20 }}
              >
                {/* <img src={Logo} className='w-[200px] h-[50px] object-contain mx-auto mt-5'/> */}
                <div className="bg-white rounded-lg">
                  <p
                    className="text-lg font-medium text-coalColor pl-5 cursor-pointer hover:text-coalColor text-left pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Billing & Shipping Addresses
                  </p>
                  <img
                    src={closeIcon}
                    className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setBillingAddressOpen(false)}
                  />
                </div>
                <div className="pt-6 px-5">
                  {isLoading || staticLoading ? (
                    <>
                      <SkeletonTheme
                        baseColor="#e2e2e2"
                        highlightColor="#bdbdbd"
                      >
                        <p className="w-1/3 text-2xl">
                          <Skeleton count={1} />
                        </p>
                        <div className="w-1/2">
                          <Skeleton count={1} height={35} />
                        </div>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>{" "}
                        <p className="w-1/3 mt-1 text-xs">
                          <Skeleton count={1} />
                        </p>
                      </SkeletonTheme>
                    </>
                  ) : (
                    <>
                      {error_ ? (
                        <p className="text-center text-gray-500">
                          {(() => {
                            const errorMessage = error?.response?.data?.message;

                            if (Array.isArray(errorMessage)) {
                              return errorMessage[0];
                            } else if (typeof errorMessage === "string") {
                              return errorMessage;
                            } else {
                              return "An error occurred.";
                            }
                          })()}
                        </p>
                      ) : (
                        <>
                          {data?.address === null || updateEnabled ? (
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  handleSubmit();
                                }}
                              >
                                <div
                                  className="space-y-4"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <input
                                    class="transform mt-1 border border-[#999999] rounded w-full py-3 px-3 text-gray-700 leading-tight"
                                    name="address1"
                                    type="text"
                                    placeholder="Address Line1 (Optional)"
                                    value={user.address1}
                                    onChange={handleInputChange}
                                  />
                                  <input
                                    class="transform mt-0.5 border border-[#999999]   rounded w-full py-3 px-3 text-gray-700 leading-tight"
                                    name="address2"
                                    type="text"
                                    placeholder="Address Line2 (Optional)"
                                    value={user.address2}
                                    onChange={handleInputChange}
                                  />
                                  <div className="flex flex-row gap-3">
                                    <div>
                                      <input
                                        class="transform mt-0.5 border border-[#999999] rounded w-full py-3 px-3 text-gray-700 leading-tight"
                                        name="city"
                                        type="text"
                                        placeholder="City (Optional)"
                                        value={user.city}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                    <div>
                                      <input
                                        class="transform mt-0.5 border border-[#999999] rounded w-full py-3 px-3 text-gray-700 leading-tight"
                                        name="zip"
                                        type="text"
                                        placeholder="Zip (Optional)"
                                        value={user.zip}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex flex-row w-full gap-3">
                                    <div className="w-1/2">
                                      <input
                                        class="transform border border-[#999999]  mt-0.5 w-full  rounded py-3 px-3 text-gray-700 leading-tight"
                                        name="state"
                                        type="text"
                                        placeholder="State (Optional)"
                                        value={user.state}
                                        onChange={handleInputChange}
                                      />
                                    </div>
                                    <div className="w-1/2">
                                      <div className="w-full relative">
                                        <input
                                          class="transform countryClass border border-[#999999] mt-0.5 transition duration-500 focus:scale-105 rounded w-full py-3 px-3 text-gray-700 leading-tight"
                                          name="country"
                                          type="text"
                                          placeholder="Country"
                                          value={user.country}
                                          onClick={() =>
                                            setCountryModal(!countryModal)
                                          }
                                          onChange={(e) => {
                                            handleInputChange(e);
                                            setsearchCountry(e.target.value);
                                          }}
                                        />
                                        {countryModal && (
                                          <div
                                            id="education-drop"
                                            className="absolute countryClass right-0 top-full h-[300px] mt-2 overflow-scroll bg-white  rounded-md shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full"
                                            style={{ zIndex: 999 }}
                                          >
                                            {filteredCountries.map(
                                              (i, index) => (
                                                <ul
                                                  key={index}
                                                  className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                                  style={{
                                                    fontFamily: "Silka",
                                                  }}
                                                  ref={countryRef}
                                                  onClick={() => {
                                                    setUser({
                                                      ...user,
                                                      country: i,
                                                    });
                                                    setCountryModal(false);
                                                  }}
                                                >
                                                  <li>
                                                    <a className="block px-5 py-2 text-sm font-medium">
                                                      {i}
                                                    </a>
                                                  </li>
                                                </ul>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="w-1/2 mx-auto">
                                    <button
                                      className="inline-flex items-center w-full border border-coalColor hover:bg-coalColor justify-center px-4 mt-[6rem] py-3 bg-[#C0FF06] text-coalColor hover:text-white text-sm font-bold rounded-md"
                                      style={{ fontFamily: "Silka" }}
                                      type="submit"
                                    >
                                      {mutateLoading ? (
                                        <span className="flex items-center justify-center">
                                          <Loader
                                            type="Oval"
                                            color="white"
                                            height={20}
                                            width={20}
                                          />
                                          <span className="ml-2">
                                            Updating...
                                          </span>
                                        </span>
                                      ) : (
                                        "Update"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </Transition.Child>
                          ) : (
                            <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <div>
                                <div
                                  className="bg-[#F6F7F7] mt-2 rounded cursor-pointer"
                                  onClick={() => setUpdateEnabled(true)}
                                >
                                  <div
                                    className="p-4  grid grid-cols-8"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <div className="col-span-6">
                                      <div className="text-sm">
                                        {data?.address?.line1}{" "}
                                        {data?.address?.line2}
                                      </div>
                                      <div className="text-sm">
                                        {data?.address?.city}{" "}
                                        {data?.address?.postal_code}{" "}
                                        {data?.address?.state}{" "}
                                        {data?.address?.country}
                                      </div>
                                    </div>
                                    <div className="my-auto col-span-2 ml-auto">
                                      <FaChevronRight className="w-3 h-3" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Transition.Child>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
