import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose } from "react-icons/io5";

export default function AlertModal({
  alertModal,
  setAlertModal,
  setErrorCount = null,
  errorC = null,
  sucessC = null,
  setSuccessCount = null,
  setModalCount = null,
}) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={alertModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setAlertModal(false);
          setErrorCount(0);
          setSuccessCount(0);
          setModalCount(0);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-10 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 w-2/4 h-[13rem]">
                {sucessC > 0 ? (
                  <div className="bg-white">
                    <p className="text-xl font-bold text-black cursor-pointer text-left p-8">
                      There was an issue with the{" "}
                      {errorC > 1 ? "emails" : "email"} you entered
                    </p>
                    <p className="px-8 text-[#000000de] mt-3">
                      {sucessC} {sucessC > 1 ? "invitations" : "invitation"}{" "}
                      have been sent successfully. It was not possible to send{" "}
                      {errorC} {errorC > 1 ? "invitations" : "invitation"}.
                      Please review the emails you entered and try again.
                    </p>
                    <IoClose
                      className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                      onClick={() => {
                        setAlertModal(false);
                        setErrorCount(0);
                        setSuccessCount(0);
                        setModalCount(0);
                      }}
                    />
                  </div>
                ) : (
                  <div className="bg-white">
                    <p className="text-xl font-bold text-black cursor-pointer text-left p-8">
                      There was an issue with the{" "}
                      {errorC > 1 ? "emails" : "email"} you entered
                    </p>
                    <p className="px-8 text-[#000000de] mt-3">
                      It was not possible to send{" "}
                      {errorC > 1 ? "invitations" : "invitation"}. Please review
                      the emails you entered and try again.
                    </p>
                    <IoClose
                      className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                      onClick={() => {
                        setAlertModal(false);
                        setErrorCount(0);
                        setSuccessCount(0);
                        setModalCount(0);
                      }}
                    />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
