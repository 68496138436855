import React, { useEffect, useMemo, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getCandidateMetaData } from "../hooks/getCandidateMetaData";
import { getCandidateAssessments } from "../hooks/getCandidateAssessments";
import * as moment from "moment";
import { FaChevronRight } from "react-icons/fa";
import { getCandidateResult } from "../hooks/getCandidateResult";
import { useParams } from "react-router-dom";
import Help from "../../../Assets/help.png";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import { getEvaluationByAssessmentId } from "../hooks/getEvaluationByAssessmentId";
import ConfirmModal from "../../../Components/ConfirmationModals/ConfirmModal";
import confirm from "../../../Dexta_assets/confirm.png";
import useWindowSize from "../../../Helpers/useWindowSize";

const Results = () => {
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userID = localStorage.getItem("CP-CANDIDATE-ID");
  const [staticLoad, setStaticLoad] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [evaluationData, setEvaluationData] = useState(null);
  const [TimePercentage, setTimePercentage] = useState(null);
  const [modulesPercentage, setModulesPercentage] = useState(null);
  const size = useWindowSize();
  const isMobile = size.width <= 640;

  useEffect(() => {
    setStaticLoad(true);
    setTimeout(() => {
      setStaticLoad(false);
    }, 1000);
  }, []);

  const {
    data: metaData,
    isLoading: metaLoading,
    error: metaError,
  } = useQuery({
    queryKey: ["candidate-meta", userID],
    queryFn: () => getCandidateMetaData(userID),
    retry: false,
  });

  const {
    data: resultsData,
    isLoading: resultsLoading,
    error: resultsError,
  } = useQuery({
    queryKey: ["assessment", id],
    queryFn: () => getCandidateResult(id),
    retry: true,
  });

  useEffect(() => {
    if (!metaLoading && !staticLoad) {
      if (metaError || metaData === undefined) {
        navigate("/candidate/profile");
      }
    }
  });

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region getEvaluationData
  const { data, error, isLoading } = useQuery(["evaluation", id, userID], () =>
    getEvaluationByAssessmentId(id, userID)
  );

  useEffect(() => {
    if (!isLoading && data?.evaluation) {
      setEvaluationData(JSON.parse(data?.evaluation?.sessionInfo));
      const evaluation = JSON.parse(data?.evaluation?.sessionInfo);
      const convert2Seconds = evaluation?.totalModuleTime * 60;
      setModulesPercentage(
        (evaluation?.currentModuleNumber / evaluation?.TotalModules) * 100
      );
      setTimePercentage((evaluation?.seconds_left / convert2Seconds) * 100);
    }
  }, [data]);
  //#endregion

  //#region Start user assessment
  const HandleStartAssessment = () => {
    const defaultColor = "#C0FF06";
    const defaultSecondaryColor = "#252E3A";
    const defaultAvatar =
      "https://dexta-public.s3.eu-west-2.amazonaws.com/dexta-logo.png";

    const theme = {
      logo: "",
      color: "",
      name: "",
      sec_color: "",
    };
    const section = {
      CurrentModule: 0,
      ModuleID: 0,
      time: 0,
      evaluation: data?.evaluation?.id,
      pageQuestion: 1,
      module_Name: "",
      isPractice: false,
    };
    const questions = { current: 0, total: 0, solved: 0 };
    if (data?.assessment?.user?.company?.color === "") {
      theme.color = defaultColor;
    } else if (data?.assessment?.user?.company?.color) {
      theme.color = data?.assessment?.user?.company?.color;
    }

    if (
      data?.assessment?.user?.company?.sec_color === "" ||
      data?.assessment?.user?.company?.sec_color === null
    ) {
      theme.sec_color = defaultSecondaryColor;
    } else if (data?.assessment?.user?.company?.sec_color) {
      theme.sec_color = data?.assessment?.user?.company?.sec_color;
    }

    if (data?.assessment?.user?.company?.companyAvatar === "") {
      theme.logo = defaultAvatar;
    } else if (data?.assessment?.user?.company?.companyAvatar) {
      theme.logo = data?.assessment?.user?.company?.companyAvatar;
    }
    theme.name = data?.assessment?.user?.company?.companyName;
    localStorage.setItem("theme", JSON.stringify(theme));
    localStorage.setItem("Current Module", JSON.stringify(section));
    localStorage.setItem("CANDIDATE-ASSESSMENT-ID", data?.assessment?.id);
    localStorage.setItem("questions", JSON.stringify(questions));
    navigate("/setup");
  };
  //#endregion

  return (
    <>
      {isLoading || metaLoading || resultsLoading ? (
        <div class="loader-container-1 col-span-6">
          <div class="loader-1"></div>
        </div>
      ) : (
        <>
          <div className="bg-coalColor">
            <div className="bg-coalColor md:mx-auto md:container sm:px-3 md:px-0">
              <div className="bg-coalColor w-full">
                <div className="sm:py-5 md:p-10 md:py-8">
                  <div className="flex justify-between sm:flex-col md:flex-row">
                    <div className="flex flex-row sm:my-auto md:my-0">
                      <IoChevronBackCircleOutline
                        alt="back"
                        className="w-6 h-6 my-auto cursor-pointer text-white"
                        onClick={() => navigate("/candidate/dashboard")}
                      />
                      <div className="flex flex-col ml-4">
                        <h1
                          className="sm:text-lg md:text-xl font-bold flex flex-row text-white"
                          style={{ fontFamily: "Archia Semibold" }}
                        >
                          <div>
                            {resultsData?.assessment?.name} for{" "}
                            {resultsData?.assessment?.recruiter?.companyName}
                          </div>
                          <div className="my-auto">
                            <p
                              className={`px-2 ml-2 sm:w-full md:w-auto pt-[1.2px] pb-[3px] my-auto text-xs rounded-md 
                          ${
                            resultsData?.candidate?.status === "completed" &&
                            "bg-green-700 text-white"
                          }
                          ${
                            resultsData?.candidate?.status === "InProgress" &&
                            "bg-orange-600 text-white"
                          }
                          `}
                            >
                              {resultsData?.candidate?.status === "completed" &&
                                "Completed"}
                              {resultsData?.candidate?.status ===
                                "InProgress" && "InProgress"}
                            </p>
                          </div>
                        </h1>
                        <p
                          className="text-xs text-[#E6E6E6]"
                          style={{ fontFamily: "Silka" }}
                        >
                          Invited for Test on{" "}
                          {moment(resultsData?.candidate.createdAt).format(
                            "MMM DD, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                    {(resultsData?.candidate?.status === "InProgress" ||
                      resultsData?.candidate?.status === "started") &&
                      data?.evaluation && (
                        <div className="md:ml-auto sm:mt-4 md:mt-0 sm:ml-9">
                          <CustomButton
                            label="Resume test"
                            iconWidth={5}
                            iconHeight={5}
                            textSize="font-medium"
                            hoverBgColor="#252E3A"
                            hoverTextColor="#FFFFFF"
                            bgColor="#C0FF06"
                            paddingY={5}
                            widthButton="md:w-full"
                            borderCustom="border border-coalColor text-coalColor hover:border-white"
                            onClickButton={() => setConfirmModal(true)}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex items-center p-4 mb-4 text-sm text-black justify-center rounded-lg w-full bg-[#DBD9C0] mt-10"
            role="alert"
            style={{ fontFamily: "Silka" }}
          >
            <svg
              class="flex-shrink-0 inline w-4 h-4 me-3 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <div>
              {data?.evaluation ? (
                <span class="font-normal text-base">
                  The results of this test do not reflect your overall progress
                  through the recruitment process.
                </span>
              ) : (
                <span class="font-normal text-base">
                  The candidate did not start the evaluation yet.
                </span>
              )}
            </div>
          </div>
          <div className="mx-auto lg:container sm:px-3 md:px-0">
            {resultsData?.candidate?.status === "InProgress" ? (
              <>
                {data?.evaluation ? (
                  <div className="sm:py-5 md:p-10 md:py-8">
                    <h1
                      className="text-2xl font-medium"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Your progress
                    </h1>
                    <div className="py-4 px-4 rounded-md bg-white mt-5">
                      <div className="grid md:grid-cols-3 gap-20">
                        <div className="flex flex-col">
                          <p style={{ fontFamily: "Silka" }}>Modules:</p>
                          <div className="w-full rounded-full h-2.5 mt-5 bg-coalColor">
                            <div
                              className="bg-primaryGreen h-2.5 rounded-full"
                              style={{ width: `${modulesPercentage}%` }}
                            ></div>
                          </div>
                          <p style={{ fontFamily: "Silka" }}>
                            {evaluationData?.currentModuleNumber} out of{" "}
                            {evaluationData?.TotalModules}
                          </p>
                        </div>
                        <div>
                          <div className="flex flex-col">
                            <p style={{ fontFamily: "Silka" }}>
                              Time left on current module:
                            </p>
                            <div className="w-full rounded-full h-2.5 mt-5 bg-coalColor">
                              <div
                                className="bg-primaryGreen h-2.5 rounded-full"
                                style={{ width: `${TimePercentage}%` }}
                              ></div>
                            </div>
                            <p style={{ fontFamily: "Silka" }}>
                              {Math.floor(evaluationData?.seconds_left / 60)}{" "}
                              minutes
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {resultsData?.candidate?.status === "started" ? (
                  <div className="md:p-10 sm:py-3 md:py-0">
                    <h1
                      className="text-2xl font-medium"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Tests
                    </h1>
                    <div className="relative  mt-5">
                      <div className="mt-3">
                        <div className="border border-[#FF5812] py-4 rounded">
                          <p
                            className="text-alertRed text-center"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            No work has started on any module for the test yet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="md:p-10 sm:py-3 md:py-0">
                    <h1
                      className="text-2xl font-medium"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      Tests
                    </h1>
                    <div className="relative  mt-5">
                      <table className="w-full sm:text-xs md:text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        {metaLoading && resultsLoading ? (
                          <tr className="">
                            <td colSpan="2" className="text-center">
                              <div className="bg-white ">
                                <div class="loader-container-2 col-span-6">
                                  <div class="loader-2"></div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            <thead
                              className="sm:text-sm md:text-base text-black bg-white font-medium"
                              style={{ fontFamily: "Silka" }}
                            >
                              <tr>
                                <th scope="col" className="px-6 py-5">
                                  NAME
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-5 flex flex-row"
                                >
                                  SCORE
                                  <div
                                    className="relative ml-2 flex justify-center"
                                    onMouseEnter={() => setTooltipVisible(true)}
                                    onMouseLeave={() =>
                                      setTooltipVisible(false)
                                    }
                                  >
                                    <img
                                      src={Help}
                                      className="md:w-5 md:h-5 sm:h-4 my-auto sm:w-4 cursor-pointer"
                                    />
                                    <div
                                      className={`${
                                        isMobile
                                          ? "tooltipxD right-0"
                                          : "tooltip left-0"
                                      } sm:w-[200px] md:w-[400px] text-center absolute top-full opacity-0 pointer-events-none text-xs`}
                                      style={{
                                        visibility: tooltipVisible
                                          ? "visible"
                                          : "hidden",
                                        zIndex: 999,
                                      }}
                                    >
                                      Companies will compare your scores with
                                      other candidates. So if your score looks
                                      low, don’t worry and wait for the company
                                      to contact you. You may have still done
                                      better than the other candidates.
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {resultsData?.sections.map((i, index) => (
                                  <tr
                                    key={index}
                                    className="bg-white odd:bg-[#F6F7F7] text-black"
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    <td className="px-6 py-4">{i?.name}</td>
                                    <td className="px-6 py-4">
                                      {Number(i?.score || 0).toFixed(0)}%
                                    </td>
                                  </tr>
                                ))}
                              </>
                            </tbody>
                          </>
                        )}
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {confirmModal && (
            <ConfirmModal
              setConfirmModal={setConfirmModal}
              icon={confirm}
              onClick={() => HandleStartAssessment()}
              header="Resume test"
              buttonText="Yes"
              description=" Are you sure you want to resume your test?"
            />
          )}
        </>
      )}
    </>
  );
};

export default Results;
