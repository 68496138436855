import React from "react";
import Loader from "react-loader-spinner";

const DeleteModal = ({
  onClick,
  icon,
  header,
  description,
  setDeleteModal,
  loading,
  buttonText,
}) => {
  return (
    <main className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-80 absolute inset-0"
        onClick={() => setDeleteModal(false)}
      ></div>
      <div className="bg-white rounded-lg md:p-4 md:max-w-lg md:mx-auto relative">
        <div className="bg-white rounded-lg md:max-w-lg md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mx-4 md:relative">
          <div className="md:flex items-center">
            <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
              <img src={icon} className="w-8 h-8" />
            </div>
            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
              <p className="font-bold" style={{ fontFamily: "Archia Bold" }}>
                {header}
              </p>
              <p
                className="text-sm text-coalColor mt-1"
                style={{ fontFamily: "Silka Light" }}
              >
                {description}
              </p>
            </div>
          </div>
          <div className="text-center md:text-right mt-8 md:flex md:justify-end">
            <button
              className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-primaryGreen text-coalColor hover:bg-coalColor hover:text-white border border-coalColor rounded-md text-sm md:ml-2 md:order-2"
              onClick={onClick}
              style={{ fontFamily: "Silka" }}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <Loader type="Oval" color="black" height={20} width={20} />
                  <span className="ml-2">Deleting...</span>
                </span>
              ) : (
                <>{buttonText ? buttonText : "Delete Test"}</>
              )}
            </button>
            <button
              className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 hover:bg-primaryGreen hover:text-coalColor bg-coalColor border border-coalColor text-white rounded-md text-sm mt-4 md:mt-0 md:order-1"
              onClick={() => setDeleteModal(false)}
              style={{ fontFamily: "Silka" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DeleteModal;
