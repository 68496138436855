import React, { useState, useEffect } from "react";
import Logo from "../../../../Assets/mainLOGO.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../../../Auth/hooks/loginUser";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";
import queryString from "query-string";
import { resetEmail } from "../../../Auth/hooks/resetEmail";
import Password from "../../../../Dexta_assets/password-image.png";
import { resetCandidateEmail } from "../../hooks/resetCandidateEmail";

const ResetPasswordCandidate = () => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [toastSuccess, setToastSuccess] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //#region Setting up new password
  const { mutate, isLoading } = useMutation(resetCandidateEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
      if (response.message) {
        setToastSuccess("Reset Successful");
      }
      setTimeout(() => {
        navigate("/candidate/reset-password-complete");
      }, 1000);
    },
    onError: (error) => {
      setError(true);
      let errorMessage;
      if (Array.isArray(error.response.data.message)) {
        errorMessage = error.response.data.message.join(", ");
      } else {
        errorMessage = error.response.data.message;
      }
      setErrorMessage(errorMessage);
      toast.success(errorMessage, {
        style: { width: "400px" },
      });
    },
  });
  //#endregion

  //#region toast for success
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess);
    }
  }, [toastSuccess]);
  //#endregion

  //#region Validation on reset password form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      password1: Yup.string()
        .required("Password 1 Required")
        .min(12, "Password 1 should be at least 12 characters"),
      password2: Yup.string()
        .required("Password 2 Required")
        .min(12, "Password 2 should be at least 12 characters"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        password1: values?.password1,
        password2: values?.password2,
        resetToken: parsed?.token,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center px-2 lg:px-0 h-screen">
        <div className="m-auto w-full lg:w-2/5 rounded-md bg-white">
          <div className="rounded-lg">
            <div className="sm:p-5 lg:p-14">
              <img src={Logo} className="h-11 w-40" alt="CP Logo" />
              <h1
                className="pt-10 text-xl font-bold"
                style={{ fontFamily: "Archia Bold" }}
              >
                Set new password{" "}
              </h1>
              <div
                style={{ fontFamily: "Silka" }}
                className="text-sm mt-6 flex flex-col"
              >
                <span>Your new password must be different</span>
                <span>to previously used passwords.</span>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                autoComplete="off"
              >
                <div className="h-[4rem]">
                  <TextField
                    name="password1"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.firstName && "border-coalColor"
                    }`}
                    id="password1"
                    rounded="rounded-md"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password1 || ""}
                    passwordIcon={true}
                    onClick={togglePasswordVisibility}
                    isPasswordVisible={isPasswordVisible}
                  />
                  {validation.touched.password1 &&
                  validation.errors.password1 ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.password1}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div
                  style={{ fontFamily: "Silka" }}
                  className="text-xs underline mt-4"
                >
                  Must be at least 12 characters.
                </div>
                <div className="h-[4rem]">
                  <TextField
                    name="password2"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.firstName && "border-coalColor"
                    }`}
                    id="password2"
                    rounded="rounded-md"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Confrm Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password2 || ""}
                    passwordIcon={true}
                    onClick={togglePasswordVisibility}
                    isPasswordVisible={isPasswordVisible}
                  />
                  {validation.touched.password2 &&
                  validation.errors.password2 ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.password2}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex justify-between">
                  <div
                    className="flex flex-row"
                    style={{ fontFamily: "Silka" }}
                  ></div>
                </div>
                <div className="mb-3 mt-6 flex justify-center items-center flex-col">
                  <button
                    type="submit"
                    name="Sign up"
                    style={{ fontFamily: "Silka" }}
                    className="bg-primaryGreen text-coalColor w-full h-12 rounded-lg border border-coalColor font-medium sm:text-sm md:text-lg relative"
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <Loader
                          type="Oval"
                          color="black"
                          height={20}
                          width={20}
                        />
                        <span className="ml-2">Setting password...</span>
                      </span>
                    ) : (
                      "Set New Password"
                    )}
                  </button>
                </div>
                <span
                  className="text-coalColor text-sm text-center p-2"
                  style={{ fontFamily: "Silka" }}
                >
                  Don't want to reset your password?{" "}
                  <span
                    className="text-coalColor font-medium cursor-pointer"
                    style={{ fontFamily: "Silka Bold" }}
                    onClick={() => navigate("/candidate/login")}
                  >
                    Return to Login
                  </span>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordCandidate;
