import React, { useEffect, useState } from "react";
import Right from "../../../Assets/rightarrow.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getInvite } from "../hooks/getInvite";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { updateUser } from "../hooks/updateUser";
import "../../../Components/Loading/Loading.css";
import Loader from "react-loader-spinner";
import { publicLink } from "../hooks/publicLink";
import "./confirmation.css";
import { setInviteText } from "../../../redux/reducers/Invite/InviteSlice";
import { useDispatch } from "react-redux";
import TextFieldCustom from "../../../Components/TextField/TextFieldCustom";
import { GoArrowRight } from "react-icons/go";
import TextField from "../../../Components/Dexta/TextField/TextField";
import { ToastContainer, Zoom, toast } from "react-toastify";
import queryString from "query-string";

const Confirmation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { data, error, isLoading, isError } = useQuery(
    ["invite", id, parsed?.email],
    () => getInvite(id, parsed?.email),
    {
      onSuccess: (data) => {
        const theme = {
          logo: data?.recruiter_company_detail?.companyAvatar,
          color: data?.recruiter_company_detail?.color,
          name: data?.assessments?.user?.companyName,
          sec_color: data?.recruiter_company_detail?.sec_color,
        };
        localStorage.setItem("theme", JSON.stringify(theme));
      },
      onError: (error) => {},
    }
  );

  const module_info = { tests: 0, time: 0 };
  const [uuid, setuuid] = useState(false);
  const [company, setCompany] = useState("");
  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading) {
      if (error?.response?.data?.message[0].includes("Invalid")) {
        navigate("/invalid");
      }

      if (error?.response?.data?.message[0].includes("Assessment link")) {
        navigate(`/expired`);
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (error?.response?.data?.message.includes("Assessment link")) {
        navigate("/expired");
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (
        error?.response?.data?.message[0].includes("already") &&
        error?.response?.data?.message[0].includes("Link")
      ) {
        navigate(`/access-denied?code=${id}`);
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (
        error?.response?.data?.message[0].includes("already") &&
        error?.response?.data?.message[0].includes("completed")
      ) {
        navigate(`/expired`);
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (error?.response?.data?.message?.includes("5")) {
        navigate(`/error`);
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (error?.response?.data?.message[0].includes("not active")) {
        navigate(`/error`);
        dispatch(setInviteText(error?.response?.data?.message));
      }
      if (data?.accessToken) {
        localStorage.setItem("CP-CANDIDATE-TOKEN", data.accessToken);
      }
      if (data?.recruiter_company_detail?.color) {
      }
      if (data?.user?.id) {
        localStorage.setItem("CP-CANDIDATE-ID", data.user.id);
      }

      if (data?.assessments?.id) {
        localStorage.setItem("CANDIDATE-ASSESSMENT-ID", data?.assessments?.id);
      }
    }
  }, [error?.response?.data?.message[0], data]);

  const { mutate, isLoading: mutateLoading } = useMutation(updateUser, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("users");
      module_info.tests = data?.totalSection;
      module_info.time = data?.totalTime;
      localStorage.setItem("module_info", JSON.stringify(module_info));
      localStorage.setItem(
        "CP-CANDIDATE-FirstName",
        validation?.values?.firstName
      );
      localStorage.setItem(
        "CP-CANDIDATE-LastName",
        validation?.values?.lastName
      );
      localStorage.setItem("CP-CANDIDATE-Role", res?.roles);
      navigate(
        `/information?firstName=${
          data?.user?.firstName != ""
            ? validation?.values?.firstName
            : validation?.values?.firstName
        }&lastName=${
          data?.user?.lastName != ""
            ? validation?.values?.lastName
            : validation?.values?.lastName
        }&Company=${data?.assessments?.user?.companyName}`
      );
    },
    onError: (response) => {},
  });

  const { mutate: publicmutate, isLoading: publicLoading } = useMutation(
    updateUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("users");
        localStorage.setItem("CP-CANDIDATE-EMAIL", validation?.values?.email);
        localStorage.setItem(
          "CP-CANDIDATE-FirstName",
          validation?.values?.firstName
        );
        localStorage.setItem(
          "CP-CANDIDATE-LastName",
          validation?.values?.lastName
        );
        navigate(
          `/information?firstName=${validation?.values?.firstName}&lastName=${validation?.values?.lastName}&Company=${company}`
        );
      },
      onError: (response) => {
        const errorMessage = response?.response?.data?.message;

        if (Array.isArray(errorMessage)) {
          setTimeout(() => {
            toast.error(errorMessage[0]);
          }, 500);
        } else if (typeof errorMessage === "string") {
          setTimeout(() => {
            toast.error(errorMessage);
          }, 500);
        } else {
          toast.error("An error occurred.");
        }
      },
    }
  );

  const { mutate: assessmentMutate, isLoading: assessmentLoading } =
    useMutation(publicLink, {
      onSuccess: (response) => {
        console.log(response, "response");
        const user_data = JSON.parse(response?.old_data);
        queryClient.invalidateQueries("assessment");
        localStorage.setItem("CP-CANDIDATE-TOKEN", response?.accessToken);
        localStorage.setItem("CP-CANDIDATE-ID", response?.user?.id);
        localStorage.setItem("CP-CANDIDATE-EMAIL", validation?.values?.email);
        module_info.tests = response?.totalSection;
        module_info.time = response?.totalTime;
        localStorage.setItem("module_info", JSON.stringify(module_info));
        setCompany(response?.assessments?.user?.companyName);
        let data = JSON.stringify({
          firstName: user_data?.firstName,
          lastName: user_data?.lastName,
          email: response?.user?.email,
        });
        try {
          publicmutate(data);
        } catch {
          //don't need to catch error
        }
      },
      onError: (response) => {
        // if (response?.response?.data?.message[0].includes("Assessment link")) {
        //   navigate(`/expired?company=${data?.assessments?.user?.companyName}`)
        // }
        if (response?.response?.data?.message[0].includes("already")) {
          navigate(`/expired`);
          dispatch(setInviteText(response?.response?.data?.message));
        }
      },
    });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data?.user?.firstName != " " ? data?.user?.firstName : "",
      lastName: data?.user?.lastName != "" ? data?.user?.lastName : "",
      email: data?.user?.email != "" ? data?.user?.email : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("First Name Required"),
      lastName: Yup.string()
        .min(3, "Too Short!")
        .max(50, "Too Long!")
        .required("Last Name Required"),
      email: Yup.string(),
    }),
    onSubmit: (values) => {
      if (uuid) {
        let mainData = {
          code: data?.assessments?.code,
          data: JSON.stringify({
            firstName: values?.firstName,
            lastName: values?.lastName,
            email: values?.email,
            attemptCode: id,
          }),
        };
        try {
          assessmentMutate(mainData);
        } catch {
          //don't need to catch error
        }
      } else {
        let data = JSON.stringify({
          firstName: values?.firstName,
          lastName: values?.lastName,
        });
        try {
          mutate(data);
        } catch {
          //don't need to catch error
        }
      }
    },
  });

  const CheckIfUUIDorToken = () => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isValidUUID = regexExp.test(id);
    if (isValidUUID) {
      setuuid(true);
      localStorage.setItem("assessment", "link_invite");
    } else {
      setuuid(false);
      localStorage.setItem("assessment", "email_invite");
    }
  };

  useEffect(() => {
    CheckIfUUIDorToken();
  }, []);
  document.title = "Confirmation | Dexta";

  const [isHovered, setIsHovered] = useState(false);

  //#region Function to darken color

  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }

  // Example usage:
  const originalColor = data?.recruiter_company_detail.color;
  const darkenedColor = darkenHexColor(originalColor, 15);

  //#endregion
  const buttonStyle = {
    background: isHovered
      ? darkenedColor
      : data?.recruiter_company_detail.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: data?.recruiter_company_detail.sec_color,
    border: `1px solid ${data?.recruiter_company_detail.sec_color}`,
    fontFamily: "Archia Semibold",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <nav
        className={`xl:static 2xl:fixed w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        {!isLoading && (
          <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
            <div className="grid grid-cols-3 container mx-auto">
              <img
                src={data?.recruiter_company_detail?.companyAvatar}
                className=" object-contain"
                style={{ height: "70px" }}
              />
            </div>
          </div>
        )}
      </nav>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          {isLoading ? (
            <div class="loader-container-1 col-span-6">
              <div class="loader-1"></div>
            </div>
          ) : (
            <div className="flex justify-center h-screen">
              <div className="m-auto lg:w-1/2 rounded-md bg-white">
                <div className="rounded-lg">
                  <div className="p-8">
                    <div className="flex flex-col items-center">
                      <img
                        src={data?.recruiter_company_detail?.companyAvatar}
                        className=" object-contain w-[100px] h-10 mr-auto"
                      />
                      <div className="flex flex-col mr-auto">
                        <div
                          className="flex flex-row items-center"
                          style={{ fontFamily: "Silka" }}
                        >
                          <p className="text-black text-left font-bold mr-2 my-auto">
                            Role{" "}
                            <span className="font-bold my-auto text-gray-400">
                              {data?.assessments?.name}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p
                      className="mt-5 text-black font-bold"
                      style={{ fontFamily: "Silka" }}
                    >
                      Please confirm who you are
                    </p>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mt-5">
                        <TextField
                          type="text"
                          name="firstName"
                          label="First name"
                          value={validation.values.firstName || ""}
                          rounded="rounded-md"
                          border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                            validation.values.firstName && "border-coalColor"
                          }`}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          placeholder="Enter your first name"
                        />
                      </div>
                      {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <p className="text-rose-500 text-xs ml-2">
                          {validation.errors.firstName}
                        </p>
                      ) : null}

                      <div className="mt-5">
                        <TextField
                          type="text"
                          name="lastName"
                          label="Last name"
                          value={validation.values.lastName || ""}
                          rounded="rounded-md"
                          border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                            validation.values.lastName && "border-coalColor"
                          }`}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          placeholder="Enter your last name"
                        />
                      </div>
                      {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <p className="text-rose-500 text-xs ml-2">
                          {validation.errors.lastName}
                        </p>
                      ) : null}

                      {uuid && (
                        <>
                          <div className="mt-5">
                            <TextField
                              type="text"
                              name="email"
                              label="Email"
                              value={validation.values.email || ""}
                              rounded="rounded-md"
                              border={`border border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                                validation.values.email && "border-coalColor"
                              }`}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              placeholder="Enter your email"
                            />
                          </div>
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <p className="text-rose-500 text-xs ml-2">
                              {validation.errors.email}
                            </p>
                          ) : null}
                        </>
                      )}
                      <div className="flex flex-row p-2 mt-3">
                        <label
                          className="relative flex cursor-pointer items-center rounded-full"
                          data-ripple-dark="true"
                        >
                          <input
                            id="ripple-on"
                            type="checkbox"
                            required
                            onInvalid={(e) => {
                              const target = e.target;
                              target.setCustomValidity(
                                "Please review Privacy Policy and check box to proceed"
                              );
                            }}
                            onChange={(e) => {
                              const target = e.target;
                              if (target.checked) {
                                target.setCustomValidity("");
                              }
                            }}
                            className={`peer relative h-5 w-5 cursor-pointer checked:bg-coalColor appearance-none rounded border-[1px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-600 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor hover:before:opacity-50`}
                          />
                          <div
                            className={`pointer-events-none absolute top-2/4 left-2/4 text-primaryGreen -translate-y-2/4 -translate-x-2/4 opacity-0 transition-opacity peer-checked:opacity-100`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3.5 w-3.5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              strokeWidth="1"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </label>
                        <p
                          style={{ fontFamily: "Silka" }}
                          className="ml-2 sm:text-xs md:text-sm text-[#6B6B6B]"
                        >
                          I have read and I accept the{" "}
                          <b
                            onClick={() =>
                              navigate("/candidate-recruiter/privacy-policy")
                            }
                            style={{
                              color: data?.recruiter_company_detail.sec_color,
                            }}
                            className="cursor-pointer"
                          >
                            privacy policy
                          </b>
                        </p>
                      </div>
                      {uuid ? (
                        <button
                          className={`inline-flex items-center w-full justify-center px-4 mt-5 py-4 text-white text-base font-medium rounded-md`}
                          style={buttonStyle}
                          onMouseEnter={handleHover}
                          onMouseLeave={handleLeave}
                          type="submit"
                          disabled={
                            validation.values.firstName === "" ||
                            validation.values.lastName === "" ||
                            validation.values.email === ""
                          }
                        >
                          {assessmentLoading || publicLoading ? (
                            <span className="flex items-center justify-center">
                              <Loader
                                type="Oval"
                                color={data?.recruiter_company_detail.sec_color}
                                height={20}
                                width={20}
                              />
                              <span className="ml-2">Continuing...</span>
                            </span>
                          ) : (
                            "Continue"
                          )}
                          <GoArrowRight
                            alt="Add Transaction Icon"
                            className="w-5 h-5 ml-2 icon-image"
                          />
                        </button>
                      ) : (
                        <button
                          className={`inline-flex items-center w-full justify-center px-4 mt-5 py-4 text-white text-base font-medium rounded-md border border-${data?.recruiter_company_detail.sec_color}`}
                          style={buttonStyle}
                          onMouseEnter={handleHover}
                          onMouseLeave={handleLeave}
                          type="submit"
                          disabled={
                            validation.values.firstName === "" ||
                            validation.values.lastName === ""
                          }
                        >
                          {mutateLoading ? (
                            <span className="flex items-center justify-center">
                              <Loader
                                type="Oval"
                                color={data?.recruiter_company_detail.sec_color}
                                height={20}
                                width={20}
                              />
                              <span className="ml-2">Continuing...</span>
                            </span>
                          ) : (
                            "Continue"
                          )}
                          <GoArrowRight
                            alt="Add Transaction Icon"
                            className="w-5 h-5 ml-2 icon-image"
                          />
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Confirmation;
