import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import TextField from "../TextField/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./modals.css";
import Loader from "react-loader-spinner";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { getCandidateDetails } from "../../Pages/Candidates/hooks/getCandidateDetails";
import { getUserDetails } from "../hooks/getUserDetails";
import { updateEmail } from "../hooks/updateEmail";
import { getUserData } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getUserData";
import CloseModal from "../../Dexta_assets/closeModal.png";
import { getUserBioData } from "../hooks/getUserBiodata";
import { updateCandidateEmail } from "../../Pages/Candidates/hooks/updateCandidateEmail";

export default function ChangeEmail({
  emailOpen,
  setEmailOpen,
  candidateChange,
}) {
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const candidateID = localStorage.getItem("CP-CANDIDATE-ID");
  const [error_, setError] = useState(false);
  const [isPasswordVisible1, setIsPasswordVisible1] = useState(false);
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false);
  const [isPasswordVisible3, setIsPasswordVisible3] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  const queryClient = useQueryClient();

  //#region Formik validations
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
        password: values?.password,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region Updating Password
  const { mutate, isLoading } = useMutation(
    candidateChange ? updateCandidateEmail : updateEmail,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users/email");
        toast.success("Email Changed");
        setEmailOpen(!emailOpen);
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;

        if (Array.isArray(errorMessage)) {
          toast.error(errorMessage[0]);
        } else if (typeof errorMessage === "string") {
          toast.error(errorMessage);
        } else {
          toast.error("An error occurred.");
        }
      },
    }
  );
  //#endregion

  //#region Fetching User Details
  const {
    data,
    isLoading: userLoading,
    error,
  } = useQuery(["users", candidateChange ? candidateID : userID], () => {
    if (candidateChange) {
      return getUserBioData(candidateID);
    } else {
      return getUserData(userID);
    }
  });
  //#endregion

  return (
    <Transition.Root show={emailOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setEmailOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-5/6 md:max-w-lg"
                style={{ height: "550px" }}
              >
                <div className="bg-white">
                  <p
                    className="text-lg font-medium text-coalColor cursor-pointer text-left pl-5 pt-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    Change Password
                  </p>
                  <img
                    src={CloseModal}
                    className="absolute top-4 right-5 z-20 w-6 h-6 cursor-pointer"
                    onClick={() => setEmailOpen(false)}
                  />
                </div>
                <div className="px-8 pt-8">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="mt-4 h-20">
                      <TextField
                        value={data?.email}
                        label="Current Email"
                        bgTextColor="bg-white"
                      />
                    </div>
                    <div className="mt-4 h-20">
                      <TextField
                        id="email"
                        type="email"
                        name="email"
                        value={validation.values.email || ""}
                        label="New Email"
                        onChangeValue={validation.handleChange}
                        onBlur={validation.handleBlur}
                        bgTextColor="bg-white"
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <div className="ml-1 fade-in-text-validations">
                          <p className="text-rose-500 sm:text-xs md:text-sm">
                            {validation.errors.email}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4 h-20">
                      <TextField
                        id="password"
                        type={isPasswordVisible2 ? "text" : "password"}
                        name="password"
                        value={validation.values.password || ""}
                        label="Current Password"
                        onChangeValue={validation.handleChange}
                        onBlur={validation.handleBlur}
                        isPasswordVisible={isPasswordVisible2}
                        setIsPasswordVisible={setIsPasswordVisible2}
                        passwordIcon={passwordIcon}
                        bgTextColor="bg-white"
                      />
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <div className="ml-1 fade-in-text-validations">
                          <p className="text-rose-500 sm:text-xs md:text-sm">
                            {validation.errors.password}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div
                      className="mt-14 gap-3 justify-center flex flex-row"
                      style={{ fontFamily: "Silka" }}
                    >
                      <button
                        className="inline-flex items-center w-[7rem] justify-center px-6 mt-5 py-3 bg-coalColor hover:bg-primaryGreen hover:text-coalColor text-white text-base font-medium border border-coalColor rounded-md"
                        type="button"
                        onClick={() => setEmailOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`inline-flex items-center w-[7rem] justify-center px-6 mt-5 py-3 bg-primaryGreen text-coalColor border border-coalColor hover:bg-coalColor hover:text-white
                                            ${
                                              validation?.values?.email ===
                                                "" ||
                                              validation?.values?.password ===
                                                ""
                                                ? "bg-primaryGreen cursor-not-allowed"
                                                : "bg-primaryGreen"
                                            }  text-coalColor text-base font-medium rounded-md`}
                        type="submit"
                        disabled={
                          validation?.values?.email === "" ||
                          validation?.values?.password === ""
                        }
                      >
                        {isLoading ? (
                          <span className="flex items-center justify-center">
                            <Loader
                              type="Oval"
                              color="black"
                              height={20}
                              width={20}
                            />
                            <span className="ml-2">Saving...</span>
                          </span>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
