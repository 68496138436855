import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Logo from "../../../Assets/mainLOGO.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaInfoCircle } from "react-icons/fa";
import { handlePassword } from "../../../Components/Regex/Regex";
import "./auth.css";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../Auth/hooks/loginUser";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";

const Signin = () => {
  const [focusedField, setFocusedField] = useState(null);
  const [BarPercentage, setBarPercentage] = useState(0);
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState({
    capital: false,
    number: false,
    length: false,
    special: false,
  });

  //#region calculating trues for bar percentage
  useEffect(() => {
    const trueCount = Object.values(passwordValidation).filter(Boolean).length;
    const newBarPercentage = trueCount * 25;
    setBarPercentage(newBarPercentage);
  }, [passwordValidation]);
  //#endregion

  //#region Checking Passwords
  const checkingPassword = (text) => {
    const { hasNumber, hasCapital, hasLength, hasSpecialCharacter } =
      handlePassword(text);
    setPasswordValidation({
      number: hasNumber,
      capital: hasCapital,
      length: hasLength,
      special: hasSpecialCharacter,
    });
  };
  //#endregion

  //#region Handle Focus and Blur
  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  //#endregion

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        emailOrUsername: values?.email,
        password: values?.password,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region login with candidate
  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-in");
      localStorage.clear();
      if (response.accessToken) {
        localStorage.setItem("CP-CANDIDATE-TOKEN", response.accessToken);
      }
      if (response.user.id) {
        localStorage.setItem("CP-CANDIDATE-ID", response.user.id);
      }
      if (response?.user?.isUserOnboard === false) {
        navigate("/candidate/profile");
      } else if (response?.user?.isUserOnboard === true) {
        navigate("/candidate/dashboard");
      }
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      toast.success(error.response.data.message[0]);
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region handle back browser
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave this page?";
      event.returnValue = message; // Standard for most browsers
      return message; // For some old browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, null, window.location.href);
    };

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.onpopstate = null;
    };
  }, []);
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center px-2 lg:px-0 h-screen">
        <div className="m-auto w-full lg:w-2/5 rounded-md bg-white">
          <div className="rounded-lg">
            <div className="sm:p-5 lg:p-14">
              <img src={Logo} className="h-11 w-40" alt="CP Logo" />
              <h1
                className="pt-10 text-xl font-bold"
                style={{ fontFamily: "Archia Bold" }}
              >
                Log in to your Dexta Profile
              </h1>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                autoComplete="off"
              >
                <TextField
                  type="email"
                  name="email"
                  label="Enter your email"
                  placeholder="Enter your email"
                  rounded="rounded-md"
                  border={`border mt-5 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    validation.values.firstName && "border-coalColor"
                  }`}
                  onChange={(e) => {
                    validation.handleChange(e);
                    checkingPassword(validation.values.password);
                  }}
                  onFocus={() => handleFocus("email")}
                  onBlur={validation.handleBlur && handleBlur}
                  value={validation.values.email || ""}
                />
                {count === 0 ? (
                  <TextField
                    id="password"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    label="Enter your password"
                    rounded="rounded-md"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.firstName && "border-coalColor"
                    }`}
                    onChange={(e) => {
                      validation.handleChange(e);
                      checkingPassword(e.target.value);
                    }}
                    onFocus={() => {
                      handleFocus("password");
                      setCount(count + 1);
                    }}
                    onBlur={validation.handleBlur && handleBlur}
                    value={validation.values.password || ""}
                    placeholder="Enter your password"
                    isPasswordVisible={isPasswordVisible}
                    onClick={togglePasswordVisibility}
                    passwordIcon={true}
                  />
                ) : (
                  <TextField
                    id="password"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    label="Enter your password"
                    rounded="rounded-md"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.firstName && "border-coalColor"
                    }`}
                    onChange={(e) => {
                      validation.handleChange(e);
                      checkingPassword(e.target.value);
                    }}
                    onFocus={() => handleFocus("password")}
                    onBlur={validation.handleBlur && handleBlur}
                    isPasswordVisible={isPasswordVisible}
                    passwordIcon={true}
                    onClick={togglePasswordVisibility}
                    value={validation.values.password || ""}
                    placeholder="Enter your password"
                  />
                )}
                <div className="flex justify-between mt-6">
                  <div
                    className="flex flex-row p-2"
                    style={{ fontFamily: "Silka" }}
                  >
                    <input
                      type="checkbox"
                      className="default:ring-2 ... accent-coalColor border-2 my-auto border-black w-4 h-4"
                    />
                    <p className="ml-2 sm:text-xs md:text-sm text-black">
                      Keep me logged in
                    </p>
                  </div>
                  <p
                    className="ml-2 p-2 sm:text-xs md:text-sm text-black cursor-pointer"
                    style={{ fontFamily: "Silka" }}
                    onClick={() => navigate("/candidate/forget-password")}
                  >
                    Forgot password?
                  </p>
                </div>
                <div className="mb-3 mt-6 flex justify-center items-center flex-col px-1">
                  <button
                    type="submit"
                    name="Sign up"
                    style={{ fontFamily: "Silka" }}
                    className="bg-primaryGreen text-coalColor w-full h-12 rounded-lg border border-coalColor font-medium sm:text-sm md:text-lg relative"
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <Loader
                          type="Oval"
                          color="black"
                          height={20}
                          width={20}
                        />
                        <span className="ml-2">Logging in...</span>
                      </span>
                    ) : (
                      "Log in"
                    )}
                  </button>
                </div>
                <span
                  className="text-coalColor text-sm text-center p-2"
                  style={{ fontFamily: "Silka" }}
                >
                  Don't have an account?{" "}
                  <span
                    className="text-coalColor font-medium cursor-pointer"
                    style={{ fontFamily: "Silka Bold" }}
                    onClick={() => navigate("/candidate/create-account")}
                  >
                    Create one here
                  </span>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signin;
