import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Plans from "./Plans";
import { excludedRoles } from "../Constants/constants";
import { useSelector } from "react-redux";
import Loader from "react-loader-spinner";
export default function GeneralModal({
  generalModal,
  setGeneralModal,
  heading,
  description,
  generalCase = false,
  onButtonClick,
  buttonText,
  loadingBtn,
  loadingText,
}) {
  const cancelButtonRef = useRef(null);
  const [plansModal, setPlansModal] = useState(false);
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  return (
    <Transition.Root show={generalModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setGeneralModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-100" // Adjust the duration here (make it smaller for a faster closing animation)
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-2 sm:translate-y-0 sm:scale-95" // Adjust the translate-y and scale for a faster closing animation
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Plans plansModal={plansModal} setPlansModal={setPlansModal} />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h1"
                        className="text-2xl text-center mt-5 font-semibold leading-6 text-gray-900"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        {heading}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p
                          className="text-lg text-center text-black mt-5"
                          style={{ fontFamily: "Silka" }}
                        >
                          {description}
                        </p>
                        {excludedRoles.includes(user_role) && (
                          <p
                            className="text-lg text-center text-black mt-5"
                            style={{ fontFamily: "Silka" }}
                          >
                            <b>Note:</b> Only Owner and Admin can upgrade the
                            package
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {generalCase ? (
                  <div className="bg-gray-50 px-4 pb-6 sm:flex sm:flex-row-reverse sm:px-6 justify-center">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-black bg-primaryGreen px-5 py-2 text-sm font-semibold text-coalColor shadow-sm hover:bg-primaryGreen/90 sm:ml-3 sm:w-auto"
                      onClick={onButtonClick}
                      style={{ fontFamily: "Silka" }}
                    >
                      {loadingBtn ? (
                        <span className="flex items-center justify-center">
                          <Loader
                            type="Oval"
                            color="black"
                            height={20}
                            width={20}
                          />
                          <span className="ml-2">{loadingText}</span>
                        </span>
                      ) : (
                        <> {buttonText}</>
                      )}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-coalColor px-5 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset  sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setGeneralModal(false);
                      }}
                      ref={cancelButtonRef}
                      style={{ fontFamily: "Silka" }}
                    >
                      Close
                    </button>
                  </div>
                ) : (
                  <>
                    {!excludedRoles.includes(user_role) && (
                      <div className="bg-gray-50 px-4 pb-6 sm:flex sm:flex-row-reverse sm:px-6 justify-center">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md border border-black bg-primaryGreen px-5 py-2 text-sm font-semibold text-coalColor shadow-sm hover:bg-primaryGreen/90 sm:ml-3 sm:w-auto"
                          onClick={() => {
                            setPlansModal(true);
                          }}
                          style={{ fontFamily: "Silka" }}
                        >
                          Upgrade
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-coalColor px-5 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset  sm:mt-0 sm:w-auto"
                          onClick={() => {
                            setGeneralModal(false);
                          }}
                          ref={cancelButtonRef}
                          style={{ fontFamily: "Silka" }}
                        >
                          Close
                        </button>
                      </div>
                    )}
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
