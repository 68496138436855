import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Delete from "../../../../../Assets/dustV.png";
import { MdDragIndicator } from "react-icons/md";

const DraggableElement = ({
  element,
  index,
  moveElement,
  elements,
  setElements,
  handleCorrectOption,
  handleTextEdit,
  setDeletedID,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const [, drag] = useDrag({
    type: "ELEMENT",
    item: { index },
    canDrag: !isDragging,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "ELEMENT",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveElement(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => drop(drag(node))}
      className="relative p-1 mb-2 flex justify-between items-center bg-white shadow rounded-lg"
    >
      <div className="flex justify-start items-center w-full">
        <div className="flex items-center p-3">
          <input
            id={`checkbox-${element?.id}`}
            type="checkbox"
            checked={element?.isCorrectOption}
            className="w-4 h-4 text-teal-600 bg-gray-100 cursor-pointer accent-primaryGreen border-gray-300 rounded focus:ring-teal-500"
            onChange={(e) => handleCorrectOption(index, e.target.checked)}
            style={{ fontFamily: "Silka" }}
          />
        </div>
        <input
          type="text"
          value={element?.title}
          placeholder="Insert answer"
          className="w-full pr-12 overflow-hidden max-h-16 ml-0 text-sm tracking-wide"
          onChange={(e) => handleTextEdit(index, e.target.value)}
          onMouseDown={() => setIsDragging(true)}
          onMouseUp={() => setIsDragging(false)}
          style={{ fontFamily: "Silka" }}
        />
        {/* Icons positioned absolutely */}
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex items-center">
          <MdDragIndicator
            className="w-6 h-6 cursor-move mr-2"
            onMouseDown={() => setIsDragging(false)}
          />
          <img
            src={Delete}
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              setDeletedID(element.id);
              const newElements = [...elements];
              newElements.splice(index, 1);
              setElements(newElements);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DraggableElement;
