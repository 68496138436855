import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { loginUser } from "./hooks/loginUser";
import GoogleLogin from "@leecheuk/react-google-login";
import http from "../../http";
import { ToastContainer, Zoom, toast } from "react-toastify";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";
import MainLogo from "../../Dexta_assets/LoginLogov4.png";
import Email from "../../Dexta_assets/email-image.png";
import Password from "../../Dexta_assets/password-image.png";
import TextField from "../../Components/Dexta/TextField/TextField";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";
import { setAccountTypeofUser } from "../../redux/reducers/AccountType/AccountTypeSlice";
import "./Auth.css";

const clientId =
  "716905942693-t5iir724oc76eiaujet95di5tfmtqokh.apps.googleusercontent.com";

const Login = () => {
  //#region useStates
  const location = useLocation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const parsed = queryString.parse(location.search);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [isLoad, setIsLoading] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [emailnew, setEmailnew] = useState("");
  const [toastError, setToasterror] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);
  const dispatch = useDispatch();
  const [dispatchLoad, setDispatchLoad] = useState(false);
  //#endregion

  //#region logging user in
  const { mutate, isLoading } = useMutation(loginUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-in");
      console.log(response, "login res");
      if (response.accessToken) {
        localStorage.setItem("CP-USER-TOKEN", response.accessToken);
      }
      if (response.user.id) {
        localStorage.setItem("CP-USER-ID", response.user.id);
      }
      if (response?.user.accountType) {
        dispatch(setAccountTypeofUser(response?.user?.accountType));
      }
      if (response?.user?.roles === "admin") {
        setErrorMessage("Invalid Credentials");
        setTimeout(() => {
          toast.success(errorMessage);
        }, 500);
        return;
      }
      if (response.user.roles === "user") {
        toast.success("Redirecting to candidate login");
        localStorage.clear();
        setTimeout(() => {
          navigate("/candidate/login");
        }, 1500);
      } else {
        if (
          response?.user?.packageID === 0 ||
          response?.user?.packageID === null
        ) {
          navigate("/packages");
        }
      }
      getPackageUser(response.user.id);
      // navigate("/dashboard")
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      toast.success(error.response.data.message[0]);
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region fetching package details
  useEffect(() => {
    if (parsed && parsed.package_id) {
      localStorage.setItem("CP-PACKAGE-ID", String(parsed.package_id));
      if (localStorage.getItem("CP-USER-TOKEN")) {
        navigate(`/place_order?package_id=${parsed.package_id}`);
      }
    }
  }, []);
  //#endregion

  //#region success and error toasts
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess);
    }
    setTimeout(() => {
      setToastSuccess("");
    }, 1000);
  }, [toastSuccess]);

  useEffect(() => {
    if (toastError != "") {
      toast.success(toastError);
    }
    setTimeout(() => {
      setToasterror("");
    }, 1000);
  }, [toastError]);
  //#endregion

  //#region formik validations and handle submit function
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        emailOrUsername: values?.email,
        password: values?.password,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region Google Sign in
  const signInWithDiscord = (res) => {
    setIsLoading(true);
    try {
      http
        .request({
          method: "post",
          maxBodyLength: Infinity,
          url: "/auth/google",
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            idToken: res.tokenId,
          }),
        })
        .then((response) => {
          const { accessToken, user } = response.data;
          if (accessToken) {
            localStorage.setItem("CP-USER-TOKEN", accessToken);
          }
          if (user) {
            localStorage.setItem("CP-USER-ID", user.id);
          }
          if (localStorage.getItem("CP-PACKAGE-ID")) {
            setIsLoading(false);
            navigate(
              `/place_order?package_id=${localStorage.getItem("CP-PACKAGE-ID")}`
            );
          } else {
            setIsLoading(false);
            getPackageUser(user.id);
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.message[0] ===
              "We don't accept personal emails. Please enter a work email."
            ) {
              toast.success("Personal emails are not allowed");
            }
          } else if (error.request) {
            // console.log(error.request);
            alert(error.request);
          } else {
            // console.log('Error', error.message);
            alert(error.message);
          }
        });
    } catch (error) {
      setIsLoading(false);
      // console.log("🚀 ~ file: LoginWithFacebook.tsx:57 ~ signInWithDiscord ~ error:", error)
    }
  };
  //#endregion

  //#region Fetching user Package Details
  const getPackageUser = async (pID) => {
    try {
      setDispatchLoad(true);
      const response = await http.get(
        `/subscriptions/subscriptionsByUserId/${pID}`
      );
      dispatch(setPackageData(response?.data?.package?.code));
      navigate("/dashboard");
      setDispatchLoad(false);
    } catch (err) {
      console.log(err.message);
      setDispatchLoad(false);
    }
  };
  //#endregion

  document.title = "Login | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Log In{" "}
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <span>
                Don't have an account?{" "}
                <a
                  className="underline cursor-pointer"
                  onClick={() => navigate("/packages")}
                >
                  Sign up
                </a>
              </span>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              id="login"
            >
              <div className="mt-6">
                <div className="h-[4rem]">
                  <TextField
                    name="email"
                    id="email"
                    type="email"
                    placeholder="Work email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    imageUrl={Email}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.email}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="h-[4rem]">
                  <TextField
                    name="password"
                    id="password"
                    type={isPasswordVisible ? "text" : "password"}
                    placeholder="Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    marginTop="mt-5"
                    onClick={togglePasswordVisibility}
                    imageUrl={Password}
                    passwordIcon={true}
                    isPasswordVisible={isPasswordVisible}
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <div className="ml-1">
                      <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                        {validation.errors.password}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between mt-3">
                <div className="flex flex-row p-2">
                  <label
                    className="relative my-auto flex cursor-pointer items-center rounded-full"
                    data-ripple-dark="true"
                  >
                    <input
                      id="ripple-on"
                      type="checkbox"
                      className={`peer relative h-4 w-4 cursor-pointer checked:bg-primaryGreen appearance-none rounded border-[1px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-700  before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-50 `}
                    />
                    <div
                      className={`pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </label>
                  <p
                    className="ml-2 text-sm my-auto"
                    style={{ fontFamily: "Silka Light" }}
                  >
                    Remember me
                  </p>
                </div>
                <p
                  className="ml-2 p-2 sm:text-xs md:text-sm cursor-pointer"
                  style={{ fontFamily: "Silka Light" }}
                  onClick={() => navigate("/forget-password")}
                >
                  Forgot password?
                </p>
              </div>
              <CustomButton
                label="LOGIN"
                borderCustom="border border-black text-white"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                paddingY="0.7rem"
                marginTop="mt-8"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading || dispatchLoad}
                loadingText="LOGGING IN"
              />
              <GoogleLogin
                clientId={clientId}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    name="SignIn"
                    className="text-[#252E3A] bg-white font-medium rounded text-lg w-full h-12 text-center flex justify-items-start items-center mr-2 mb-2"
                  >
                    {isLoad ? (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 ml-3 text-black animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    ) : null}
                    <p
                      className="flex ml-auto mr-auto sm:text-xs md:text-sm font-medium"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      {!isLoad && (
                        <svg
                          className="md:w-4 sm:w-3 md:h-4 sm:h-3 sm:mr-2 md:mr-3 ml-3 my-auto"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 19"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8.842 18.083a8.8 8.8 0 0 1-8.65-8.948 8.841 8.841 0 0 1 8.8-8.652h.153a8.464 8.464 0 0 1 5.7 2.257l-2.193 2.038A5.27 5.27 0 0 0 9.09 3.4a5.882 5.882 0 0 0-.2 11.76h.124a5.091 5.091 0 0 0 5.248-4.057L14.3 11H9V8h8.34c.066.543.095 1.09.088 1.636-.086 5.053-3.463 8.449-8.4 8.449l-.186-.002Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                      SIGN IN WITH GOOGLE WORKSPACE
                    </p>
                    <FaArrowRightLong
                      className={`my-auto sm:mr-3 md:mr-5 w-4 h-4`}
                    />
                  </button>
                )}
                buttonText="Login"
                onSuccess={(res) => signInWithDiscord(res)}
                onFailure={(res) => signInWithDiscord(res)}
                cookiePolicy={"single_host_origin"}
                pluginName={"stargateloginwithgoogle"}
              />
            </form>
            <p className="text-xs mt-5" style={{ fontFamily: "Silka" }}>
              By creating an account, you confirm that you have read and agree
              to Dexta's{" "}
              <b
                className="text-coalColor font-medium cursor-pointer"
                style={{ fontFamily: "Silka Bold" }}
                onClick={() => navigate("/term-of-use")}
              >
                Term of Use
              </b>{" "}
              and{" "}
              <b
                className="text-coalColor font-medium cursor-pointer"
                style={{ fontFamily: "Silka Bold" }}
                onClick={() => navigate("/candidate-recruiter/privacy-policy")}
              >
                Privacy Policy
              </b>
            </p>
            <p
              onClick={() => navigate("/candidate/create-account")}
              className="text-xs mt-8 cursor-pointer"
              style={{ fontFamily: "Silka" }}
            >
              Are you a candidate? <b>Click here to create account</b>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
