import React, { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import "../../../../../Components/Loading/Loading2.css";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "../../../../../Components/Loading/Loading7.css";
import "../invite.css";
import { useSelector, useDispatch } from "react-redux";
import { getAssessmentByID } from "../hooks/getAssessmentByID";
import { createCustomQuestions } from "../hooks/createCustomQuestions";
import { useQuery } from "@tanstack/react-query";
import King from "../../../../../Assets/preee.png";
import http from "../../../../../http";
import { toast, ToastContainer, Zoom } from "react-toastify";
import PremiumGeneral from "../../../../../Components/Modals/PremiumGeneral";
import { FaPlus } from "react-icons/fa6";
import { setNextQuestionsToFalse } from "../../../../../redux/reducers/NextQuestions/NextQuestionsSlice";
import QuestionsModal from "./QuestionsModal";
import { TimeData } from "./data";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import { getLibraryQuestions } from "../hooks/getLibraryQuestions";
import { VscLibrary } from "react-icons/vsc";
import info from "../../../../../Dexta_assets/helpIcon.png";
import eye from "../../../../../Dexta_assets/eye.png";
import bin from "../../../../../Dexta_assets/bin.png";
import DeleteIcon from "../../../../../Dexta_assets/deleteIcon.png";
import DeleteModal from "../../../../../Components/ConfirmationModals/DeleteModal";
import { deleteSection } from "../hooks/deleteSection";
import { createCustomSet } from "../hooks/createCustomSet";
import { updateStep } from "../hooks/updateStep";

const Questions = (props) => {
  const [questionSetName, setQuestionSetName] = useState("");
  const [questionSetTime, setQuestionSetTime] = useState(0);
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const assessment_id = localStorage.getItem("assessment_ID");
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumGeneralOpen, setPremiumGeneral] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const next = useSelector((state) => state.nextQuestion.setNextQuestions);
  const [questions, setQuestions] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [questionModal, setQuestionModal] = useState(false);
  const [timeDropdown, setTimeDropdown] = useState(false);
  const [timeTitle, setTimeTitle] = useState("");
  const TimeRef = useRef(null);
  const dispatch = useDispatch();
  const [createQuestionLoading, setCreateQuestionLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [questionID, setQuestionID] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [lastQuestionDelete, setLastQuestionDelete] = useState(false);
  const [adminLibrary, setAdminLibrary] = useState(false);
  const [fakeLoading, setFakeLoading] = useState(false);
  //#region take user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region Submit test
  const handleSubmit = () => {
    let data = {
      content: {
        status: "active",
        notes: "complete",
      },
      categoryID:
        parsed && parsed.assessment_id !== undefined
          ? parsed.assessment_id
          : assessment_id,
    };

    try {
      submitMutate(data);
    } catch (err) {
      //
    }
  };

  const { mutate: submitMutate, isLoading: submitLoading } = useMutation(
    updateStep,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
      },
      onError: (error) => {},
    }
  );

  //#endregion
  const handleModulesIDS = (id) => {
    if (props.data.modulesID.includes(id)) {
      return;
    }
    const newProps = { ...props.data };
    let newModuleID = newProps.customQuestion;
    newModuleID = id;
    newProps["customQuestion"] = newModuleID;
    props.setData(newProps);
  };

  const { mutate, isLoading: mutateLoad } = useMutation(createCustomQuestions, {
    onSuccess: (response) => {
      const { data } = response;
      handleModulesIDS(data?.id);
      queryClient.invalidateQueries("assessment");
      dispatch(setNextQuestionsToFalse(false));
      props.setQuestionLoading(false);
      setQuestionModal(true);
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      if (Array.isArray(error.response.data.message)) {
        toast.error(error.response.data.message[0]);
      } else if (typeof error.response.data.message === "string") {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred.");
      }
    },
  });

  const {
    data: data_assessments,
    error: assessmentError,
    isLoading: assessmentLoading,
    refetch,
  } = useQuery(["assessment_new"], () =>
    getAssessmentByID(
      parsed.assessment_id != null
        ? parsed.assessment_id
        : parseInt(assessment_id)
    )
  );

  useEffect(() => {
    if (!assessmentLoading) {
      refetch();
      if (data_assessments?.customQuestionsList?.name) {
        setQuestionSetName(data_assessments?.customQuestionsList?.name);
      }
      setQuestionSetTime(data_assessments?.customQuestionsList?.time);
      setQuestions(data_assessments?.customQuestionsList?.questions || []);
    }
  }, [data_assessments, assessmentLoading, questionModal]);

  const removeInlineStyles = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove style attributes
    const elementsWithStyle = doc.querySelectorAll("[style]");
    elementsWithStyle.forEach((element) => {
      element.removeAttribute("style");
    });

    // Remove strong tags
    const strongTags = doc.querySelectorAll("strong");
    strongTags.forEach((tag) => {
      const parent = tag.parentNode;
      while (tag.firstChild) {
        parent.insertBefore(tag.firstChild, tag);
      }
      parent.removeChild(tag);
    });

    return doc.documentElement.innerHTML;
  };

  const handleAddCustom = () => {
    if (props?.data?.customQuestion === null) {
      if (
        questionSetName == "" ||
        questionSetName == null ||
        questionSetName == undefined
      ) {
        toast.error("Questions set name can not be empty.");
      } else if (
        questionSetTime == "" ||
        questionSetTime == null ||
        questionSetTime == undefined
      ) {
        toast.error("Questions set time can not be empty.");
      } else {
        let data = {
          name: questionSetName,
          time: questionSetTime,
          assessmentId:
            parsed.assessment_id != null
              ? parseInt(parsed.assessment_id)
              : parseInt(assessment_id),
        };
        try {
          mutate(data);
        } catch (err) {
          //
        }
      }
    } else {
      setQuestionModal(true);
    }
  };

  useEffect(() => {
    if (!assessmentLoading && !data_assessments?.customQuestionsList?.name) {
      setQuestionSetName("");
    }
  }, [data_assessments]);

  const handleNext = () => {
    props.setQuestionLoading(false);
    if (questions.length > 0) {
      if (
        questionSetName == "" ||
        questionSetName == null ||
        questionSetName == undefined
      ) {
        toast.error("Questions set name can not be empty.");
      } else if (
        questionSetTime == "" ||
        questionSetTime == null ||
        questionSetTime == undefined
      ) {
        toast.error("Questions set time can not be empty.");
      } else {
        let data = {
          questions: questions?.map((q) => q?.id),
          name: questionSetName,
          time: questionSetTime,
          assessmentId:
            parsed.assessment_id != null
              ? parseInt(parsed.assessment_id)
              : parseInt(assessment_id),
        };
        try {
          mutate(data);
          props.setselecteditem("candidates");
          localStorage.setItem("current_module", "candidates");
          setQuestionSetName("");
          handleSubmit();
        } catch (err) {
          //
        }
      }
    } else {
      props.setselecteditem("candidates");
      localStorage.setItem("current_module", "candidates");
      dispatch(setNextQuestionsToFalse(false));
      handleSubmit();
    }
  };

  useEffect(() => {
    if (next) {
      handleNext();
    }
  }, [next]);

  const handleBack = () => {
    props.setselecteditem("modules");
    localStorage.setItem("current_module", "modules");
  };

  const deleteQuestion = (id) => {
    setDeleteLoading(true);
    // console.log("id of question", id)
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `/questions/${id}/sections/${props?.data?.customQuestion}`,
      headers: {
        accept: "*/*",
      },
    };
    http
      .request(config)
      .then((response) => {
        const newElements = [...questions];
        const extractIDs = (arr) => arr.map((obj) => obj.id);
        const ids = extractIDs(newElements);
        const index = ids.indexOf(id);
        if (index !== -1) {
          newElements.splice(index, 1);
          setQuestions(newElements);
          setDeleteLoading(false);
          setDeleteModal(false);
        }
        if (lastQuestionDelete) {
          sectionDelete(props?.data?.customQuestion);
        }
        setDeleteLoading(false);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
        setDeleteLoading(false);
        setDeleteModal(false);
      });
  };

  //#region Handling clicks outside ref
  useEffect(() => {
    function HandleOutside(event) {
      if (
        TimeRef.current &&
        !TimeRef.current.contains(event.target) &&
        event.target.id !== "time" &&
        !event.target.closest(".TimeClass")
      ) {
        setTimeDropdown(false);
      }
    }
    document.addEventListener("mousedown", HandleOutside);
    return () => {
      document.removeEventListener("mousedown", HandleOutside);
    };
  }, [TimeRef]);
  //#endregion

  //#region Fetching questions from library
  const {
    data: libraryData,
    error: libraryError,
    isLoading: libraryLoading,
  } = useQuery(["/questions/recruiter/custom-questions", adminLibrary], () =>
    getLibraryQuestions(adminLibrary)
  ); //#endregion

  //#region Add new module from custom library
  const createOption = async (option) => {
    try {
      let cleaObj = option;
      cleaObj = removeEmpty(cleaObj);
      return await http.post(`/options`, cleaObj).then((response) => {
        return response.data;
      });
    } catch (error) {
      console.log("🚀 ~ createOption ~ error:", error);
    }
  };

  function removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null && v !== "")
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  const handleModulesIDSNew = (obj) => {
    const newProps = { ...props?.data };
    let newModuleID = newProps.customQuestionDetail;
    newModuleID = obj;
    newProps["customQuestionDetail"] = newModuleID;
    props?.setData(newProps);
  };

  useEffect(() => {
    if (questionData !== null) {
      AddNewModule(questionData);
    }
  }, [questionData]);

  const AddNewModule = (i) => {
    setCreateQuestionLoading(true);
    setFakeLoading(true);
    setLoadingIndex(libraryData.data.indexOf(i));
    const questionData = {
      section: props?.data?.customQuestion,
      type: i?.type,
      image: i?.image,
      status: "active",
      description: i?.description,
      is_sample: false,
    };
    http
      .post(`/questions`, questionData)
      .then(async (response) => {
        const { data } = response;
        const options = i?.options?.map((ele, index) => {
          return {
            question: data?.id,
            title: ele?.title,
            isCorrectOption: ele?.isCorrectOption,
            optionPosition: ele?.optionPosition,
            status: "active",
          };
        });
        const optionSavedList = await options?.map((opt, index) => {
          return createOption(opt);
        });

        const questionSaveItem = {
          ...data,
          options: await Promise.all(optionSavedList),
        };
        setQuestions([{ ...questionSaveItem }, ...questions]);
        handleModulesIDSNew([{ ...questionSaveItem }, ...questions]);
        setCreateQuestionLoading(false);
        setQuestionData(null);
      })
      .catch((error) => {
        // logAxiosError(error)
        setCreateQuestionLoading(false);
        setQuestionData(null);
        alert("Error: While creating question. Please try again!");
        // setSaveBtnLoading(false)
      });
  };

  useEffect(() => {
    props.setData({ ...props.data, fileBulk: null });
  }, []);

  const handleAddCustomFromLibrary = (i, index) => {
    setLoadingIndex(index);
    setFakeLoading(true);
    if (props?.data?.customQuestion === null) {
      if (
        questionSetName == "" ||
        questionSetName == null ||
        questionSetName == undefined
      ) {
        toast.error("Questions set name can not be empty.");
      } else if (
        questionSetTime == "" ||
        questionSetTime == null ||
        questionSetTime == undefined
      ) {
        toast.error("Questions set time can not be empty.");
      } else {
        let data = {
          name: questionSetName,
          time: questionSetTime,
          assessmentId:
            parsed.assessment_id != null
              ? parseInt(parsed.assessment_id)
              : parseInt(assessment_id),
        };
        try {
          libraryMutate(data);
          setTimeout(() => {
            let Maindata = {
              data: {
                questions: Array.isArray(i?.id) ? i?.id : [i?.id],
                name: questionSetName,
                time: questionSetTime,
                assessmentId:
                  parsed.assessment_id != null
                    ? parseInt(parsed.assessment_id)
                    : parseInt(assessment_id),
              },
              section: {
                id: props?.data?.customQuestion,
              },
            };
            mutateLibrary(Maindata);
          }, 1000);
        } catch (err) {
          //
        }
      }
    } else {
      let Maindata = {
        data: {
          questions: Array.isArray(i?.id) ? i?.id : [i?.id],
          name: questionSetName,
          time: questionSetTime,
          assessmentId:
            parsed.assessment_id != null
              ? parseInt(parsed.assessment_id)
              : parseInt(assessment_id),
        },
        section: {
          id: props?.data?.customQuestion,
        },
      };
      mutateLibrary(Maindata);
      // setQuestionData(i);
    }
  };

  const { mutate: libraryMutate, isLoading: libraryLoad } = useMutation(
    createCustomQuestions,
    {
      onSuccess: (response) => {
        const { data } = response;
        handleModulesIDS(data?.id);
        queryClient.invalidateQueries("assessment");
        setTimeout(() => {
          setLoadingIndex(null);
          setFakeLoading(false);
        }, 1000);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    }
  );

  const { mutate: mutateLibrary, isLoading: mutateLoading } = useMutation(
    createCustomSet,
    {
      onSuccess: (response) => {
        const { data } = response;
        handleModulesIDS(data?.id);
        queryClient.invalidateQueries("assessment");
        setTimeout(() => {
          setLoadingIndex(null);
          setFakeLoading(false);
        }, 1000);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    }
  );

  const { mutate: sectionDelete, isLoading: sectionLoading } = useMutation(
    deleteSection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("section");
        setLastQuestionDelete(false);
        props.setData({ ...props.data, customQuestion: null });
        setQuestionSetName("");
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    }
  );
  //#endregion

  return (
    <div>
      <div className="sm:px-0 md:px-2">
        <div className="bg-white grid sm:grid-cols-1 lg:grid-cols-12 p-6">
          <PremiumGeneral
            premiumGeneralOpen={premiumGeneralOpen}
            setPremiumGeneral={setPremiumGeneral}
          />
          <QuestionsModal
            questionModal={questionModal}
            setQuestionModal={setQuestionModal}
            showQuestion={showQuestion}
            setQuestionShow={setShowQuestion}
            questionIndex={questionIndex}
            questions={questions}
            setQuestions={setQuestions}
            setData={props?.setData}
            data={props?.data}
            setQuestionIndex={setQuestionIndex}
          />
          {deleteModal && (
            <DeleteModal
              setDeleteModal={setDeleteModal}
              onClick={() => deleteQuestion(questionID, questionIndex)}
              header="Delete your question"
              icon={DeleteIcon}
              loading={deleteLoading}
              description=" Are you sure you want to delete your question? This action cannot be undone."
              buttonText="Delete question"
            />
          )}
          {assessmentLoading ? (
            <div class="loader-container-1 col-span-12">
              <div class="loader-1"></div>
            </div>
          ) : (
            <>
              <div className="lg:col-span-3 sm:col-span-1">
                <h2
                  className="text-coalColor mt-3"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  Add custom modules
                </h2>
                <p
                  className="mt-5 text-sm w-5/6"
                  style={{ fontFamily: "Silka Light" }}
                >
                  Add up to <b>20 questions</b> to your Custom module. Your
                  Custom module will be the last module your candidate takes. It
                  will be shown to the candidates after the Dexta default
                  modules you have selected in the Add Module section.
                </p>
              </div>
              <div className="lg:col-span-9 sm:col-span-1 sm:pl-0 lg:pl-4">
                <div className="flex flex-row">
                  <h1
                    className="font-medium mr-4 lg:mt-0 sm:mt-3"
                    style={{ fontFamily: "Silka" }}
                  >
                    Custom Module Name
                  </h1>
                  <div className="group relative flex justify-center lg:mt-0 sm:mt-3">
                    <img
                      src={info}
                      data-tooltip-target="tooltip-default"
                      className="w-5 h-5 cursor-pointer"
                    />
                    <span className="absolute scale-0 right-0 bottom-10 rounded bg-coalColor left-50 p-2 text-xs w-1008 text-white group-hover:scale-100">
                      The name you select for your Custom Module will be visible
                      to the candidate
                    </span>
                  </div>
                </div>
                <div className="relative w-full border border-1 mt-2 rounded-lg border-[#D3D5D8] focus:border-coalColor focus-within:border-coalColor">
                  <input
                    type="text"
                    id="customQuestion"
                    autoComplete="false"
                    maxLength="48"
                    className="w-full p-3 sm:text-sm xl:text-base 2x:text-lg rounded-lg outline-none bg-[#F6F7F7]"
                    placeholder="Add name here"
                    value={questionSetName}
                    onChange={(e) =>
                      user_package_check !== "Enterprise"
                        ? setPremiumGeneral(true)
                        : setQuestionSetName(e.target.value)
                    }
                  />
                </div>
                <div className="flex flex-row mt-5">
                  <h1
                    className="font-medium mr-4"
                    style={{ fontFamily: "Silka" }}
                  >
                    Custom Module Time
                  </h1>
                  <div className="group relative flex justify-center">
                    <img
                      src={info}
                      data-tooltip-target="tooltip-default"
                      className="w-5 h-5 cursor-pointer"
                    />
                    <span className="absolute scale-0 right-0 bottom-10 rounded bg-coalColor left-50 p-2 text-xs w-1008 text-white group-hover:scale-100">
                      The time you enter here will be used for the countdown
                      timer whilst the candidate is taking this custom Module
                    </span>
                  </div>
                </div>
                <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-10">
                  <div>
                    <div className="flex flex-col gap-3 relative">
                      <button
                        id="time"
                        className="TimeClass bg-[#F6F7F7] border border-1 border-[#D3D5D8] focus:border-coalColor focus-within:border-coalColor
                                     sm:py-3 md:py-0 h-1011 mt-1.5  focus:outline-[#4A9CB9] rounded-lg text-left inline-flex items-center px-3"
                        type="button"
                        onClick={() =>
                          user_package_check !== "Enterprise"
                            ? setPremiumGeneral(true)
                            : setTimeDropdown(!timeDropdown)
                        }
                      >
                        {!isNaN(questionSetTime) ? (
                          <span className="sm:text-sm xl:text-base 2x:text-lg ">
                            {questionSetTime} mins
                          </span>
                        ) : (
                          <span className="sm:text-sm xl:text-base 2x:text-lg  text-[#999] font-normal">
                            Set the max time allowed to complete the custom
                            module
                          </span>
                        )}
                      </button>
                      {timeDropdown && (
                        <div
                          id="education-drop"
                          className=" absolute TimeClass z-20 border border-coalColor assessmentClass right-0 top-full h-[12rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                        >
                          {TimeData.map((i) => (
                            <ul
                              key={i.value}
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              style={{ fontFamily: "Silka" }}
                              ref={TimeRef}
                              onClick={() => {
                                setQuestionSetTime(i?.value);
                                setTimeDropdown(false);
                                setTimeTitle(i?.title);
                              }}
                            >
                              <li>
                                <p className="block px-5 py-2 text-sm font-medium">
                                  {i.title}
                                </p>
                              </li>
                            </ul>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="relative ">
                      {user_package_check === "Enterprise" ? (
                        <>
                          <div className="flex relative mt-[4px]">
                            <CustomButton
                              label="Add Custom Question"
                              bgColor="#252E3A"
                              borderCustom="border border-black text-white"
                              hoverBgColor="#C0FF06"
                              hoverTextColor="#252E3A"
                              disableField={
                                questions?.length === 20 ||
                                questionSetName == "" ||
                                questionSetName == null ||
                                questionSetName == undefined ||
                                questionSetTime == "" ||
                                questionSetTime == null ||
                                questionSetTime == undefined
                              }
                              disabledCheck={
                                questions?.length === 20 ||
                                questionSetName == "" ||
                                questionSetName == null ||
                                questionSetName == undefined ||
                                questionSetTime == "" ||
                                questionSetTime == null ||
                                questionSetTime == undefined
                              }
                              disabledColor="#D3D5D8"
                              disabledTextColor="#7C8289"
                              paddingY="0.7rem"
                              onClickButton={handleAddCustom}
                            />
                            {(questionSetName == "" ||
                              questionSetName == null ||
                              questionSetName == undefined ||
                              questionSetTime == "" ||
                              questionSetTime == null ||
                              questionSetTime == undefined) && (
                              <div className="tooltip2 w-[20rem]   text-center">
                                You cannot proceed with empty Module name and
                                Module time fields
                              </div>
                            )}
                            {questions?.length === 20 && (
                              <div className="tooltip2 w-[20rem] text-center">
                                You cannot add more than 20 questions
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="flex relative mt-[4px]">
                          <button
                            className={`inline-flex items-center py-[0.8rem] px-4 w-full hover:text-white text-white text-base font-medium rounded-md bg-coalColor hover:bg-coalColor/90`}
                            onClick={() => {
                              setQuestions([]);
                              setPremiumGeneral(true);
                            }}
                            style={{ fontFamily: "Silka" }}
                          >
                            <FaPlus className="mr-2" />
                            Add new question
                            <span className="absolute top-0 right-0 -mt-2 -mr-2">
                              <img
                                src={King}
                                alt="Premium Icon"
                                className="w-6 h-6"
                              />
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-12 sm:grid-cols-1">
                <hr className="w-full mt-10 bg-[#D3D5D8] border-1" />
                <div
                  className="relative overflow-x-auto bg-white sm:rounded-lg mt-10"
                  id="assessment2"
                >
                  <table className="w-full sm:text-xs md:text-sm sm:text-left md:text-left text-white ">
                    <thead
                      className="sm:text-xs md:text-lg text-coalColor bg-white"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      <tr>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[10%] sm:py-2"
                        >
                          ID#
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[40%] sm:py-2"
                        >
                          Question
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[20%] sm:py-2 "
                        >
                          Correct options
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[10%] sm:py-2"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[10%] sm:py-2"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="sm:px-3 md:px-6 w-[10%] sm:py-2"
                        >
                          Image
                        </th>
                      </tr>
                    </thead>
                    <tbody className="rounded-lg">
                      {assessmentLoading ? (
                        <tr>
                          <td colSpan="7" className="text-center mt-10">
                            <div className="bg-white">
                              <div class="loader-container-3">
                                <div class="loader-3"></div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <>
                          {questions &&
                            questions?.map((i, index) => {
                              const correctOptions = [];
                              i?.options?.map((option) => {
                                if (option?.isCorrectOption) {
                                  return correctOptions.push(option);
                                }
                              });
                              return (
                                <tr
                                  key={index}
                                  className={`bg-white odd:bg-[#F6F7F7] text-black cursor-pointer`}
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <td className="md:px-6 sm:px-2 sm:py-3">
                                    {i?.id}
                                  </td>
                                  <td
                                    className="md:px-6 my-auto sm:px-2 sm:py-3"
                                    style={{
                                      maxWidth: "170px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <div
                                      className="my-auto align-center flex"
                                      dangerouslySetInnerHTML={{
                                        __html: removeInlineStyles(
                                          i?.description
                                        ),
                                      }}
                                    />
                                  </td>

                                  <td className="md:px-6 sm:px-2 text-center sm:py-3">
                                    {correctOptions[0]?.title}
                                  </td>
                                  <td className="md:px-6 sm:px-2 sm:py-3">
                                    {i?.type}
                                  </td>
                                  <td className="md:px-6 sm:px-2 lg:text-left sm:text-center sm:py-3">
                                    {i?.status}
                                  </td>
                                  <td className="md:px-6 sm:px-2 sm:py-3 lg:text-left sm:text-center">
                                    {i?.image ? "YES" : "No"}
                                  </td>
                                  <td className="md:px-6 sm:px-2 sm:py-3 w-[6rem] gap-3 align-center clickable flex flex-row">
                                    {i?.is_custom_question ? (
                                      <div className="relative group">
                                        <img
                                          src={eye}
                                          className="w-6 h-3 my-auto flex"
                                        />
                                        <div className="tooltipxD right-0 group-hover:block hidden sm:w-[200px] md:w-[200px] text-center absolute top-full opacity-0 pointer-events-none text-xs">
                                          Questions from Dexta Library are Not
                                          Accessible
                                        </div>
                                      </div>
                                    ) : (
                                      <img
                                        src={eye}
                                        className="w-6 h-3 my-auto flex"
                                        onClick={() => {
                                          setQuestionModal(true);
                                          setShowQuestion(true);
                                          setQuestionIndex(index);
                                        }}
                                      />
                                    )}
                                    <img
                                      src={bin}
                                      className="w-3 h-4 my-auto flex"
                                      onClick={() => {
                                        setQuestionIndex(index);
                                        setQuestionID(i?.id);
                                        setLastQuestionDelete(
                                          questions?.length === 1 ? true : false
                                        );
                                        setDeleteModal(true);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </table>
                  {!assessmentLoading && questions && questions?.length < 1 && (
                    <div className="mt-3 mb-5">
                      <div className="border border-[#FF5812] py-4 rounded">
                        <p
                          className="text-alertRed text-center"
                          style={{ fontFamily: "Silka Bold" }}
                        >
                          No questions added yet
                        </p>
                      </div>
                    </div>
                  )}
                  {/* <div className='mt-8 ml-2'>
                                <TablePagination
                                    totalCount={dataR?.meta?.pageCount === 0 ? 1 : dataR?.meta?.pageCount}
                                    onPageChange={handlePageChange}
                                />
                            </div> */}
                </div>
                <hr className="w-full mt-10 bg-[#D3D5D8] border-1" />
                <div className="flex sm:flex-col md:flex-row gap-5 mt-7">
                  <h1
                    className="text-lg my-auto"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Use custom tests from
                  </h1>
                  <div className="flex md:flex-row sm:flex-col">
                    <div className="flex relative">
                      <h2
                        className={`${
                          !adminLibrary
                            ? "bg-coalColor text-primaryGreen rounded-md"
                            : "text-[#7C8289]"
                        } p-3 cursor-pointer w-full`}
                        onClick={() => {
                          if (user_package_check !== "Enterprise") {
                            setPremiumGeneral(true);
                          } else if (adminLibrary !== false) {
                            setAdminLibrary(false);
                          }
                        }}
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        Your library
                      </h2>
                      {user_package_check !== "Enterprise" && (
                        <span className="absolute top-0 right-0 -mt-2 -mr-2">
                          <img
                            src={King}
                            alt="Premium Icon"
                            className="w-6 h-6"
                          />
                        </span>
                      )}
                    </div>
                    <h2
                      className={`${
                        adminLibrary
                          ? "bg-coalColor text-primaryGreen rounded-md"
                          : "text-[#7C8289]"
                      } p-3 cursor-pointer`}
                      style={{ fontFamily: "Archia Semibold" }}
                      onClick={() => {
                        if (user_package_check !== "Enterprise") {
                          setPremiumGeneral(true);
                        } else if (adminLibrary !== true) {
                          setAdminLibrary(true);
                        }
                      }}
                    >
                      Dexta library
                    </h2>
                  </div>
                </div>
                <div className="grid xl:grid-cols-3 sm:grid-cols-1 2xl:grid-cols-3 gap-6 sm:px-6 md:px-0">
                  {libraryData?.data.map((i, index) => {
                    const isAdded = questions?.some(
                      (item) => item?.description === i?.description
                    );
                    const isDisabled = !questionSetName || !questionSetTime;

                    const disabledQuestions = questions?.length >= 20;
                    console.log(
                      questions?.length,
                      disabledQuestions,
                      "length of questions"
                    );
                    // Skip custom questions when not in admin library mode
                    if (!adminLibrary && i?.is_custom_question) {
                      return null;
                    }

                    return (
                      <div
                        className="bg-[#F6F7F7] p-5 mt-5 border border-[#D3D5D8] rounded-lg"
                        key={index}
                      >
                        <div className="h-[70px] pr-3 text-sm 2xl:mt-3 sm:mt-3">
                          <div
                            style={{
                              fontFamily: "Silka",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            <div
                              className="my-auto align-center flex"
                              style={{
                                fontFamily: "Silka",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: removeInlineStyles(i?.description),
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex justify-between">
                          <div className="flex flex-col gap-3 my-auto">
                            <div className="flex flex-row gap-2">
                              <VscLibrary className="w-5 h-5 my-auto" />
                              <p>
                                {adminLibrary
                                  ? "Dexta Library"
                                  : "Your Library"}
                              </p>
                            </div>
                          </div>

                          <div className="my-auto w-[100px]">
                            {isAdded ? (
                              <div className="relative">
                                <CustomButton
                                  label="Added"
                                  paddingY={
                                    createQuestionLoading &&
                                    loadingIndex === index
                                      ? "0.58rem"
                                      : "0.2rem"
                                  }
                                  bgColor="#C0FF06"
                                  textColor="#252E3A"
                                  textSize="text-base"
                                  borderCustom="border border-black"
                                />
                                <div className="tooltip w-[12rem] font-medium text-center">
                                  You have added this question already
                                </div>
                              </div>
                            ) : (
                              <div className="relative">
                                <CustomButton
                                  label="Add"
                                  paddingY={
                                    createQuestionLoading &&
                                    loadingIndex === index
                                      ? "0.58rem"
                                      : "0.2rem"
                                  }
                                  bgColor="#252E3A"
                                  borderCustom={`border border-coalColor text-white ${
                                    fakeLoading && "cursor-not-allowed"
                                  }`}
                                  hoverBgColor="#C0FF06"
                                  hoverTextColor="#252E3A"
                                  textSize="text-base"
                                  disabledColor="#D3D5D8"
                                  disabledTextColor="#7C8289"
                                  onClickButton={() => {
                                    if (!fakeLoading) {
                                      handleAddCustomFromLibrary(i, index);
                                    }
                                  }}
                                  LoadingBtn={loadingIndex === index}
                                  disableField={
                                    isDisabled ||
                                    disabledQuestions ||
                                    (loadingIndex === index &&
                                      createQuestionLoading)
                                  }
                                  disabledCheck={
                                    isDisabled ||
                                    disabledQuestions ||
                                    (loadingIndex === index &&
                                      createQuestionLoading)
                                  }
                                  loadingText="Adding"
                                />
                                {disabledQuestions && (
                                  <div className="tooltip w-[15rem] font-medium text-center">
                                    You cannot add more than 20 questions
                                  </div>
                                )}
                                {isDisabled && (
                                  <div className="tooltip w-[15rem] font-medium text-center">
                                    Please fill out Test name and Test time
                                    fields first
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {libraryData?.data?.length === 0 && (
                  <div className="mt-3 mb-5">
                    <div className="border border-[#FF5812] py-4 rounded">
                      <p
                        className="text-alertRed text-center"
                        style={{ fontFamily: "Silka Bold" }}
                      >
                        No custom questions available
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

export default Questions;
