import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/Register";
import Step1 from "../Pages/Profile/MyAssessments/Steps/Step-1";
import Step2 from "../Pages/Profile/MyAssessments/Steps/step-2";
import Home from "../Pages/Profile/MyAssessments/Assessments-main";
import CreateAssessment from "../Pages/Profile/MyAssessments/Assessments-main/create-assesstment";
import InviteCandidates from "../Pages/Profile/MyAssessments/Assessments-main/Invite-Candidates";
import ConfirmEmail from "../Pages/Auth/confirm-email";
import Confirmation from "../Pages/Candidates/StudentsConfirmation";
import Information from "../Pages/Candidates/StudentsInformation";
import Setup from "../Pages/Candidates/Setup";
import Ready from "../Pages/Candidates/Ready";
import Test from "../Pages/Candidates/Test-screens";
import Reset from "../Pages/Auth/Reset";
import Completed from "../Pages/Candidates/completion";
import Feedback from "../Pages/Candidates/Feedback";
import Finished from "../Pages/Candidates/completed";
import Invalid from "../Pages/Candidates/invalid";
import AlreadyDone from "../Pages/Candidates/AlreadyDone";
import Details from "../Pages/Profile/MyAssessments/Assessments-main/details";
import Details_Assessment from "../Pages/Profile/MyAssessments/Assessments-main/details";
import PlaceOrder from "../Pages/Place_Order/PlaceOrder";
import Preview from "../Pages/Profile/MyAssessments/Assessments-main/preview";
import PreviewModule from "../Pages/Profile/MyAssessments/Assessments-main/previewModule";
import Settings from "../Pages/Profile/Settings";
import Candidates from "../Pages/Profile/MyCandidates";
import ModuleFeedback from "../Pages/Candidates/moduleFeedback.js/moduleFeedback";
import Preview_Ready from "../Pages/Candidates/Preview/preview-ready";
import PreviewTest from "../Pages/Candidates/Preview/preview-questions";
import Signup from "../Pages/Candidates/Auth/Signup";
import Signin from "../Pages/Candidates/Auth/Signin";
import Dashboard from "../Pages/Candidates/Candidate-Profile/Dashboard";
import Profile from "../Pages/Candidates/Candidate-Profile/Profile";
import Results from "../Pages/Candidates/Candidate-Profile/Results";
import Tests from "../Pages/Profile/Tests/Tests";
import PreviewExam from "../Pages/Profile/Settings/Preview";
import PreviewExamSecondary from "../Pages/Profile/Settings/Preview_secondary";
import Preview_admin from "../Pages/Admin/Preview_admin";
import WelcomeScreen from "../Pages/Profile/Settings/Previews/welcome";
import WebcamScreen from "../Pages/Profile/Settings/Previews/webcam";
import TimerScreen from "../Pages/Profile/Settings/Previews/timer";
import FeedbackScreen from "../Pages/Profile/Settings/Previews/feedback";
import TeamSignup from "../Pages/Team/TeamSignup";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import ForgetPasswordEmailSent from "../Pages/Auth/ForgetPasswordEmailSent";
import ResetComplete from "../Pages/Auth/ResetComplete";
import PlansPricing from "../Pages/PublicPages/PlansPricing";
import Graph1 from "../Pages/Profile/MyAssessments/Assessments-main/Graphs_for_small_screens/Graph1";
import CandidatePrivacyPolicy from "../Pages/Legal-Documents/Candidate-Privacy-Policy/PrivacyPolicy";
import CandidateTerms from "../Pages/Legal-Documents/Candidate-terms/CandidateTerms";
import CustomerTerms from "../Pages/Legal-Documents/Customer-Terms/CustomerTerms";
import ErrorPage from "../Pages/Candidates/ErrorPage";
import RequestDemo from "../Pages/PublicPages/RequestDemo";
import Graph2 from "../Pages/Profile/MyAssessments/Assessments-main/Graphs_for_small_screens/Graph2";
import ForgetPasswordCandidate from "../Pages/Candidates/Auth/Reset/ForgetPassword";
import EmailSent from "../Pages/Candidates/Auth/Reset/EmailSent";
import ResetPasswordCandidate from "../Pages/Candidates/Auth/Reset/ResetPassword";
import ResetSuccessful from "../Pages/Candidates/Auth/Reset/ResetSuccessful";
import DemoRequested from "../Pages/PublicPages/DemoRequested";
import AccessDenied from "../Pages/Candidates/AlreadyDone/accessdenied";

const privateRoutes = [
  {
    path: "/home",
    component: <Login />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "/step-1",
    component: <Step1 />,
  },
  {
    path: "/step-2",
    component: <Step2 />,
  },
  {
    path: "/dashboard",
    component: <Home />,
  },
  {
    path: "/create-test",
    component: <CreateAssessment />,
  },
  {
    path: "/invite-candidates/:id",
    component: <InviteCandidates />,
  },
  {
    path: "/details/:id",
    component: <Details_Assessment />,
  },
  {
    path: "/preview/:id",
    component: <Preview />,
  },
  {
    path: "/preview-module/:id",
    component: <PreviewModule />,
  },
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/candidates",
    component: <Candidates />,
  },
  {
    path: "/tests",
    component: <Tests />,
  },
  {
    path: "/score-distribution-graph",
    component: <Graph1 />,
  },
  {
    path: "/completion-time-graph",
    component: <Graph2 />,
  },

  { path: "/place_order", component: <PlaceOrder /> },
];

const privateWithoutNavbar = [
  {
    path: "/preview-test-1",
    component: <PreviewExam />,
  },
  {
    path: "/preview-test-2",
    component: <PreviewExamSecondary />,
  },
  {
    path: "/preview-confirmation-screen",
    component: <WelcomeScreen />,
  },
  {
    path: "/preview-setup-screen",
    component: <WebcamScreen />,
  },
  {
    path: "/preview-ready-screen",
    component: <TimerScreen />,
  },
  {
    path: "/preview-feedback-screen",
    component: <FeedbackScreen />,
  },
];

const nonAuthRoutes = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <Register />,
  },
  {
    path: "/forget-password",
    component: <ForgetPassword />,
  },
  {
    path: "/forget-password-email-sent",
    component: <ForgetPasswordEmailSent />,
  },
  {
    path: "/reset-password-complete",
    component: <ResetComplete />,
  },
  {
    path: "/packages",
    component: <PlansPricing />,
  },
  {
    path: "/term-of-use",
    component: <CustomerTerms />,
  },
  {
    path: "/request-demo",
    component: <RequestDemo />,
  },
  {
    path: "/demo-requested",
    component: <DemoRequested />,
  },
];

const publicRoutes = [
  {
    path: "/team-member-accept-invite/:id",
    component: <TeamSignup />,
  },
  {
    path: "/confirm_email/:token",
    component: <ConfirmEmail />,
  },
  {
    path: "/reset_password",
    component: <Reset />,
  },
];

const studentpublic = [
  {
    path: "/confirmation/:id",
    component: <Confirmation />,
  },
  {
    path: "/invalid",
    component: <Invalid />,
  },
  {
    path: "/expired",
    component: <AlreadyDone />,
  },
  {
    path: "/access-denied",
    component: <AccessDenied />,
  },
  {
    path: "/error",
    component: <ErrorPage />,
  },
  {
    path: "/candidate/forget-password-email-sent",
    component: <EmailSent />,
  },
  {
    path: "/candidate/forget-password",
    component: <ForgetPasswordCandidate />,
  },
  {
    path: "/candidate/reset-password",
    component: <ResetPasswordCandidate />,
  },
  {
    path: "/candidate/reset-password-complete",
    component: <ResetSuccessful />,
  },
  {
    path: "/candidate/login",
    component: <Signin />,
  },
  {
    path: "/candidate/create-account",
    component: <Signup />,
  },
  {
    path: "/candidate-recruiter/privacy-policy",
    component: <CandidatePrivacyPolicy />,
  },
  {
    path: "/candidate/candidate-terms",
    component: <CandidateTerms />,
  },
];

const studentRoutes = [
  {
    path: "/information",
    component: <Information />,
  },
  {
    path: "/setup",
    component: <Setup />,
  },
  {
    path: "/get-ready",
    component: <Ready />,
  },
  {
    path: "/practice-ready",
    component: <Preview_Ready />,
  },
  {
    path: "/practice-questions",
    component: <PreviewTest />,
  },
  {
    path: "/exam",
    component: <Test />,
  },
  {
    path: "/module-feedback",
    component: <ModuleFeedback />,
  },
  {
    path: "/completed",
    component: <Completed />,
  },
  {
    path: "/feedback",
    component: <Feedback />,
  },
  {
    path: "/finished",
    component: <Finished />,
  },
];

const candidateRoutes = [
  {
    path: "/candidate/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/candidate/profile",
    component: <Profile />,
  },

  {
    path: "/candidate/assessment/:id",
    component: <Results />,
  },
];

const adminPublic = [
  {
    path: "/preview/question/:qid",
    component: <Preview_admin />,
  },
];

export {
  privateRoutes,
  publicRoutes,
  studentRoutes,
  studentpublic,
  candidateRoutes,
  privateWithoutNavbar,
  adminPublic,
  nonAuthRoutes,
};
