import React, { useEffect, useState } from "react";
// import Clock from "../../../Assets/wallclock.png"
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSections } from "../hooks/getSections";
import "../../../Components/Loading/Loading4.css";
import "./ready.css";
import { FiClock } from "react-icons/fi";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { FaCheck, FaCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setQuestionsTotal } from "../../../redux/reducers/QuestionsTotal/QuestionsSlice";

const Ready = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(10);
  const assessmentID = localStorage.getItem("CANDIDATE-ASSESSMENT-ID");
  const localData = JSON?.parse(localStorage.getItem("Current Module"));
  const moduleData = JSON?.parse(localStorage.getItem("module"));
  const timecheck = JSON?.parse(localStorage.getItem("time"));
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const completion_check = localStorage.getItem("test_completed");
  const user_exists = localStorage.getItem("user_exists");
  //#region Resetting module time
  useEffect(() => {
    timecheck.timeLeft = 0;
    timecheck.seconds = 0;
    timecheck.time_consumed = 0;
    localStorage.setItem("time", JSON.stringify(timecheck));
  }, []);
  //#endregion

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  //#region Timer for test
  useEffect(() => {
    let interval;
    if (isIntervalRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time === 0) {
      const currentSection = JSON?.parse(
        localStorage.getItem("Current Module")
      );
      // currentSection.CurrentModule = currentSection.CurrentModule + 1;
      localStorage.setItem("Current Module", JSON.stringify(currentSection));
      currentSection.pageQuestion = 1;
      localStorage.setItem("Current Module", JSON.stringify(currentSection));
      console.log(currentSection.CurrentModule);
      localStorage.setItem("exam", "start");
      dispatch(setQuestionsTotal(null));
      navigate("/exam");

      // setTimeout(() => {
      // }, 5000);

      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, navigate, isIntervalRunning]);

  const toggleInterval = () => {
    setIsIntervalRunning((prevIsIntervalRunning) => !prevIsIntervalRunning);
    if (!isIntervalRunning) {
      setTime(0);
    }
  };

  //#endregion

  //#region Fetching section details
  const { data, error, isLoading } = useQuery(
    ["/section/byAssessmentId", assessmentID],
    () => getSections(assessmentID)
  );

  //#endregion

  const [isHovered, setIsHovered] = useState(false);

  //#region handle back browser
  useEffect(() => {
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.href);
    };

    // Push the current state to the history stack initially
    window.history.pushState(null, null, window.location.href);

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Push the state whenever the user attempts to navigate back
    handlePopState();

    return () => {
      // Cleanup the event listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  //#endregion

  const buttonStyle = {
    background: isHovered ? `rgba(${theme.color}, 1.9)` : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  var NewColor = LightenDarkenColor(theme.color, 140);

  function DarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) - amt;

    if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) - amt;

    if (b < 0) b = 0;

    var g = (num & 0x0000ff) - amt;

    if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  var DarkenedColor = DarkenColor(theme.color, 50);

  //#region check if user completed feedback
  useEffect(() => {
    if (completion_check === "yes") {
      navigate("/feedback");
    } else if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, [completion_check, user_exists]);
  //#endregion

  document.title = "Get Ready | Dexta";

  return (
    <>
      <nav
        className={`xl:fixed  2xl:fixed  w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center xl:max-xxl:mt-10 h-screen sm:max-md:px-4">
            <div className="m-auto sm:max-md:w-full w-2/3 2xl:w-1/2 rounded-md bg-white relative overflow-hidden">
              <div className="rounded-lg">
                {isLoading ? (
                  <div class="loader-container-3 col-span-6">
                    <div class="loader-3"></div>
                  </div>
                ) : (
                  <div className="lg:p-8 lg:px-12 sm:max-md:p-6">
                    <p className="text-[#767676] gap-1 flex flex-row">
                      {Array.from(
                        { length: moduleData.current },
                        (_, index) => (
                          <FaCheck
                            key={index}
                            className="text-green-500 w-4 h-4"
                          />
                        )
                      )}

                      {/* Display circles for remaining tests */}
                      {Array.from(
                        { length: moduleData.last - moduleData.current },
                        (_, index) => (
                          <FaCircle key={index} className="text-gray-400" />
                        )
                      )}

                      {/* Display the current test number */}
                    </p>{" "}
                    <b
                      className="text-coalColor font-bold mt-1"
                      style={{ fontFamily: "Silka" }}
                    >
                      Module Number:
                    </b>{" "}
                    <b className="text-[#767676] font-bold mt-1">
                      {moduleData.current}
                    </b>{" "}
                    out of{" "}
                    <b className="text-[#767676] font-bold mt-1">
                      {moduleData.last}
                    </b>
                    {data?.data?.map((i, ind) => (
                      <React.Fragment key={ind}>
                        {i?.section.map((j, index) => {
                          //saving modules-length on local-storage
                          moduleData.last = i?.section?.length;
                          localStorage.setItem(
                            "module",
                            JSON.stringify(moduleData)
                          );

                          if (index === parseInt(localData.CurrentModule)) {
                            //saving current-module-index on local-storage
                            moduleData.current = index + 1;
                            localStorage.setItem(
                              "module",
                              JSON.stringify(moduleData)
                            );

                            const ModuleID = JSON?.parse(
                              localStorage.getItem("Current Module")
                            );
                            ModuleID.ModuleID = j.id;
                            localStorage.setItem(
                              "Current Module",
                              JSON.stringify(ModuleID)
                            );

                            const ModuleName = JSON?.parse(
                              localStorage.getItem("Current Module")
                            );
                            ModuleName.module_Name = j.name;
                            localStorage.setItem(
                              "Current Module",
                              JSON.stringify(ModuleName)
                            );

                            const timeMod = JSON?.parse(
                              localStorage.getItem("Current Module")
                            );
                            timeMod.time = j.time;
                            localStorage.setItem(
                              "Current Module",
                              JSON.stringify(timeMod)
                            );

                            const minsec = JSON?.parse(
                              localStorage.getItem("minsec")
                            );
                            minsec.secleft = j.time * 60;
                            minsec.secBar = j.time * 60;
                            localStorage.setItem(
                              "minsec",
                              JSON.stringify(minsec)
                            );
                          }
                          return (
                            <React.Fragment key={index}>
                              {index === parseInt(localData.CurrentModule) && (
                                <h1
                                  className="text-base mt-7"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <span className="py-0.5 px-8 rounded-xl bg-[#252E3A1A]">
                                    {j?.name}
                                  </span>
                                </h1>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ))}
                    <p
                      className="text-[#767676] mt-1"
                      style={{ fontFamily: "Silka" }}
                    >
                      The actual module starts now.
                    </p>
                    <p
                      className="text-[#767676] font-bold mt-1"
                      style={{ fontFamily: "Silka" }}
                    >
                      Good Luck!
                    </p>
                    <Clock value={value} className="mx-auto mt-3 " />
                    <div>
                      {isIntervalRunning && (
                        <h1
                          className="text-lg font-medium text-center mt-5"
                          style={{ fontFamily: "Silka Light" }}
                        >
                          The module will start in{" "}
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            {time}{" "}
                          </span>
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            seconds
                          </span>
                        </h1>
                      )}
                    </div>
                    <p
                      className="text-coalColor mt-8"
                      style={{ fontFamily: "Silka" }}
                    >
                      Please stay on this screen. The timer for your next module
                      has started, and it cannot be paused.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ready;
