import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../Components/Loading/Loading.css";
import { getModuleByID } from "./hooks/getModuleByID";
import { getQuestions } from "./hooks/getQuestions";
import ReactHtmlParser from "react-html-parser";
import styles from "../../../Candidates/Test-screens/styling.module.css";
import NotSelected from "../../../../Assets/notS.png";
import Scrollbars from "react-custom-scrollbars";
import SampleIcon from "../../../../Dexta_assets/sample-questions.png";
import Clock from "../../../../Dexta_assets/clock.png";
import Lock from "../../../../Dexta_assets/sample_lock.png";
import eye from "../../../../Dexta_assets/magnifier.png";
import closeIcon from "../../../../Dexta_assets/closeModal.png";

const PreviewModule = () => {
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = useState([]);

  //#region Calling useQueries to fetch Data
  const {
    data: modulesData,
    error: modulesError,
    isLoading: loadingError,
  } = useQuery(["section", id], () => getModuleByID(id));
  const {
    data: questionData,
    error: questionError,
    isLoading: questionLoading,
  } = useQuery(["questions", id], () => getQuestions(id));
  //#endregion

  //#region Handling Radios and checkboxes
  const handleRadios = (questionId, optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = [...prevSelectedOptions];
      const index = updatedOptions.findIndex(
        (item) => item.questionId === questionId
      );

      if (index !== -1) {
        // Update existing question's selected option
        updatedOptions[index] = { questionId, optionId };
      } else {
        // Add a new question's selected option
        updatedOptions.push({ questionId, optionId });
      }

      return updatedOptions;
    });
  };

  // Function to handle checkbox selection for Multiple type questions
  const handleCheckbox = (questionId, optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = [...prevSelectedOptions];
      const index = updatedOptions.findIndex(
        (item) => item.questionId === questionId && item.optionId === optionId
      );

      if (index !== -1) {
        // Remove the option if already selected
        updatedOptions.splice(index, 1);
      } else {
        // Add the option if not selected
        updatedOptions.push({ questionId, optionId });
      }

      return updatedOptions;
    });
  };
  //#endregion

  document.title = "Preview Questions | Dexta";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <>
        <div className="bg-bodyColor">
          <div className="bg-bodyColor pt-10 lg:container mx-auto lg:px-0 sm:px-4">
            <div className="pt-[7rem] sm:px-0 md:px-2 rounded-lg enable-scrollbar2">
              <div className="flex flex-row gap-2 md:gap-5 mt-4">
                <div className="flex flex-row gap-3">
                  <img src={SampleIcon} className="w-6 h-6 my-auto" />
                  <h1
                    className="sm:text-base md:text-2xl my-auto font-bold select-text text-coalColor"
                    id="step3"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Sample questions
                  </h1>
                </div>
                <div
                  className={`border ${
                    modulesData?.experience === "Basic" && "border-[#0B5B23]"
                  } ${
                    modulesData?.experience === "Intermediate" &&
                    "border-[#FFB500]"
                  } ${
                    modulesData?.experience === "Advanced" && "border-[#FF5812]"
                  }  px-2 rounded-2xl text-xs my-auto py-1 text-coalColor`}
                  style={{ fontFamily: "Silka Light" }}
                >
                  {modulesData?.experience}
                </div>
                <div className="flex flex-row gap-2">
                  <img
                    src={Clock}
                    className="md:w-5 md:h-5 sm:h-4 sm:w-4 my-auto"
                  />
                  <p
                    className="sm:text-xs md:text-[14px] text-coalColor my-auto"
                    style={{ fontFamily: "Silka" }}
                  >
                    {modulesData?.time} mins
                  </p>
                </div>
              </div>
              {isModalOpen && (
                <div
                  className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                  onClick={() => setIsModalOpen(false)}
                >
                  <div className="relative bg-white p-5 rounded-md">
                    <img
                      src={closeIcon}
                      className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                      onClick={() => setIsModalOpen(false)}
                    />
                    <img
                      src={selectedImageUrl}
                      alt="Selected"
                      className="p-5 object-contain"
                      style={{
                        height: "450px",
                        width: "850px",
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="bg-white p-3 rounded-lg mt-5">
                <>
                  {questionLoading || loadingError ? (
                    <div class="loader-container-1">
                      <div class="loader-1"></div>
                    </div>
                  ) : (
                    <>
                      <Scrollbars
                        autoHeight
                        autoHide
                        autoHeightMax="660px"
                        style={{ width: "100%", height: "100%" }}
                      >
                        {questionData?.data?.length === 0 && (
                          <div className="border border-[#FF5812] py-4 mt-10 rounded">
                            <p
                              className="text-[#252E3A] text-center"
                              style={{ fontFamily: "Silka" }}
                            >
                              {" "}
                              No sample questions available for this test
                            </p>
                          </div>
                        )}
                        {questionData?.data?.slice(0, 4).map((i, index) => (
                          <div
                            className={`md:mx-5 py-3 md:py-0 border-b border-[#D3D5D8] ${
                              index === 3 && "backdrop-blur-sm border-none"
                            }`}
                            key={index}
                          >
                            <div className="grid md:grid-cols-2 md:p-5">
                              <div className="pt-5 md:pl-5">
                                <h1
                                  className={`text-black text-xl font-bold ${
                                    index === 3 && "blur-lg"
                                  }`}
                                  style={{ fontFamily: "Archia Semibold" }}
                                >
                                  Question
                                </h1>
                                <div
                                  className={` ${
                                    index === 3 && "blur-lg"
                                  } mt-5 w-full pr-[18px]`}
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <div className={styles["html-content"]}>
                                    {ReactHtmlParser(i.description)}
                                  </div>
                                </div>
                                {i?.image != null &&
                                  i?.image != " " &&
                                  i?.image != "" && (
                                    <div className="px-4">
                                      <img
                                        src={i?.image}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleImageClick(i?.image);
                                        }}
                                        className={`rounded-lg object-contain mt-5 cursor-pointer  ${
                                          index === 3 && "blur-lg"
                                        }`}
                                        style={{
                                          height: "400px",
                                          width: "800px",
                                        }}
                                      />
                                    </div>
                                  )}
                                {i?.figImage != null &&
                                  i?.figImage != " " &&
                                  i?.figImage != "" && (
                                    <div className="">
                                      <img
                                        src={i?.figImage}
                                        className="rounded-lg object-contain cursor-pointer mt-5"
                                        style={{
                                          height: "400px",
                                          width: "800px",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleImageClick(i?.figImage);
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                              {(() => {
                                let options_check;
                                if (
                                  i?.options?.every(
                                    (option) => option.imageUrl === null
                                  )
                                ) {
                                  options_check = "null";
                                } else if (
                                  i?.options?.every(
                                    (option) =>
                                      option.imageUrl !== null &&
                                      option?.title !== null
                                  )
                                ) {
                                  options_check = "all";
                                } else if (
                                  i?.options?.every(
                                    (option) =>
                                      option.imageUrl !== null &&
                                      option?.title === null
                                  )
                                ) {
                                  options_check = "all images";
                                } else {
                                  options_check = "some_image";
                                }

                                return i?.type === "Single" ? (
                                  <div
                                    className={`pt-5 pl-2 md:pr-0 sm:pr-2 ${
                                      index === 3 && "blur-lg"
                                    }`}
                                  >
                                    <div
                                      className="md:px-10 text-coalColor text-xl font-bold rounded-lg"
                                      style={{ fontFamily: "Archia Semibold" }}
                                    >
                                      Select ONE answer
                                      {i?.options
                                        ?.sort((a, b) =>
                                          a?.optionPosition > b?.optionPosition
                                            ? 1
                                            : -1
                                        )
                                        // Group options into pairs for two per row
                                        .reduce((acc, curr, index, array) => {
                                          if (index % 2 === 0)
                                            acc.push(
                                              array.slice(index, index + 2)
                                            );
                                          return acc;
                                        }, [])
                                        .map((pair, rowIndex) => (
                                          <div
                                            key={rowIndex}
                                            className={`grid ${
                                              options_check === "all images" &&
                                              "grid-cols-2"
                                            }  gap-4 mt-5`}
                                          >
                                            {pair.map((j) => (
                                              <>
                                                {options_check ===
                                                "all images" ? (
                                                  <div
                                                    key={j.id}
                                                    onClick={() =>
                                                      handleRadios(i.id, j.id)
                                                    }
                                                    className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                                  >
                                                    <div className="relative flex items-center justify-center">
                                                      <div className="relative group">
                                                        <img
                                                          src={j?.imageUrl}
                                                          className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                          style={{
                                                            borderColor:
                                                              selectedOptions.some(
                                                                (item) =>
                                                                  item.questionId ===
                                                                    i.id &&
                                                                  item.optionId ===
                                                                    j.id
                                                              )
                                                                ? "#C0FF06"
                                                                : "white",
                                                            borderStyle:
                                                              "solid",
                                                            borderWidth: "3px",
                                                          }}
                                                        />
                                                        <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                          <button
                                                            className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              handleImageClick(
                                                                j?.imageUrl
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              src={eye}
                                                              className="w-5 h-5 object-contain"
                                                            />
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    key={j.id}
                                                    onClick={() =>
                                                      handleRadios(i.id, j.id)
                                                    }
                                                    className={`p-3 2xl:p-5 mt-5 cursor-pointer ${
                                                      selectedOptions.some(
                                                        (item) =>
                                                          item.questionId ===
                                                            i.id &&
                                                          item.optionId === j.id
                                                      )
                                                        ? "bg-primaryGreen border-coalColor"
                                                        : "bg-[#F6F7F7] border-[#D3D5D8]"
                                                    } hover:animate-[jiggle_1s_ease-in-out_infinite] rounded-2xl border border-[#B6B6B6]`}
                                                  >
                                                    <div className="flex justify-between">
                                                      <div className="flex gap-10">
                                                        <div className="inline-flex items-center">
                                                          <label
                                                            className="relative flex cursor-pointer items-center rounded-full p-3"
                                                            htmlFor={`radio_${j.id}`}
                                                            data-ripple-dark="true"
                                                          >
                                                            <input
                                                              id={`radio_${j.id}`}
                                                              name={`type_${i.id}`}
                                                              type="radio"
                                                              className="peer relative 2xl:h-7 2xl:w-7 w-5 h-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-coalColor transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-coalColor checked:before:bg-black hover:before:opacity-10"
                                                              onChange={() =>
                                                                handleRadios(
                                                                  i.id,
                                                                  j.id
                                                                )
                                                              }
                                                              checked={selectedOptions.some(
                                                                (item) =>
                                                                  item.questionId ===
                                                                    i.id &&
                                                                  item.optionId ===
                                                                    j.id
                                                              )}
                                                            />
                                                            <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="2xl:h-5 2xl:w-5 w-3 h-3"
                                                                viewBox="0 0 16 16"
                                                                fill="currentColor"
                                                              >
                                                                <circle
                                                                  data-name="ellipse"
                                                                  cx="8"
                                                                  cy="8"
                                                                  r="8"
                                                                ></circle>
                                                              </svg>
                                                            </div>
                                                          </label>
                                                          <label
                                                            className={`mt-px cursor-pointer select-none text-sm font-medium`}
                                                            htmlFor={`radio_${j.id}`}
                                                            style={{
                                                              fontFamily:
                                                                "Silka",
                                                              color:
                                                                selectedOptions.some(
                                                                  (item) =>
                                                                    item.questionId ===
                                                                      i.id &&
                                                                    item.optionId ===
                                                                      j.id
                                                                )
                                                                  ? "black"
                                                                  : "#252e3a",
                                                            }}
                                                          >
                                                            {j.title}
                                                          </label>
                                                        </div>
                                                      </div>
                                                      {(options_check ===
                                                        "all" ||
                                                        options_check ===
                                                          "some_image") && (
                                                        <div className="my-auto">
                                                          {j?.imageUrl ? (
                                                            <div className="relative flex items-center justify-center">
                                                              <div className="relative group">
                                                                <img
                                                                  src={
                                                                    j?.imageUrl
                                                                  }
                                                                  className="my-auto w-20 h-20 object-cover"
                                                                />
                                                                <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                  <button
                                                                    className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      handleImageClick(
                                                                        j?.imageUrl
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={eye}
                                                                      className="w-3 h-3 object-contain"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div className="w-20 h-20"></div>
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            ))}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className={`pt-5 pl-2 md:pr-0 sm:pr-2 ${
                                      index === 3 && "blur-lg"
                                    }`}
                                  >
                                    <div
                                      className="md:px-10 text-black text-xl font-bold rounded-lg"
                                      style={{ fontFamily: "Archia Semibold" }}
                                    >
                                      Select ALL that apply
                                      {i?.options
                                        ?.sort((a, b) =>
                                          a?.optionPosition > b?.optionPosition
                                            ? 1
                                            : -1
                                        )
                                        .reduce((acc, curr, index, array) => {
                                          if (index % 2 === 0)
                                            acc.push(
                                              array.slice(index, index + 2)
                                            );
                                          return acc;
                                        }, [])
                                        .map((pair, rowIndex) => (
                                          <div
                                            key={rowIndex}
                                            className={`grid ${
                                              options_check === "all images" &&
                                              "grid-cols-2"
                                            }  gap-4 mt-5`}
                                          >
                                            {pair.map((j) => (
                                              <>
                                                {options_check ===
                                                "all images" ? (
                                                  <div
                                                    key={j.id}
                                                    onClick={() =>
                                                      handleCheckbox(i.id, j.id)
                                                    }
                                                    className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                                  >
                                                    <div className="relative flex items-center justify-center">
                                                      {/* Container for image and zoom button */}
                                                      <div className="relative group">
                                                        <img
                                                          src={j?.imageUrl}
                                                          className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                          style={{
                                                            borderColor:
                                                              selectedOptions.some(
                                                                (item) =>
                                                                  item.questionId ===
                                                                    i.id &&
                                                                  item.optionId ===
                                                                    j.id
                                                              )
                                                                ? "#C0FF06"
                                                                : "white",
                                                            borderStyle:
                                                              "solid",
                                                            borderWidth: "3px",
                                                          }}
                                                        />

                                                        <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                          <button
                                                            className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              handleImageClick(
                                                                j?.imageUrl
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              src={eye}
                                                              className="w-5 h-5 object-contain"
                                                            />
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className={`p-3 2xl:p-5 mt-3 rounded-2xl border border-[#B6B6B6] cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite] ${
                                                      selectedOptions.some(
                                                        (item) =>
                                                          item.questionId ===
                                                            i.id &&
                                                          item.optionId === j.id
                                                      )
                                                        ? "bg-primaryGreen border-coalColor"
                                                        : "bg-[#F6F7F7] border-[#D3D5D8]"
                                                    }`}
                                                    key={j.id}
                                                    onClick={() =>
                                                      handleCheckbox(i.id, j.id)
                                                    }
                                                  >
                                                    <div className="flex justify-between">
                                                      <div className="inline-flex items-center">
                                                        <label
                                                          className="relative flex cursor-pointer items-center rounded-full p-3"
                                                          htmlFor={`radio_${j.id}`}
                                                          data-ripple-dark="true"
                                                        >
                                                          <input
                                                            id="ripple-on"
                                                            type="checkbox"
                                                            className={`peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 border-gray-600  before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-10`}
                                                            onClick={() =>
                                                              handleCheckbox(
                                                                i.id,
                                                                j.id
                                                              )
                                                            }
                                                            checked={selectedOptions.some(
                                                              (item) =>
                                                                item.questionId ===
                                                                  i.id &&
                                                                item.optionId ===
                                                                  j.id
                                                            )}
                                                            onChange={() =>
                                                              handleCheckbox(
                                                                i.id,
                                                                j.id
                                                              )
                                                            }
                                                          />
                                                          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="h-3.5 w-3.5"
                                                              viewBox="0 0 20 20"
                                                              fill="currentColor"
                                                              stroke="currentColor"
                                                              strokeWidth="1"
                                                            >
                                                              <path
                                                                fillRule="evenodd"
                                                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                                clipRule="evenodd"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                        </label>
                                                        <label
                                                          className={`mt-px cursor-pointer select-none text-sm font-medium ${
                                                            selectedOptions.some(
                                                              (item) =>
                                                                item.questionId ===
                                                                  i.id &&
                                                                item.optionId ===
                                                                  j.id
                                                            )
                                                              ? "text-[#FFFFFF]"
                                                              : "text-coalColor"
                                                          }`}
                                                          htmlFor="html"
                                                          style={{
                                                            fontFamily: "Silka",
                                                            color:
                                                              selectedOptions.some(
                                                                (item) =>
                                                                  item.questionId ===
                                                                    i.id &&
                                                                  item.optionId ===
                                                                    j.id
                                                              )
                                                                ? "#black"
                                                                : "#252e3a",
                                                          }}
                                                        >
                                                          {j.title}
                                                        </label>
                                                      </div>
                                                      {(options_check ===
                                                        "all" ||
                                                        options_check ===
                                                          "some_image") && (
                                                        <div className="my-auto">
                                                          {j?.imageUrl ? (
                                                            <div className="relative flex items-center justify-center">
                                                              {/* Container for image and zoom button */}
                                                              <div className="relative group">
                                                                <img
                                                                  src={
                                                                    j?.imageUrl
                                                                  }
                                                                  className="my-auto w-20 h-20 object-cover"
                                                                />

                                                                <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                  <button
                                                                    className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                    onClick={(
                                                                      e
                                                                    ) => {
                                                                      e.stopPropagation();
                                                                      handleImageClick(
                                                                        j?.imageUrl
                                                                      );
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src={eye}
                                                                      className="w-3 h-3 object-contain"
                                                                    />
                                                                  </button>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div className="w-20 h-20"></div>
                                                          )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                              </>
                                            ))}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                );
                              })()}
                            </div>
                            {index === 3 && (
                              <div class="absolute inset-0 flex justify-center items-center z-20 backdrop-blur-lg px-2 md:px-0">
                                <div class="md:p-8 sm:p-4 bg-coalColor lg:h-1/5 xl:h-2/6 w-full rounded-lg flex flex-col justify-center items-center">
                                  <img src={Lock} class="w-8 h-9" />
                                  <p
                                    class="text-center text-white mt-2"
                                    style={{ fontFamily: "Silka Light" }}
                                  >
                                    We don’t show more than 3 questions per
                                    module for preview to protect the integrity
                                    of our modules
                                  </p>
                                  <p
                                    class="text-center text-white mt-2"
                                    style={{ fontFamily: "Silka Light" }}
                                  >
                                    All questions will be available to view for
                                    the candidates
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </Scrollbars>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default PreviewModule;
