import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, Zoom, toast } from "react-toastify";
import MainLogo from "../../Dexta_assets/req_demo.png";
import demo from "../../Dexta_assets/demo.png";

const DemoRequested = () => {
  const navigate = useNavigate();

  document.title = "Login | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid overflow-hidden bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col h-screen">
          <div className="sm:w-3/4 lg:w-2/3 mx-auto">
            <img src={demo} className="w-20 h-20 mx-auto" />
            <h1
              className="text-[4rem] text-center mt-4"
              style={{ fontFamily: "Archia Bold" }}
            >
              Thank you!
            </h1>
            <div
              className="flex flex-col mt-4 text-center mx-auto"
              style={{ fontFamily: "Silka" }}
            >
              <p>A member of the Dexta team will</p>
              <p>reach out to you shortly to set up a</p>
              <p>demo</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DemoRequested;
