import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Auth.css";
import { createUser } from "./hooks/createUser";
import { useNavigate, useLocation } from "react-router-dom";
import http from "../../http";
import queryString from "query-string";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import { useDispatch } from "react-redux";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";
import TextField from "../../Components/Dexta/TextField/TextField";
import CustomButton from "../../Components/CustomButton/CustomButton";
import MainLogo from "../../Dexta_assets/SignupLogov2.png";
import { FaArrowRightLong } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";

const Register = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [load, setLoad] = useState(true);
  const navigate = useNavigate();
  const [packageNSelected, setPackageNSelected] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [packageDetail, setPackageDetail] = useState({});
  const [notfound, setnotfound] = useState(false);
  const [pkgError, setpkgError] = useState(false);
  const [dispatchLoad, setDispatchLoad] = useState(false);
  const [packageLoad, setPackageLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(parsed?.interval);

  //#region password hide and unhide
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region disable / enable overflow
  useEffect(() => {
    if (parsed.package_id === undefined) {
      setPackageNSelected(true);
    } else if (parsed.package_id === "" || isNaN(parsed.package_id)) {
      setPackageNSelected(true);
    }
  }, []);

  useEffect(() => {
    if (packageNSelected) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [packageNSelected]);
  //#endregion

  //#region creating new user
  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-up");
      validation.values.firstName = "";
      validation.values.lastName = "";
      validation.values.email = "";
      validation.values.company = "";
      validation.values.password = "";
      if (response.accessToken) {
        localStorage.setItem("CP-USER-TOKEN", response.accessToken);
      }
      if (response.user.id) {
        localStorage.setItem("CP-USER-ID", response.user.id);
      }
      if (parsed && parsed.package_id) {
        if (localStorage.getItem("CP-USER-TOKEN")) {
          if (parsed?.package_id == 1) {
            getPackageUser(response.user.id);
          } else {
            localStorage.setItem("Registration", "initiated");
            navigate(
              `/place_order?package_id=${parsed?.package_id}&currency=${parsed?.currency}&interval=${selectedType}`
            );
          }
        }
      } else {
        getPackageUser(response.user.id);
      }
      // if (response.message === "Registration successful") {
      //     setError(true);
      //     setErrorMessage(`We have sent you verification email`)
      //     setVerify(true)
      // }
    },
    onError: (error) => {
      setError(true);
      setErrorMessage(error.response.data.message[0]);
      toast.success(error.response.data.message[0], {
        style: { width: "380px" },
      });
      // if (error.response.data.message[0].includes("password1"))
      //     setErrorMessage("Password must be longer than or equal to 8 characters")
      //     toast.success('Password must be longer than or equal to 8 characters')
    },
  });
  //#endregion

  //#region validations on register form
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: "",
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name required"),
      lastName: Yup.string().required("Last name required"),
      email: Yup.string().required("Email required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        firstName: values?.firstName,
        lastName: values?.lastName,
        companyName: values?.company,
        email: values?.email,
        password1: values?.password,
        password2: values?.password,
        // "referredBy": values?.refer
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region taking package to place order screen
  useEffect(() => {
    if (parsed && parsed.package_id) {
      localStorage.setItem("CP-PACKAGE-ID", String(parsed.package_id));
      if (localStorage.getItem("CP-USER-TOKEN")) {
        if (parsed.package_id == 1) {
          const ID = parseInt(localStorage.getItem("CP-USER-ID"));
          getPackageUser(ID);
          navigate("/dashboard");
        } else {
          localStorage.setItem("Registration", "initiated");
          navigate(
            `/place_order?package_id=${parsed?.package_id}&currency=${parsed?.currency}&interval=${selectedType}`
          );
        }
      }
    }
  }, []);
  //#endregion

  //#region Fetching user package
  const getPackageUser = async (pID) => {
    try {
      setDispatchLoad(true);
      const response = await http.get(
        `/subscriptions/subscriptionsByUserId/${pID}`
      );
      dispatch(setPackageData(response?.data?.package?.code));
      navigate("/dashboard");
      setDispatchLoad(false);
    } catch (err) {
      console.log(err.message);
      setDispatchLoad(false);
    }
  };
  //#endregion

  //#region Fetching package details
  const getPackageDetail = (package_id) => {
    setPackageLoading(true);
    http
      .request({
        method: "get",
        maxBodyLength: Infinity,
        url: `/packages/${package_id}?currency=${parsed?.currency}&interval=${selectedType}`,
      })
      .then((response) => {
        setPackageDetail(response.data);
        const exist = Object.keys(response.data)?.length;
        if (exist === 0) {
          setnotfound(true);
          setpkgError(true);
        }
        setPackageLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setPackageLoading(false);
      });
  };
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, [packageDetail, selectedType]);

  useEffect(() => {
    if (parsed && parsed.package_id) {
      getPackageDetail(Number(parsed.package_id));
    }
  }, [selectedType]);
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion
  document.title = "Register | Dexta";
  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img src={MainLogo} className="w-full lg:h-full  lg:object-cover" />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col md:pb-40">
          <div className="sm:w-5/6 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Sign up{" "}
            </h1>
            <div style={{ fontFamily: "Silka" }} className="text-sm mt-6">
              <span>
                Already a member?{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
              </span>
            </div>
            <div
              className={`px-5 py-5 mt-5 rounded border border-black flex justify-between ${
                packageDetail?.code === "free" && "bg-[#C0FF06]"
              }
                        ${packageDetail?.code === "pro" && "bg-[#FFB500]"}
                        ${
                          packageDetail?.code === "Enterprise" && "bg-[#FF5812]"
                        }                         
                        `}
            >
              <div
                className={`my-auto ${
                  packageDetail?.code === "Enterprise"
                    ? "text-white"
                    : "text-black"
                }`}
                style={{ fontFamily: "Archia Semibold" }}
              >
                {load ? (
                  <Skeleton width={200} height={20} />
                ) : (
                  <>
                    {packageDetail?.code === "free" && "Explore Dexta for free"}
                    {packageDetail?.code === "pro" && "Pro package"}
                    {packageDetail?.code === "Enterprise" &&
                      "Enterprise package"}
                  </>
                )}
              </div>
              {selectedType === "year" && "1 year commitment" && (
                <div className="border border-black text-xs my-auto py-1 rounded-xl bg-white px-3">
                  {load ? (
                    <Skeleton width={100} height={20} />
                  ) : (
                    selectedType === "year" && "1 year commitment"
                  )}
                </div>
              )}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mt-6">
                <div className="grid grid-cols-2 gap-3 h-[4.7rem]">
                  <div className="h-[4.7rem]">
                    <TextField
                      name="firstName"
                      id="firstName"
                      type="name"
                      placeholder="First Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                    />
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <div className="ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.firstName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="h-[4.7rem]">
                    <TextField
                      name="lastName"
                      id="lastName"
                      type="name"
                      placeholder="Last Name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <div className="ml-w-1/2 ml-1">
                        <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                          {validation.errors.lastName}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="email"
                  id="email"
                  type="email"
                  placeholder="Work email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.email || ""}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.email}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="company"
                  id="company"
                  type="name"
                  placeholder="Company Name"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  marginTop="mt-2"
                  value={validation.values.company || ""}
                />
              </div>
              <div className="h-[4.7rem]">
                <TextField
                  name="password"
                  id="password"
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  marginTop="mt-2"
                  onClick={togglePasswordVisibility}
                  passwordIcon={true}
                  isPasswordVisible={isPasswordVisible}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div className="ml-1">
                    <p className="text-rose-500 fade-in-text-validations sm:text-xs md:text-sm">
                      {validation.errors.password}
                    </p>
                  </div>
                ) : null}
              </div>
              <CustomButton
                label="Submit"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                marginTop="mt-4"
                bgColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                LoadingBtn={isLoading || dispatchLoad}
                loadingText="SUBMITTING"
              />
            </form>
            <p className="text-xs mt-5" style={{ fontFamily: "Silka" }}>
              By creating an account, you confirm that you have read and agree
              to Dexta's{" "}
              <b
                className="text-coalColor font-medium cursor-pointer"
                style={{ fontFamily: "Silka Bold" }}
                onClick={() => navigate("/term-of-use")}
              >
                Term of Use
              </b>{" "}
              and{" "}
              <b
                className="text-coalColor font-medium cursor-pointer"
                style={{ fontFamily: "Silka Bold" }}
                onClick={() => navigate("/candidate-recruiter/privacy-policy")}
              >
                Privacy Policy
              </b>
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
