import React, { useEffect, useState } from "react";
import Vector from "../../../Assets/Vector.png";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getQuestions } from "../hooks/getQuestions";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { postQuestion } from "../hooks/postQuestions";
import "../../../Components/Loading/Loading2.css";
import ReactHtmlParser from "react-html-parser";
import styles from "./styling.module.css";
import { updateStatus } from "../hooks/updateStatus";
import * as moment from "moment";
import ReactCursorPosition from "react-cursor-position";
import https from "../../../https";
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax
import { BsFillClockFill } from "react-icons/bs";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import "../../../Components/Loading/Loading4.css";
import { FaRunning } from "react-icons/fa";
import { updateQuestion } from "../hooks/updateQuestion";
import { useDispatch, useSelector } from "react-redux";
import { setExamtoTrue } from "../../../redux/reducers/ExamDone/ExamDoneSlice";
import { postPictureData } from "../hooks/postPictureData";
import { GoArrowRight } from "react-icons/go";
import closeImg from "../../../Dexta_assets/closeModal.png";
import { setQuestionsTotal } from "../../../redux/reducers/QuestionsTotal/QuestionsSlice";
import { setCurrentQuestion } from "../../../redux/reducers/CurrentQuestion/CurrentQuestionSlice";
import closeIcon from "../../../Dexta_assets/closeModal.png";
import eye from "../../../Dexta_assets/magnifier.png";
import { toast, ToastContainer, Zoom } from "react-toastify";

const Test = () => {
  let questionID = null;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentSection = JSON?.parse(localStorage.getItem("Current Module"));
  const timecheck = JSON?.parse(localStorage.getItem("time"));
  const [selectedOption, setSelectedOption] = useState([]);
  const [remaining, setRemaining] = useState(timecheck.seconds);
  const moduleData = JSON?.parse(localStorage.getItem("module"));
  const question = JSON?.parse(localStorage.getItem("questions"));
  const currentTimeUnix = moment().unix();
  const minsec = JSON?.parse(localStorage.getItem("minsec"));
  const [isFullscreen, setIsFullscreen] = useState(
    Boolean(document.fullscreenElement)
  );
  const [response, setResponse] = useState(null);
  const [accessDenied, setAccessDenied] = useState(false);
  const [camLogs, setCamLogs] = useState(true);
  const [isOutside, setIsOutside] = useState(false);
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const getQuestion = JSON.parse(localStorage.getItem("prevQuestion")) || [];
  const [questState, setQuestState] = useState(false);
  const [fetchingOptions, setFetchingOptions] = useState(false);
  const [res_id, setResID] = useState(0);
  const [skipModal, setSkipModal] = useState(false);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);
  const [screenshotInterval, setScreenshotInterval] = useState(null);
  const [backLoading, setBackLoading] = useState(false);
  const practiceSection = JSON?.parse(localStorage.getItem("practice_section"));
  const questionsTotal = useSelector((state) => state.questionsTotal.setTotal);
  const [pictureLink, setPictureLink] = useState(null);
  const currentQuestion = useSelector(
    (state) => state.currentQuestion.setCurrent
  );
  const [totalDuration, setTotalDuration] = useState(minsec.secleft);
  const completion_check = localStorage.getItem("test_completed");
  const user_exists = localStorage.getItem("user_exists");

  useEffect(() => {
    localStorage.setItem("exam", "start");
  }, []);

  useEffect(() => {
    if (data?.meta?.page) {
      dispatch(setCurrentQuestion(question?.current));
    }
  }, [question?.current]);

  useEffect(() => {
    if (!questionsTotal) {
      dispatch(setQuestionsTotal(question?.total));
    }
  }, [question?.total, questionsTotal]);
  //#region Fetching questions details
  const { data, error, isLoading } = useQuery(
    ["questions", currentSection],
    () => getQuestions(currentSection)
  );
  //#endregion

  //#region Handling back button
  const handleBackQuestion = () => {
    setBackLoading(true);
    localStorage.setItem("question_ID", 0);
    currentSection.pageQuestion = currentSection.pageQuestion - 1;
    localStorage.setItem("Current Module", JSON.stringify(currentSection));
    setTimeout(() => {
      setBackLoading(false);
    }, 1000);
  };
  //#endregion

  //#region fetching selected options
  useEffect(() => {
    const getSelectedQuestion = () => {
      if (!isLoading && data?.data[0]?.responseSubmitted?.id !== null) {
        setSelectedOption(data?.data[0]?.responseSubmitted?.selectedOptions);
        setResID(data?.data[0]?.responseSubmitted?.id);
        setQuestState(true);
      } else {
        setQuestState(false);
      }
    };
    getSelectedQuestion();
  }, [data?.data[0]?.responseSubmitted?.id, questState]);
  //#endregion

  //#region Handling Questions in localstorage
  const handleLocalStorage = (id) => {
    getQuestion.push(id); // Add the new questionID to the array
    localStorage.setItem("prevQuestion", JSON.stringify(getQuestion));
    localStorage.setItem("question_ID", 0);
  };
  //#endregion

  //#region handling workers

  const handleApiRequest = () => {
    console.log(!isOutside, "😒 Mouse outside???");
    let DataObj = {
      data: {
        usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
        fullscreen: isFullscreen,
        evaluation: currentSection.evaluation,
        assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
        sectionId: parseInt(currentSection.ModuleID),
        questionId: parseInt(localStorage.getItem("Question")),
        proctoringSnapshot: pictureLink,
        webcam: camLogs,
        logType:
          data?.meta?.page === 1 && counter === 0 && totalDuration !== 0
            ? "Started"
            : data?.meta?.hasNextPage === false || totalDuration === 0
            ? "Ended"
            : null,
        mouseInAssessmentWindow: !isOutside,
      },
      requestOptions: {
        authorization: `Bearer ${localStorage.getItem("CP-CANDIDATE-TOKEN")}`,
      },
    };
    const workerInstance = worker();
    workerInstance.postToApi({ data: DataObj });
    workerInstance.addEventListener("message", (e) => {
      const { type, result } = e.data;
      if (type === "POST_RESPONSE") {
        setResponse(result);
        workerInstance.terminate();
      }
    });
  };

  const UploadImageToApi = async (blob) => {
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("fileType", "user");
    try {
      const response = await https.post(
        `/evaluation_users_response_logs/assessment/${parseInt(
          localStorage.getItem("CANDIDATE-ASSESSMENT-ID")
        )}/upload-proctoring-snapshot`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPictureLink(response?.data?.link);
      // let data = JSON.stringify({
      //   usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      //   evaluation: currentSection.evaluation,
      //   assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      //   sectionId: parseInt(currentSection.ModuleID),
      //   proctoringSnapshot: response?.data?.link,
      //   questionId: parseInt(localStorage.getItem("Question")),
      // });
      // try {
      //   pictureMutate(data);
      // } catch (error) {
      //   //onError will automatically detect
      // }
    } catch (error) {
      console.error(error);
    }
  };

  const { mutate: pictureMutate, isLoading: pictureLoading } = useMutation(
    postPictureData,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/evaluation_users_response_logs");
      },
    }
  );

  useEffect(() => {
    if (!camLogs || !accessDenied || !isFullscreen) {
    }
    if (data?.meta?.page === 1 || data?.meta?.hasNextPage === false) {
      handleApiRequest();
    } else if (totalDuration === 0) {
      handleApiRequest();
    } else {
      handleApiRequest();
    }
  }, [
    isFullscreen,
    camLogs,
    accessDenied,
    isOutside,
    data?.meta,
    counter,
    totalDuration,
  ]);

  //#endregion

  //#region Handle scroll
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection.pageQuestion]);
  //#endregion

  //#region handle full screen

  //#endregion

  const enterFullscreen = () => {
    const elementToFullscreen = document.documentElement;
    if (elementToFullscreen.requestFullscreen) {
      elementToFullscreen.requestFullscreen();
    } else if (elementToFullscreen.mozRequestFullScreen) {
      elementToFullscreen.mozRequestFullScreen();
    } else if (elementToFullscreen.webkitRequestFullscreen) {
      elementToFullscreen.webkitRequestFullscreen();
    } else if (elementToFullscreen.msRequestFullscreen) {
      elementToFullscreen.msRequestFullscreen();
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(Boolean(document.fullscreenElement));
    };

    document.addEventListener("fullscreenchange", onFullscreenChange);
    document.addEventListener("webkitfullscreenchange", onFullscreenChange);
    document.addEventListener("mozfullscreenchange", onFullscreenChange);
    document.addEventListener("MSFullscreenChange", onFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullscreenChange
      );
      document.removeEventListener("mozfullscreenchange", onFullscreenChange);
      document.removeEventListener("MSFullscreenChange", onFullscreenChange);
    };
  }, []);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(display-mode: fullscreen)");

    const onMediaQueryChange = ({ matches }) => {
      setIsFullscreen(matches);
    };

    mediaQueryList.addListener(onMediaQueryChange);

    return () => {
      mediaQueryList.removeListener(onMediaQueryChange);
    };
  }, []);

  const handleFullscreenClick = () => {
    enterFullscreen();
  };
  //#region restting counter
  useEffect(() => {
    setCounter(0);
  }, [data?.meta?.hasNextPage !== false]);
  //#endregion

  //#region Post question
  const { mutate, isLoading: questionLoading } = useMutation(postQuestion, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/evaluationUsersResponse");
      localStorage.removeItem("exam");
      currentSection.pageQuestion = currentSection.pageQuestion + 1;
      localStorage.setItem("Current Module", JSON.stringify(currentSection));
      handleLocalStorage(response?.id);
      setSelectedOption([]);
      if (data?.meta?.hasNextPage === false) {
        handleFullscreenClick();
        navigate("/module-feedback");
      }
    },
    onError: (error) => {
      // const errorMessage = error?.response?.data?.message;
      // if (Array.isArray(errorMessage)) {
      //   setTimeout(() => {
      //     toast.error(errorMessage[0]);
      //   }, 500);
      // } else if (typeof errorMessage === "string") {
      //   setTimeout(() => {
      //     toast.error(errorMessage);
      //   }, 500);
      // } else {
      //   setTimeout(() => {
      //     toast.error("An error occurred.");
      //   }, 500);
      // }
    },
  });

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    updateQuestion,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/evaluationUsersResponse");
        localStorage.removeItem("exam");
        currentSection.pageQuestion = currentSection.pageQuestion + 1;
        localStorage.setItem("Current Module", JSON.stringify(currentSection));
        handleLocalStorage(response?.id);
        setSelectedOption([]);
        if (data?.meta?.hasNextPage === false) {
          handleFullscreenClick();
          navigate("/module-feedback");
        }
      },
    }
  );

  const { mutate: submitMutate, isLoading: mutatesubmitloading } = useMutation(
    postQuestion,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/evaluationUsersResponse");
        localStorage.removeItem("exam");
        dispatch(setExamtoTrue(true));
        navigate("/module-feedback");
      },
    }
  );

  //#endregion

  //#region Posting Question 1 by 1
  const handlePostQuestion = () => {
    console.log("Condition called", questState);
    // Common data structure for both cases
    let commonData = {
      usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      sectionId: parseInt(currentSection.ModuleID),
      questionId: questionID,
      evaluation: currentSection.evaluation,
      selectedOptions: selectedOption,
      status: "abc",
    };

    // Check if extra parameters need to be added
    if (data?.meta?.hasNextPage === false || totalDuration === 0) {
      commonData.timeTakenToAnswer = barWidth;
      commonData.isSectionLastQuestion = true;
    }

    // Prepare the data for the first API call
    let data_new = JSON.stringify(commonData);

    // Prepare the data for the second API call
    let data2 = {
      content: { ...commonData },
      id: res_id,
    };

    try {
      if (!questState) {
        mutate(data_new);
      } else {
        updateMutate(data2);
      }
    } catch (err) {
      console.error("Error occurred while submitting:", err); // Error handling
    }
  };

  //#endregion

  //#region Handle ID
  const handleRadios = (id) => {
    setSelectedOption([id]);
  };
  //#endregion

  //#region Handle checboxes
  const handleCheckbox = (id) => {
    if (selectedOption.includes(id)) {
      setSelectedOption(selectedOption.filter((item) => item !== id));
    } else {
      setSelectedOption([...selectedOption, id]);
    }
  };
  //#endregion

  //#region Timer for test
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining((prevTime) => {
        return prevTime + 1;
      });
    }, 1000);
    timecheck.seconds = remaining;
    localStorage.setItem("time", JSON.stringify(timecheck));
    return () => clearInterval(interval);
  }, [timecheck]);

  const width = Math.min(
    (timecheck.seconds / (parseInt(currentSection.time) * 60)) * 100,
    100
  );
  timecheck.timeLeft = width;
  localStorage.setItem("time", JSON.stringify(timecheck));
  const remainingTime = parseInt(currentSection.time) * 60;
  const roundedValue = Math.round(remainingTime - timecheck.seconds);

  //#endregion

  //#region Handling skip
  const handleSkipQuestion = () => {
    localStorage.removeItem("exam");
    let skipData = {
      usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      sectionId: parseInt(currentSection.ModuleID),
      questionId: questionID,
      evaluation: currentSection.evaluation,
      selectedOptions: [],
      status: "abc",
    };

    // Check if extra parameters need to be added
    if (data?.meta?.hasNextPage === false) {
      skipData.timeTakenToAnswer = barWidth;
      skipData.isSectionLastQuestion = true;
    }

    // Prepare the data for the first API call
    let data_new = JSON.stringify(skipData);

    // Prepare the data for the second API call
    let data2 = {
      content: { ...skipData },
      id: res_id,
    };

    try {
      if (!questState) {
        mutate(data_new);
      } else {
        updateMutate(data2);
      }
    } catch (err) {
      console.error("Error occurred while submitting:", err); // Error handling
    }
  };
  //#endregion

  //#region if module got no questions
  useEffect(() => {
    if (!isLoading) {
      if (data?.data?.length === 0) {
        navigate("/module-feedback");
      }
    }
  }, [data?.data?.length]);
  //#endregion

  //#region handling tab close
  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      return (event.returnValue = "Are you sure you want to exit?");
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  //#endregion

  //#region handle submit
  const handleSubmit = () => {
    // Create the common data object
    let dataz = {
      usersId: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
      assessmentId: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
      sectionId: parseInt(currentSection.ModuleID),
      questionId: questionID,
      evaluation: currentSection.evaluation,
      selectedOptions: selectedOption,
      status: "abc",
    };

    // Conditionally add extra parameters if the criteria are met
    if (data?.meta?.hasNextPage === false || totalDuration === 0) {
      dataz.isSectionLastQuestion = true;
      dataz.timeTakenToAnswer = barWidth;
    }

    // Convert the data object to a JSON string
    const jsonData = JSON.stringify(dataz);

    try {
      // Submit the data using the mutate function
      submitMutate(jsonData);
    } catch (err) {
      // Error handling
      console.error("Error occurred while submitting:", err);
    }
  };
  //#endregion

  //#region calculating percentage for number of questions solved / skipped

  question.current = data?.meta?.page - 1;
  localStorage.setItem("questions", JSON.stringify(question));

  question.total = data?.meta?.itemCount;
  localStorage.setItem("questions", JSON.stringify(question));

  question.solved = Math.min((question.current / question.total) * 100);
  localStorage.setItem("questions", JSON.stringify(question));

  //#endregion

  //#region Update Status
  const { mutate: mutateStatus, isLoading: mutateLoading } = useMutation(
    updateStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/evaluation");
      },
    }
  );

  const handleUpdate = () => {
    let sessionInfo = {
      sectionId: parseInt(currentSection.ModuleID),
      questionId: questionID,
      evaluation: currentSection.evaluation,
      seconds_left: minsec.secleft,
      currentModuleNumber: currentSection.CurrentModule,
      PracticeSectionModule: practiceSection?.CurrentModule,
      PracticeModuleID: practiceSection?.ModuleID,
      PracticePage: practiceSection?.pageQuestion,
      PracticeTime: practiceSection?.time,
      timeWidth: timecheck.timeLeft,
      CompletionTime: timecheck.seconds,
      QuestionPage: currentSection?.pageQuestion,
      totalModuleTime: currentSection?.time,
      currentModule: moduleData?.current,
      TotalModules: moduleData?.last,
      time_consumed: barWidth,
      lastModule:
        parseInt(moduleData?.current) === parseInt(moduleData?.last)
          ? true
          : false,
    };
    let datanew = {
      evaluation: currentSection.evaluation,
      data: JSON.stringify({
        user: parseInt(localStorage.getItem("CP-CANDIDATE-ID")),
        assessment: parseInt(localStorage.getItem("CANDIDATE-ASSESSMENT-ID")),
        status: "InProgress",
        sessionInfo: JSON.stringify(sessionInfo),
      }),
    };
    try {
      mutateStatus(datanew);
    } catch (err) {
      //catching error
    }
  };

  const handleUpdateComplete = () => {
    let datanew = {
      evaluation: currentSection.evaluation,
      data: JSON.stringify({
        // end: handleConvert(barWidth),
        end: currentTimeUnix,
        status: "completed",
      }),
    };
    try {
      mutateStatus(datanew);
    } catch (err) {
      //catching error
    }
  };

  const [totalbarWidth, setTotalBarWidth] = useState(minsec.secBar);
  const [barWidth, setBarWidth] = useState(timecheck?.time_consumed);

  console.log(barWidth, "bar width");
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTotalDuration((prevDuration) => {
        if (prevDuration > 0) {
          return prevDuration - 1;
        } else {
          clearInterval(timerInterval);
          return prevDuration;
        }
      });
    }, 1000);
    minsec.secleft = totalDuration;
    localStorage.setItem("minsec", JSON.stringify(minsec));
    return () => clearInterval(timerInterval);
  }, [totalDuration]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setBarWidth((prevDuration) => {
        if (barWidth !== totalbarWidth) {
          return prevDuration + 1;
        } else {
          clearInterval(timerInterval);
          return prevDuration;
        }
      });
    }, 1000);
    timecheck.time_consumed = barWidth;
    localStorage.setItem("time", JSON.stringify(timecheck));
    return () => clearInterval(timerInterval);
  }, [barWidth]);

  const minutes = Math.floor(totalDuration / 60);
  const seconds = totalDuration % 60;

  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;

  useEffect(() => {
    {
      if (totalDuration === 0 && moduleData.current !== moduleData.last) {
        handlePostQuestion();
        navigate("/module-feedback");
      } else if (
        totalDuration === 0 &&
        moduleData.current === moduleData.last
      ) {
        localStorage.setItem("exam", "start");
        handlePostQuestion();
        handleUpdateComplete();
        dispatch(setExamtoTrue(true));
        setTimeout(() => {
          navigate("/module-feedback");
        }, 500);
      }
    }
  }, [
    totalDuration,
    parseInt(moduleData.current, 10),
    parseInt(moduleData.last, 10),
  ]);
  //#endregion

  //#region handle back browser
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function (event) {
      window.history.pushState(null, null, window.location.href);
    };
    return () => {
      window.onpopstate = null;
    };
  }, []);
  //#endregion

  //#region montinor browser cam settings
  const checkCameraPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera",
      });

      if (permissionStatus.state === "denied") {
        setAccessDenied(true);
        setCamLogs(false);
      } else if (permissionStatus.state === "prompt") {
        setAccessDenied(false);
        setCamLogs(false);
      } else if (permissionStatus.state === "granted") {
        try {
          // Try to access the camera stream
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          setAccessDenied(false);
          setCamLogs(true);

          // Stop all tracks to release the camera
          stream.getTracks().forEach((track) => track.stop());
        } catch (error) {
          console.error("Error accessing the camera stream:", error);
          setAccessDenied(false);
          setCamLogs(false);
        }
      }
    } catch (error) {
      console.error("Error checking camera permission:", error);
    }
  };

  useEffect(() => {
    checkCameraPermission();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkCameraPermission();
    }, 2000);

    return () => clearInterval(interval);
  }, [accessDenied, camLogs]);

  //#endregion

  //#region Adjusting colors
  const [isHovered, setIsHovered] = useState(false);

  function darkenHexColor(hex, percent) {
    // Ensure the hex code is in the correct format
    hex = hex?.replace(/^#/, "");

    // Convert 3-digit hex to 6-digit hex
    if (hex?.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    // Convert hex to RGB
    let r = parseInt(hex?.substring(0, 2), 16);
    let g = parseInt(hex?.substring(2, 4), 16);
    let b = parseInt(hex?.substring(4, 6), 16);

    // Calculate the darkened color
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Convert RGB back to hex
    const darkenedHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return darkenedHex;
  }

  // Example usage:
  const originalColor = theme.color;
  const darkenedColor = darkenHexColor(originalColor, 15);

  const buttonStyle = {
    background: isHovered ? darkenedColor : theme.color,
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    color: theme.sec_color,
    border: `1px solid ${theme.sec_color}`,
    fontFamily: "Archia Semibold",
  };

  const buttonStyle3 = {
    background: isHovered ? `rgba(${theme.sec_color}, 1.9)` : theme.sec_color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
    fontFamily: "Archia Semibold",
    color: theme.color,
    border: `1px solid ${theme.color}`,
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  function LightenDarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }

  function DarkenColor(col, amt) {
    if (col === "#000000") {
      // If the color is black, handle it differently
      return "#454545";
    }

    var usePound = false;

    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) - amt;

    if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) - amt;

    if (b < 0) b = 0;

    var g = (num & 0x0000ff) - amt;

    if (g < 0) g = 0;

    return (
      (usePound ? "#" : "") +
      ("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6)
    );
  }

  var DarkenedColor = DarkenColor(theme.color, 50);

  //#endregion

  //#region taking screenshots
  useEffect(() => {
    // Start capturing screenshots
    const interval = setInterval(() => takeScreenshot(), 2000); // 5 seconds
    setScreenshotInterval(interval);

    // Clean up on component unmount
    return () => clearInterval(interval);
  }, []);

  const takeScreenshot = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        const track = stream.getVideoTracks()[0];
        const imageCapture = new ImageCapture(track);

        imageCapture
          .grabFrame()
          .then((imageBitmap) => {
            // Convert imageBitmap to canvas
            const canvas = document.createElement("canvas");
            canvas.width = imageBitmap.width;
            canvas.height = imageBitmap.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(imageBitmap, 0, 0);

            canvas.toBlob((blob) => {
              UploadImageToApi(blob);
            });

            // Stop the webcam stream
            stream.getTracks().forEach((track) => track.stop());
          })
          .catch((error) => {
            console.error("Error grabbing frame:", error);
          });
      })
      .catch((error) => {
        console.error("Error accessing webcam:", error);
      });
  };

  //#endregion

  //#region check if user completed feedback
  useEffect(() => {
    if (completion_check === "yes") {
      navigate("/feedback");
    } else if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, [completion_check, user_exists]);
  //#endregion

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  document.title = "Exam | Dexta";

  return (
    <ReactCursorPosition
      className="example__target"
      mapChildProps={({
        elementDimensions,
        isActive,
        isPositionOutside,
        position,
      }) => {
        if (isPositionOutside) {
          setIsOutside(true);
        } else {
          setIsOutside(false);
        }
        return {
          elementDimensions,
          isActive,
          isOutside: isPositionOutside,
          point: position,
        };
      }}
    >
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <nav className="w-full top-0 left-0 py-2 bg-white">
        <div className="w-3/4 px-2 items-center py-2 text-xl text-coalColor mx-auto">
          <div className="grid md:grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className="my-auto object-contain sm:max-lg:w-[100px] sm:max-lg:h-[70px]"
              style={{ height: "70px" }}
            />
            <div className="my-auto w-full">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row h-5">
                  <div className="w-20 flex flex-row">
                    <BsFillClockFill
                      color={DarkenedColor}
                      className="w-4 h-4 my-auto"
                    />
                    <p
                      className="text-coalColor ml-2 text-sm flex items-center"
                      style={{ fontFamily: "Silka" }}
                    >
                      {isLoading ? (
                        <span className="flex items-center justify-center ml-2 font-bold">
                          <div
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        </span>
                      ) : (
                        <>{formattedTime}</>
                      )}
                    </p>
                  </div>
                  <div
                    className="w-full rounded-lg ml-2 my-auto"
                    style={{ background: theme?.sec_color }}
                  >
                    <div
                      className="p-2 rounded-lg text-center text-xs font-medium leading-none text-primary-100"
                      style={{
                        width: `${(barWidth / totalbarWidth) * 100}%`,
                        background: DarkenedColor,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="flex flex-row h-5">
                  <div className="w-20 flex flex-row">
                    <FaRunning
                      color={DarkenedColor}
                      className="w-4 h-4 my-auto"
                    />
                    <p
                      className="text-coalColor ml-2 text-sm flex items-center"
                      style={{ fontFamily: "Silka" }}
                    >
                      {isLoading ? (
                        <span className="flex items-center justify-center ml-2 font-bold">
                          <div
                            className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        </span>
                      ) : (
                        <>
                          {question.current + 1}/{question.total}
                        </>
                      )}
                    </p>
                  </div>
                  <div
                    className="w-full rounded-lg ml-2 my-auto"
                    style={{ background: theme?.sec_color }}
                  >
                    <div
                      className="p-0.5 rounded-lg py-2 text-center text-xs font-medium leading-none text-primary-100"
                      style={{
                        width: `${question.solved}%`,
                        background: DarkenedColor,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-auto ml-auto flex mt-5 md:mt-3">
              <div className="">
                {moduleData.current === moduleData.last &&
                data?.meta?.hasNextPage === false ? (
                  <div className="flex flex-row gap-3">
                    <button
                      className={`inline-flex items-center w-full justify-center px-8 py-3 my-auto ${
                        data?.meta?.hasPreviousPage === false &&
                        "bg-[#FFFFFF]/90 cursor-not-allowed"
                      } text-sm font-medium rounded-md`}
                      style={{
                        color: theme.color,
                        backgroundColor: theme?.sec_color,
                        fontFamily: "Archia Semibold",
                      }}
                      disabled={
                        data?.meta?.hasPreviousPage === false ||
                        questionLoading ||
                        isLoading ||
                        backLoading
                      }
                      onClick={() => {
                        handleBackQuestion();
                      }}
                    >
                      <AiOutlineArrowLeft
                        color={DarkenColor}
                        className="w-5 h-5 mr-2  icon-image"
                      />
                      Back
                    </button>
                    <button
                      className={`inline-flex items-center w-full justify-center px-8 py-3 my-auto  ${
                        selectedOption.length === 0 &&
                        "bg-[#FFFFFF]/90 cursor-not-allowed"
                      } text-sm font-medium rounded-md`}
                      style={{
                        color: theme.color,
                        backgroundColor: theme?.sec_color,
                        fontFamily: "Archia Semibold",
                      }}
                      disabled={selectedOption.length === 0}
                      onClick={() => {
                        handleSubmit();
                        setCounter(counter + 1);
                        handleApiRequest();
                        setTimeout(() => {
                          handleUpdateComplete();
                        }, 1000);
                      }}
                    >
                      Submit
                      <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-row gap-3">
                      <button
                        className={`inline-flex items-center w-full justify-center px-8 py-3 my-auto  ${
                          data?.meta?.hasPreviousPage === false &&
                          "bg-[#FFFFFF]/90 cursor-not-allowed"
                        } text-sm font-medium rounded-md`}
                        style={{
                          color: theme.color,
                          backgroundColor: theme?.sec_color,
                          fontFamily: "Archia Semibold",
                        }}
                        disabled={
                          data?.meta?.hasPreviousPage === false ||
                          questionLoading ||
                          isLoading
                        }
                        onClick={() => {
                          handleBackQuestion();
                        }}
                      >
                        <AiOutlineArrowLeft
                          color={DarkenColor}
                          className="w-5 h-5 mr-2  icon-image"
                        />
                        Back
                      </button>
                      {selectedOption.length === 0 ? (
                        <button
                          className="inline-flex items-center w-full justify-center px-8 py-3 my-auto  hover:bg-[#FFFFFF]/90  text-sm font-medium rounded-md"
                          style={{
                            color: theme.color,
                            backgroundColor: theme?.sec_color,
                            fontFamily: "Archia Semibold",
                          }}
                          disabled={
                            questionLoading || isLoading || updateLoading
                          }
                          onClick={() => setSkipModal(true)}
                        >
                          Skip
                          <AiOutlineArrowRight
                            color={DarkenColor}
                            className="w-5 h-5 ml-2 icon-image"
                          />
                        </button>
                      ) : (
                        <button
                          className="inline-flex items-center w-full justify-center px-8 py-3 my-auto text-sm font-medium rounded-md"
                          style={{
                            color: theme.color,
                            backgroundColor: theme?.sec_color,
                            fontFamily: "Archia Semibold",
                          }}
                          onClick={() => {
                            handlePostQuestion();
                            handleUpdate();
                            if (data?.meta?.hasNextPage === false) {
                              setCounter(counter + 1);
                              handleApiRequest();
                            }
                          }}
                        >
                          Continue
                          <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="bg-bodyColor">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen mb-[190px]">
            <div className="xl:mt-[30px] 2xl:mt-[50px] w-5/6 rounded-lg ">
              {(!isLoading && !fetchingOptions) ||
                (!backLoading && (
                  <h1
                    className="text-xl font-bold py-5"
                    style={{ fontFamily: "Archia Bold" }}
                  >
                    {/* {currentSection.module_Name} */}
                  </h1>
                ))}
              {isLoading || fetchingOptions || backLoading ? (
                <div class="loader-container-3 col-span-6">
                  <div class="loader-3"></div>
                </div>
              ) : (
                <div className="rounded-md bg-white">
                  <div className="p-3 md:p-8">
                    {data?.data?.map((i, index) => {
                      questionID = i?.id;
                      localStorage.setItem("Question", i?.id);
                      let options_check;
                      if (
                        i?.options?.every((option) => option.imageUrl === null)
                      ) {
                        options_check = "null";
                      } else if (
                        i?.options?.every(
                          (option) =>
                            option.imageUrl !== null && option?.title !== null
                        )
                      ) {
                        options_check = "all";
                      } else if (
                        i?.options?.every(
                          (option) =>
                            option.imageUrl !== null && option?.title === null
                        )
                      ) {
                        options_check = "all images";
                      } else {
                        options_check = "some_image";
                      }
                      return (
                        <React.Fragment key={i.id}>
                          <div className="grid md:grid-cols-2 gap-5">
                            <div className="pt-1 2xl:pt-3.5 md:pl-5">
                              <div className="md:pr-8">
                                <h1
                                  className="text-coalColor text-lg font-bold"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  QUESTION {parseInt(question.current) + 1} of{" "}
                                  {question.total}
                                </h1>
                                <div
                                  className="mt-5"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <div className={styles["html-content"]}>
                                    {ReactHtmlParser(i.description)}
                                  </div>
                                </div>
                              </div>

                              {i?.image != null &&
                                i?.image != " " &&
                                i?.image != "" && (
                                  <div className="">
                                    <img
                                      src={i?.image}
                                      className="rounded-lg object-contain cursor-pointer"
                                      style={{
                                        height: "400px",
                                        width: "850px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageClick(i?.image);
                                      }}
                                    />
                                  </div>
                                )}

                              {i?.figImage != null &&
                                i?.figImage != " " &&
                                i?.figImage != "" && (
                                  <div className="">
                                    <img
                                      src={i?.figImage}
                                      className="rounded-lg object-contain cursor-pointer"
                                      style={{
                                        height: "400px",
                                        width: "850px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageClick(i?.figImage);
                                      }}
                                    />
                                  </div>
                                )}
                            </div>
                            {i.type === "Single" ? (
                              <>
                                <div className="pt-1 2xl:pt-5 md:pl-2">
                                  <div
                                    className="md:px-10 text-coalColor text-lg font-bold rounded-lg"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    SELECT ONLY ONE
                                    {i?.options
                                      ?.sort((a, b) =>
                                        a?.optionPosition > b?.optionPosition
                                          ? 1
                                          : -1
                                      )
                                      // Group options into pairs for two per row
                                      .reduce((acc, curr, index, array) => {
                                        if (index % 2 === 0)
                                          acc.push(
                                            array.slice(index, index + 2)
                                          );
                                        return acc;
                                      }, [])
                                      .map((pair, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          className={`grid ${
                                            options_check === "all images" &&
                                            "grid-cols-2"
                                          }  gap-4 mt-5`}
                                        >
                                          {pair.map((j) => (
                                            <>
                                              {options_check ===
                                              "all images" ? (
                                                <div
                                                  key={j.id}
                                                  onClick={() =>
                                                    handleRadios(j.id)
                                                  }
                                                  className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                                >
                                                  <div className="relative flex items-center justify-center">
                                                    {/* Container for image and zoom button */}
                                                    <div className="relative group">
                                                      <img
                                                        src={j?.imageUrl}
                                                        className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                        style={{
                                                          borderColor:
                                                            selectedOption.includes(
                                                              j.id
                                                            )
                                                              ? theme.color
                                                              : "white",
                                                          borderStyle: "solid",
                                                          borderWidth: "3px",
                                                        }}
                                                      />

                                                      <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        <button
                                                          className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleImageClick(
                                                              j?.imageUrl
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={eye}
                                                            className="w-5 h-5 object-contain"
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  key={j.id}
                                                  onClick={() =>
                                                    handleRadios(j.id)
                                                  }
                                                  className={`p-3 2xl:p-5 mt-5 cursor-pointer ${
                                                    selectedOption.includes(
                                                      j.id
                                                    )
                                                      ? "bg-black"
                                                      : null
                                                  } hover:animate-[jiggle_1s_ease-in-out_infinite] rounded-2xl border border-[#B6B6B6]`}
                                                  style={{
                                                    background:
                                                      selectedOption.includes(
                                                        j.id
                                                      )
                                                        ? theme.color
                                                        : "white",
                                                  }}
                                                >
                                                  <div className="flex justify-between">
                                                    <div className="flex gap-10">
                                                      <div className="inline-flex items-center">
                                                        <label
                                                          className="relative flex cursor-pointer items-center rounded-full p-3"
                                                          htmlFor={`radio_${j.id}`}
                                                          data-ripple-dark="true"
                                                        >
                                                          <input
                                                            id={`radio_${j.id}`}
                                                            name="type"
                                                            type="radio"
                                                            className="peer relative 2xl:h-7 2xl:w-7 w-5 h-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-coalColor transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-coalColor checked:before:bg-black hover:before:opacity-10"
                                                            onChange={() =>
                                                              handleRadios(j.id)
                                                            }
                                                            checked={selectedOption.includes(
                                                              j.id
                                                            )}
                                                          />
                                                          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                            <svg
                                                              xmlns="http://www.w3.org/2000/svg"
                                                              className="2xl:h-5 2xl:w-5 w-3 h-3"
                                                              viewBox="0 0 16 16"
                                                              fill="currentColor"
                                                            >
                                                              <circle
                                                                data-name="ellipse"
                                                                cx="8"
                                                                cy="8"
                                                                r="8"
                                                              ></circle>
                                                            </svg>
                                                          </div>
                                                        </label>
                                                        <label
                                                          className={`mt-px cursor-pointer select-none text-sm font-medium`}
                                                          htmlFor={`radio_${j.id}`}
                                                          style={{
                                                            fontFamily: "Silka",
                                                            color:
                                                              selectedOption.includes(
                                                                j.id
                                                              )
                                                                ? theme?.sec_color
                                                                : "#252e3a",
                                                          }}
                                                        >
                                                          {j.title}
                                                        </label>
                                                      </div>
                                                    </div>
                                                    {(options_check === "all" ||
                                                      options_check ===
                                                        "some_image") && (
                                                      <div className="my-auto">
                                                        {j?.imageUrl ? (
                                                          <div className="relative flex items-center justify-center">
                                                            {/* Container for image and zoom button */}
                                                            <div className="relative group">
                                                              <img
                                                                src={
                                                                  j?.imageUrl
                                                                }
                                                                className="my-auto w-20 h-20 object-cover"
                                                              />

                                                              <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <button
                                                                  className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    handleImageClick(
                                                                      j?.imageUrl
                                                                    );
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={eye}
                                                                    className="w-3 h-3 object-contain"
                                                                  />
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="w-20 h-20"></div>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          ))}
                                        </div>
                                      ))}
                                  </div>
                                  <div className="flex justify-center my-auto md:px-10 mt-8">
                                    {moduleData.current === moduleData.last &&
                                    data?.meta?.hasNextPage === false ? (
                                      <button
                                        className={`inline-flex items-center w-full justify-center px-8 py-4 my-auto ${
                                          selectedOption.length === 0
                                            ? "cursor-not-allowed"
                                            : ""
                                        } text-white text-sm font-medium rounded-md`}
                                        disabled={selectedOption.length === 0}
                                        style={buttonStyle}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleLeave}
                                        onClick={() => {
                                          handleSubmit();
                                          setCounter(counter + 1);
                                          handleApiRequest();
                                          setTimeout(() => {
                                            handleUpdateComplete();
                                          }, 1000);
                                        }}
                                      >
                                        Submit
                                        <GoArrowRight
                                          alt="Add Transaction Icon"
                                          className="w-5 h-5 ml-2 icon-image"
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        {selectedOption.length === 0 ? (
                                          <button
                                            className="inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-lg"
                                            disabled={
                                              questionLoading ||
                                              isLoading ||
                                              updateLoading
                                            }
                                            style={buttonStyle}
                                            onMouseEnter={handleHover}
                                            onMouseLeave={handleLeave}
                                            onClick={() => setSkipModal(true)}
                                          >
                                            Skip
                                            <GoArrowRight
                                              alt="Add Transaction Icon"
                                              className="w-5 h-5 ml-2 icon-image"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            className="inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-md"
                                            style={buttonStyle}
                                            onMouseEnter={handleHover}
                                            onMouseLeave={handleLeave}
                                            onClick={() => {
                                              handleUpdate();
                                              handlePostQuestion();
                                              if (
                                                data?.meta?.hasNextPage ===
                                                false
                                              ) {
                                                setCounter(counter + 1);
                                                handleApiRequest();
                                              }
                                            }}
                                          >
                                            Continue
                                            <GoArrowRight
                                              alt="Add Transaction Icon"
                                              className="w-5 h-5 ml-2 icon-image"
                                            />
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {isModalOpen && (
                                    <div
                                      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                                      onClick={() => setIsModalOpen(false)}
                                    >
                                      <div className="relative bg-white p-5 rounded-md">
                                        <img
                                          src={closeIcon}
                                          className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                                          onClick={() => setIsModalOpen(false)}
                                        />
                                        <img
                                          src={selectedImageUrl}
                                          alt="Selected"
                                          className="p-5 object-contain"
                                          style={{
                                            height: "450px",
                                            width: "850px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="pt-1 2xl:pt-5 md:pl-2">
                                  <div
                                    className="md:px-10 text-coalColor text-xl font-bold rounded-lg"
                                    style={{ fontFamily: "Archia Bold" }}
                                  >
                                    SELECT ALL THAT APPLY
                                    {i?.options
                                      ?.sort((a, b) =>
                                        a?.optionPosition > b?.optionPosition
                                          ? 1
                                          : -1
                                      )
                                      .reduce((acc, curr, index, array) => {
                                        if (index % 2 === 0)
                                          acc.push(
                                            array.slice(index, index + 2)
                                          );
                                        return acc;
                                      }, [])
                                      .map((pair, rowIndex) => (
                                        <div
                                          key={rowIndex}
                                          className={`grid ${
                                            options_check === "all images" &&
                                            "grid-cols-2"
                                          }  gap-4 mt-5`}
                                        >
                                          {pair.map((j) => (
                                            <>
                                              {options_check ===
                                              "all images" ? (
                                                <div
                                                  key={j.id}
                                                  onClick={() =>
                                                    handleCheckbox(j.id)
                                                  }
                                                  className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                                >
                                                  <div className="relative flex items-center justify-center">
                                                    {/* Container for image and zoom button */}
                                                    <div className="relative group">
                                                      <img
                                                        src={j?.imageUrl}
                                                        className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                        style={{
                                                          borderColor:
                                                            selectedOption.includes(
                                                              j.id
                                                            )
                                                              ? theme.color
                                                              : "white",
                                                          borderStyle: "solid",
                                                          borderWidth: "3px",
                                                        }}
                                                      />

                                                      <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                        <button
                                                          className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                          onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleImageClick(
                                                              j?.imageUrl
                                                            );
                                                          }}
                                                        >
                                                          <img
                                                            src={eye}
                                                            className="w-5 h-5 object-contain"
                                                          />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  className={`p-3 2xl:p-5 mt-3 rounded-2xl border border-[#B6B6B6] cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                                  key={j.id}
                                                  style={{
                                                    background:
                                                      selectedOption.includes(
                                                        j.id
                                                      )
                                                        ? theme.color
                                                        : "white",
                                                  }}
                                                  onClick={() =>
                                                    handleCheckbox(j?.id)
                                                  }
                                                >
                                                  <div className="flex justify-between">
                                                    <div className="inline-flex items-center">
                                                      <label
                                                        className="relative flex cursor-pointer items-center rounded-full p-3"
                                                        htmlFor={`radio_${j.id}`}
                                                        data-ripple-dark="true"
                                                      >
                                                        <input
                                                          id="ripple-on"
                                                          type="checkbox"
                                                          className={`peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 border-gray-600  before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-10`}
                                                          onClick={() =>
                                                            handleCheckbox(
                                                              j?.id
                                                            )
                                                          }
                                                          checked={selectedOption.includes(
                                                            j.id
                                                          )}
                                                          onChange={() =>
                                                            handleCheckbox(
                                                              j?.id
                                                            )
                                                          }
                                                        />
                                                        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-3.5 w-3.5"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            stroke="currentColor"
                                                            strokeWidth="1"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                              clipRule="evenodd"
                                                            ></path>
                                                          </svg>
                                                        </div>
                                                      </label>
                                                      <label
                                                        className={`mt-px cursor-pointer select-none text-sm font-medium ${
                                                          selectedOption?.includes(
                                                            j?.id
                                                          )
                                                            ? "text-[#FFFFFF]"
                                                            : "text-coalColor"
                                                        }`}
                                                        htmlFor="html"
                                                        style={{
                                                          fontFamily: "Silka",
                                                          color:
                                                            selectedOption.includes(
                                                              j.id
                                                            )
                                                              ? theme?.sec_color
                                                              : "#252e3a",
                                                        }}
                                                      >
                                                        {j.title}
                                                      </label>
                                                    </div>
                                                    {(options_check === "all" ||
                                                      options_check ===
                                                        "some_image") && (
                                                      <div className="my-auto">
                                                        {j?.imageUrl ? (
                                                          <div className="relative flex items-center justify-center">
                                                            {/* Container for image and zoom button */}
                                                            <div className="relative group">
                                                              <img
                                                                src={
                                                                  j?.imageUrl
                                                                }
                                                                className="my-auto w-20 h-20 object-cover"
                                                              />

                                                              <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <button
                                                                  className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                                  onClick={(
                                                                    e
                                                                  ) => {
                                                                    e.stopPropagation();
                                                                    handleImageClick(
                                                                      j?.imageUrl
                                                                    );
                                                                  }}
                                                                >
                                                                  <img
                                                                    src={eye}
                                                                    className="w-3 h-3 object-contain"
                                                                  />
                                                                </button>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div className="w-20 h-20"></div>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          ))}
                                        </div>
                                      ))}
                                  </div>

                                  <div className="flex justify-center my-auto md:px-10 mt-8">
                                    {moduleData.current === moduleData.last &&
                                    data?.meta?.hasNextPage === false ? (
                                      <button
                                        className={`inline-flex items-center w-full justify-center px-8 py-4 my-auto  ${
                                          selectedOption.length === 0
                                            ? "cursor-not-allowed"
                                            : ""
                                        }  text-white text-sm font-medium rounded-md`}
                                        disabled={selectedOption.length === 0}
                                        style={buttonStyle}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={handleLeave}
                                        onClick={() => {
                                          handleSubmit();
                                          setCounter(counter + 1);
                                          handleApiRequest();
                                          setTimeout(() => {
                                            handleUpdateComplete();
                                          }, 1000);
                                        }}
                                      >
                                        Submit
                                        <GoArrowRight
                                          alt="Add Transaction Icon"
                                          className="w-5 h-5 ml-2 icon-image"
                                        />
                                      </button>
                                    ) : (
                                      <>
                                        {selectedOption.length === 0 ? (
                                          <button
                                            className="inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-lg"
                                            disabled={
                                              questionLoading ||
                                              isLoading ||
                                              updateLoading
                                            }
                                            style={buttonStyle}
                                            onMouseEnter={handleHover}
                                            onMouseLeave={handleLeave}
                                            onClick={() => setSkipModal(true)}
                                          >
                                            Skip
                                            <GoArrowRight
                                              alt="Add Transaction Icon"
                                              className="w-5 h-5 ml-2 icon-image"
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            className="inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-md"
                                            style={buttonStyle}
                                            onMouseEnter={handleHover}
                                            onMouseLeave={handleLeave}
                                            onClick={() => {
                                              handleUpdate();
                                              handlePostQuestion();
                                              if (
                                                data?.meta?.hasNextPage ===
                                                false
                                              ) {
                                                setCounter(counter + 1);
                                                handleApiRequest();
                                              }
                                            }}
                                          >
                                            Continue
                                            <GoArrowRight
                                              alt="Add Transaction Icon"
                                              className="w-5 h-5 ml-2 icon-image"
                                            />
                                          </button>
                                        )}
                                      </>
                                    )}
                                  </div>
                                  {isModalOpen && (
                                    <div
                                      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
                                      onClick={() => setIsModalOpen(false)}
                                    >
                                      <div className="relative bg-white p-5 rounded-md">
                                        <img
                                          src={closeIcon}
                                          className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
                                          onClick={() => setIsModalOpen(false)}
                                        />
                                        <img
                                          src={selectedImageUrl}
                                          alt="Selected"
                                          className="p-5 object-contain"
                                          style={{
                                            height: "450px",
                                            width: "850px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {skipModal && (
        <div
          id="crypto-modal"
          tabindex="-1"
          aria-hidden="true"
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60"
          style={{ zIndex: 999 }}
        >
          <div class="relative p-4 w-full max-w-xl max-h-full">
            <div class="relative bg-white rounded-lg shadow ">
              <button
                type="button"
                class="absolute top-3 end-2.5 text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center  hover:bg-gray-200"
                data-modal-hide="popup-modal"
                onClick={() => setSkipModal(false)}
              >
                <img src={closeImg} className="w-5 h-5" />

                <span class="sr-only">Close modal</span>
              </button>
              <div class="px-6 py-4 pl-2 text-center">
                <h3
                  class="mb-5 text-xl font-bold text-gray-800"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Are you sure you want to skip this question?
                </h3>
                <p className="px-4 mt-8" style={{ fontFamily: "Silka" }}>
                  You may skip this question if the answer is not immediately
                  apparent. Feel free to return later and provide your response
                </p>
                <div
                  className="flex flex-row justify-end mt-8 gap-3"
                  style={{ fontFamily: "Silka" }}
                >
                  <button
                    className="inline-flex items-center justify-center px-4 py-2.5 my-auto text-white border border-black text-sm font-medium rounded-lg bg-coalColor"
                    disabled={questionLoading || isLoading}
                    style={buttonStyle3}
                    onClick={() => {
                      handleUpdate();
                      handleSkipQuestion();
                      setSkipModal(false);
                    }}
                  >
                    {!questionLoading ? "Skip question" : "Skipping"}
                  </button>
                  <button
                    className="inline-flex items-center justify-center px-4 py-3 bg-primaryGreen my-auto text-coalColor border border-coalColor text-sm font-medium rounded-lg"
                    disabled={questionLoading || isLoading}
                    onClick={() => setSkipModal(false)}
                    style={buttonStyle}
                  >
                    Choose answer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ReactCursorPosition>
  );
};

export default Test;
