import React, { useEffect, useRef, useState } from "react";
import Logo from "../../../Dexta_assets/navbar-logo.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaInfoCircle } from "react-icons/fa";
import { handlePassword } from "../../../Components/Regex/Regex";
import "./auth.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createcandidateAccount } from "../hooks/createcandidateAccount";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../Components/Dexta/TextField/TextField";
import { IoIosArrowDown, IoMdClose } from "react-icons/io";
import {
  candidate_statuses,
  experience_years,
  highestEducation,
  language,
} from "../../../data/mapData";
import Scrollbars from "react-custom-scrollbars";
import useOutsideClick from "../../../Components/OutsideClick/OutsideClick";
import { getAllJobs } from "../../Profile/MyAssessments/Assessments-main/hooks/getAlljobs";
import { IoAddCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { updateCandidateData } from "../../../redux/reducers/CandidateDetails/CandidateLoginDetailsSlice";
const Signup = () => {
  const [focusedField, setFocusedField] = useState(null);
  const [BarPercentage, setBarPercentage] = useState(0);
  const [count, setCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const email = localStorage.getItem("CP-CANDIDATE-EMAIL");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState({
    capital: false,
    number: false,
    length: false,
    special: false,
  });
  const educationref = useRef(null);
  const [educationLevel, setEducationLevel] = useState(
    "Highest education level"
  );
  const [educationDrop, setEducationDrop] = useState(false);
  const [study, setStudy] = useState("");
  const [field, setField] = useState("");
  const [fieldDrop, setFieldDrop] = useState(false);
  const listInnerRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({
    currentTake: 1,
    hasNextPage: true,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const fieldRef = useRef(null);
  const [expDropdown, setExpDropdown] = useState(false);
  const [yearexp, setyearexp] = useState("Years of experience in field");
  const expRef = useRef(null);
  const [candidate, setCandidate] = useState("Candidate Status");
  const [candidateDropdown, setCandidateDropdown] = useState(false);
  const [otherValue, setOtherValue] = useState("");
  const candidateRef = useRef(null);
  const [lang, setLang] = useState("What is your first language");
  const [langDrop, setLangDrop] = useState(false);
  const langRef = useRef(null);
  const [otherEducation, setOtherEducation] = useState("");
  const candidateDetails = useSelector(
    (state) => state.candidateLoginDetails.candidateData
  );
  const dispatch = useDispatch();
  console.log(candidateDetails, "details of candidates");

  //#region calculating trues for bar percentage
  useEffect(() => {
    const trueCount = Object.values(passwordValidation).filter(Boolean).length;
    const newBarPercentage = trueCount * 25;
    setBarPercentage(newBarPercentage);
  }, [passwordValidation]);
  //#endregion

  //#region Checking Passwords
  const checkingPassword = (text) => {
    const { hasNumber, hasCapital, hasLength, hasSpecialCharacter } =
      handlePassword(text);
    setPasswordValidation({
      number: hasNumber,
      capital: hasCapital,
      length: hasLength,
      special: hasSpecialCharacter,
    });
  };
  //#endregion

  //#region Handle Focus and Blur
  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  //#endregion

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email,
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
      password: Yup.string().required("Password Required"),
    }),

    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
        password: values?.password,
        candidateMeta: {
          experience_in: field,
          experience_years:
            yearexp === "Years of experience in field" ? null : yearexp,
          firstLanguage: lang,
          employmentStatus:
            candidate !== "Other (Please specify)"
              ? candidate === "Candidate Status"
                ? ""
                : candidate
              : otherValue,

          educationLevel:
            educationLevel === "Highest education level" ? "" : educationLevel,
          study: study,
        },
        // "referredBy": values?.refer
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });

  const { mutate, isLoading } = useMutation(createcandidateAccount, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/sign-up");
      // const { accessToken, user } = response.data;
      validation.values.email = "";
      validation.values.password = "";
      dispatch(
        updateCandidateData({
          educationLevel: "",
          study: "",
          relevant_experience: "",
          years_experience: "",
          candidate_status: "",
          first_lang: "",
        })
      );
      if (response?.user?.isUserOnboard === false) {
        navigate(`/candidate/profile`);
      } else {
        navigate(`/candidate/dashboard`);
      }
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.success(errorMessage[0]);
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.success(errorMessage);
        }, 500);
      } else {
        setTimeout(() => {
          toast.success("An error occurred.");
        }, 500);
      }
    },
  });
  //#endregion

  //#region toggle password visible / invisible
  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }
  //#endregion

  //#region preview function
  const handleTermsAndPrivacy = (type) => {
    if (type === "terms") {
      const url = `/candidate/candidate-terms`;
      window.open(url, "_blank");
    } else {
      const url = `/candidate-recruiter/privacy-policy`;
      window.open(url, "_blank");
    }
  };
  //#endregion

  //#region handle outside click
  useOutsideClick(
    [
      {
        ref: educationref,
        excludeClasses: [".educationClass"],
        excludeIds: ["education"],
      },
      {
        ref: fieldRef,
        excludeClasses: [".fieldclass"],
        excludeIds: ["field"],
      },
      {
        ref: expRef,
        excludeClasses: [".expClass"],
        excludeIds: ["expYear"],
      },
      {
        ref: candidateRef,
        excludeClasses: [".candidateClass"],
        excludeIds: ["candidate"],
      },
      {
        ref: langRef,
        excludeClasses: [".langClass"],
        excludeIds: ["language"],
      },
    ],
    (ref) => {
      if (ref === educationref) setEducationDrop(false);
      if (ref === fieldRef) setFieldDrop(false);
      if (ref === expRef) setExpDropdown(false);
      if (ref === candidateRef) setCandidateDropdown(false);
      if (ref === langRef) setLangDrop(false);
    }
  );
  //#endregion

  //#region handle pagination of jobs
  const { data: jobsData, isLoading: jobsLoading } = useQuery(
    ["jobs", paginationInfo.currentTake, field],
    () => getAllJobs(paginationInfo.currentTake, field),
    {
      keepPreviousData: true,
    }
  );

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight && !jobsLoading) {
        if (
          jobsData?.meta?.hasNextPage !== false &&
          allData.length >= paginationInfo.currentTake * paginationInfo.pageSize
        ) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            currentTake: prevPaginationInfo.currentTake + 1,
          }));
        }
      }
    }
  };
  const handleScrollFrame = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      onScroll();
    }
  };

  useEffect(() => {
    if (!jobsLoading && jobsData && paginationInfo.currentTake === 1) {
      setAllData(jobsData?.data);
    } else if (!jobsLoading && jobsData && paginationInfo.currentTake > 1) {
      setAllData((prevData) => {
        const newData = jobsData?.data || [];
        const filteredData = newData.filter(
          (newItem) => !prevData.some((prevItem) => prevItem.id === newItem.id)
        );
        const updatedData = [...prevData, ...filteredData];

        // Update paginationInfo.hasNextPage based on whether all data is fetched
        if (filteredData.length < paginationInfo.pageSize) {
          setPaginationInfo((prevPaginationInfo) => ({
            ...prevPaginationInfo,
            hasNextPage: false,
          }));
        }

        return updatedData;
      });
    }
  }, [jobsData, jobsLoading, paginationInfo.currentTake, field]);

  useEffect(() => {
    if (paginationInfo?.currentTake > 1) {
      setAllData([]);
      setPaginationInfo((prev) => ({
        ...prev,
        currentTake: 1,
        hasNextPage: true,
      }));
    }
  }, [field]);

  //#region Calculate height based on the number of items
  const calculateHeight = () => {
    const itemHeight = 40;
    const maxVisibleItems = 8;

    // Number of items in allData
    const itemsCount = allData.length > 0 ? allData.length : 1; // Ensure there's height for the "No results" message

    // Determine the height based on the items count
    const height =
      itemsCount > maxVisibleItems
        ? itemHeight * maxVisibleItems
        : itemHeight * itemsCount;

    return height;
  };
  //#endregion

  //#region Setting placeholders
  useEffect(() => {
    if (educationLevel === "") {
      setEducationLevel("Highest education level");
    }
    if (yearexp === "") {
      setyearexp("Years of experience in field");
    }
    if (candidate === "") {
      setCandidate("Candidate Status");
    }
    if (lang === "") {
      setLang("What is your first language");
    }
  }, [yearexp, candidate, lang, educationLevel]);
  //#endregion

  //#region Fetching data from redux store and updating on the states
  useEffect(() => {
    if (candidateDetails?.candidate_status !== "") {
      setCandidate(candidateDetails?.candidate_status);
    }
    if (candidateDetails?.educationLevel !== "") {
      setEducationLevel(candidateDetails?.educationLevel);
    }
    if (candidateDetails?.first_lang !== "") {
      setLang(candidateDetails?.first_lang);
    }
    if (candidateDetails?.relevant_experience !== "") {
      setField(candidateDetails?.relevant_experience);
    }
    if (candidateDetails?.study !== "") {
      setStudy(candidateDetails?.study);
    }
    if (candidateDetails?.years_experience !== "") {
      setyearexp(candidateDetails?.years_experience);
    }
  }, []);
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center px-2 lg:px-0 h-screen">
        <div className="m-auto w-full lg:w-2/5 rounded-md bg-white mt-10">
          <div className="rounded-lg">
            <div className="sm:p-5 lg:px-14 lg:py-8">
              <img src={Logo} className="h-8" alt="CP Logo" />
              <h1
                className="pt-10 text-xl font-bold"
                style={{ fontFamily: "Archia Bold" }}
              >
                Create your Dexta Profile
              </h1>
              <p className="mt-6 text-lg" style={{ fontFamily: "Silka" }}>
                Enter the email address to which you received a test invite.
              </p>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                autoComplete="off"
              >
                <TextField
                  type="email"
                  name="email"
                  label="Enter your email"
                  rounded="rounded-md"
                  border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                    validation.values.firstName && "border-coalColor"
                  }`}
                  onChange={(e) => {
                    validation.handleChange(e);
                    checkingPassword(validation.values.password);
                  }}
                  onBlur={validation.handleBlur && handleBlur}
                  value={validation.values.email || ""}
                  placeholder="Enter your email"
                />
                <div className="relative">
                  <button
                    id="education"
                    className={`border border-[#D3D5D8] mt-3 ${
                      educationLevel === "Highest education level"
                        ? "text-[#999999]"
                        : "text-coalColor"
                    }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                    value={educationLevel}
                    style={{ fontFamily: "Silka" }}
                    onClick={() => setEducationDrop(!educationDrop)}
                    type="button"
                  >
                    {educationLevel != "" ? (
                      educationLevel === "Other (Please specify)" ? (
                        <input
                          placeholder="Please specify"
                          autoFocus
                          value={otherEducation}
                          onChange={(e) => setOtherEducation(e.target.value)}
                        />
                      ) : (
                        educationLevel
                      )
                    ) : (
                      "Highest education level"
                    )}
                    <div className="absolute top-1/2 right-3 mt-1.5 transform -translate-y-1/2 flex flex-row ">
                      <IoMdClose
                        onClick={() => {
                          setEducationLevel("");
                        }}
                        className="cursor-pointer"
                      />
                      <IoIosArrowDown />
                    </div>
                  </button>
                  {educationDrop && (
                    <div className="educationClass absolute z-40 border border-coalColor right-0 top-full h-[80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2">
                      {highestEducation?.map((i) => (
                        <ul
                          key={i.title}
                          className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                          ref={educationref}
                          onClick={() => {
                            setEducationLevel(i?.title);
                            setEducationDrop(false);
                          }}
                        >
                          <li>
                            {" "}
                            <a className="block px-5 py-2 text-sm font-medium">
                              {" "}
                              {i.title}{" "}
                            </a>
                          </li>
                        </ul>
                      ))}
                    </div>
                  )}
                </div>
                <TextField
                  type="text"
                  name="study"
                  label="What did you study"
                  rounded="rounded-md"
                  border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor `}
                  value={study}
                  onChange={(e) => setStudy(e.target.value)}
                  placeholder="What did you study"
                />
                <div className="relative">
                  <input
                    id="field"
                    className={`border border-[#D3D5D8] mt-3 text-coalColor focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                    value={field}
                    style={{ fontFamily: "Silka" }}
                    onChange={(e) => {
                      setField(e.target.value);
                      setSearchTerm(e.target.value);
                      setFieldDrop(true);
                    }}
                    onClick={() => setFieldDrop(!fieldDrop)}
                    placeholder="Most relevant experience"
                  />
                  <div className="absolute top-1/2 right-3 mt-1.5 transform -translate-y-1/2 flex flex-row ">
                    <IoMdClose
                      onClick={() => {
                        setField("");
                      }}
                      className="cursor-pointer"
                    />
                    <IoIosArrowDown />
                  </div>

                  {fieldDrop && (
                    <div
                      className={`fieldclass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full  overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2`}
                      id="experienceDropdown"
                      ref={listInnerRef}
                      style={{
                        fontFamily: "Silka",
                        height: calculateHeight(),
                      }}
                    >
                      <Scrollbars
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "hidden",
                        }}
                        onScrollFrame={handleScrollFrame}
                        hideTracksWhenNotNeeded
                      >
                        {allData?.map((item, index) => (
                          <ul
                            key={index}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={fieldRef}
                            onClick={() => {
                              setField(item?.name);
                              setFieldDrop(false);
                            }}
                          >
                            <li>
                              <a className="block px-5 py-2 text-sm font-medium">
                                {item.name}
                              </a>
                            </li>
                          </ul>
                        ))}
                        <>
                          {field !== "" &&
                            !allData.some((item) => item.name === field) && (
                              <ul
                                className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                                ref={fieldRef}
                                onClick={() => {
                                  setFieldDrop(false);
                                }}
                              >
                                <li className="flex flex-row">
                                  <a className="block px-5 py-2 text-sm font-medium">
                                    Add "{field}"
                                  </a>
                                  <IoAddCircle className="w-5 h-5 ml-auto my-auto" />
                                </li>
                              </ul>
                            )}
                        </>
                      </Scrollbars>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <input
                    id="expYear"
                    type="button"
                    className={`border border-[#D3D5D8] mt-3 ${
                      yearexp === "Years of experience in field"
                        ? "text-[#999999]"
                        : "text-coalColor"
                    }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                    value={yearexp}
                    style={{ fontFamily: "Silka" }}
                    onChange={(e) => {
                      setyearexp(e.target.value);
                      setExpDropdown(true);
                    }}
                    onClick={() => setExpDropdown(!expDropdown)}
                  />
                  <div className="absolute top-1/2 right-3 mt-1.5 transform -translate-y-1/2 flex flex-row ">
                    <IoMdClose
                      onClick={() => {
                        setyearexp("");
                      }}
                      className="cursor-pointer"
                    />
                    <IoIosArrowDown />
                  </div>

                  {expDropdown && (
                    <div
                      id="education-drop"
                      className="expClass absolute z-40 enable-scrollbar2 border border-coalColor right-0 top-full h-80 overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      style={{ fontFamily: "Silka" }}
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "100%" }}
                      >
                        {experience_years?.map((i) => (
                          <ul
                            key={i.title}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={expRef}
                            onClick={() => {
                              setyearexp(i?.title);
                              setExpDropdown(false);
                            }}
                          >
                            <li>
                              <a className="block px-5 py-2 text-sm font-medium">
                                {i.title}
                              </a>
                            </li>
                          </ul>
                        ))}
                      </Scrollbars>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    id="candidate"
                    type="button"
                    className={`border border-[#D3D5D8] mt-3 ${
                      candidate === "Candidate Status"
                        ? "text-[#999999]"
                        : "text-coalColor"
                    }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                    style={{ fontFamily: "Silka" }}
                    onClick={() => setCandidateDropdown(!candidateDropdown)}
                  >
                    {candidate != "" ? (
                      candidate === "Other (Please specify)" ? (
                        <input
                          placeholder="Please specify"
                          autoFocus
                          value={otherValue}
                          className="w-full"
                          onChange={(e) => setOtherValue(e.target.value)}
                        />
                      ) : (
                        candidate
                      )
                    ) : (
                      "Candidate Status"
                    )}
                    <div className="absolute top-1/2 right-3 mt-1.5 transform text-coalColor -translate-y-1/2 flex flex-row ">
                      <IoMdClose
                        onClick={() => {
                          setCandidate("");
                        }}
                        className="cursor-pointer"
                      />
                      <IoIosArrowDown />
                    </div>
                  </button>
                  {candidateDropdown && (
                    <div
                      id="education-drop"
                      className="candidateClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[12rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      style={{ fontFamily: "Silka" }}
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "100%" }}
                      >
                        {candidate_statuses.map((i, index) => (
                          <ul
                            key={index}
                            className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                            ref={candidateRef}
                            onClick={() => {
                              setCandidate(i.title);
                              setCandidateDropdown(false);
                            }}
                          >
                            <li>
                              <a className="block px-5 py-2 text-sm font-medium">
                                {i.title}
                              </a>
                            </li>
                          </ul>
                        ))}
                      </Scrollbars>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <button
                    id="language"
                    className={`border border-[#D3D5D8] mt-3 ${
                      lang === "What is your first language"
                        ? "text-[#999999]"
                        : "text-coalColor"
                    }  focus-within:border focus-within:border-coalColor rounded-md px-3 text-left w-full py-4`}
                    style={{
                      fontFamily: "Silka",
                    }}
                    type="button"
                    onClick={() => setLangDrop(!langDrop)}
                  >
                    {lang != "" ? lang : "What is your first language"}
                    <div className="absolute top-1/2 right-3 mt-1.5 transform text-coalColor -translate-y-1/2 flex flex-row ">
                      <IoMdClose
                        onClick={() => {
                          setLang("");
                        }}
                        className="cursor-pointer"
                      />
                      <IoIosArrowDown />
                    </div>
                  </button>
                  {langDrop && (
                    <div
                      id="education-drop"
                      className="langClass absolute z-40 border enable-scrollbar2 border-coalColor right-0 top-full h-[11rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                      style={{ fontFamily: "Silka" }}
                    >
                      <Scrollbars
                        autoHide
                        style={{ width: "100%", height: "100%" }}
                      >
                        {language
                          .sort((a, b) => a?.title.localeCompare(b.title))
                          .map((i) => (
                            <ul
                              key={i.title}
                              className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                              ref={langRef}
                              onClick={() => {
                                setLang(i.title);
                                setLangDrop(false);
                              }}
                            >
                              <li>
                                <a className="block px-5 py-2 text-sm font-medium">
                                  {i.title}
                                </a>
                              </li>
                            </ul>
                          ))}
                      </Scrollbars>
                    </div>
                  )}
                </div>
                {count === 0 ? (
                  <TextField
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    label="Enter your password"
                    rounded="rounded-md"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.firstName && "border-coalColor"
                    }`}
                    onChange={(e) => {
                      validation.handleChange(e);
                      checkingPassword(e.target.value);
                    }}
                    onFocus={() => {
                      handleFocus("password");
                      setCount(count + 1);
                    }}
                    onBlur={validation.handleBlur && handleBlur}
                    value={validation.values.password || ""}
                    placeholder="Enter your password"
                    onClick={togglePasswordVisibility}
                    isPasswordVisible={isPasswordVisible}
                  />
                ) : (
                  <TextField
                    id="password"
                    type={isPasswordVisible ? "text" : "password"}
                    name="password"
                    label="Enter your password"
                    rounded="rounded-md"
                    border={`border mt-3 border-[#D3D5D8] focus-within:border focus-within:border-coalColor ${
                      validation.values.password && "border-coalColor"
                    }`}
                    onChange={(e) => {
                      validation.handleChange(e);
                      checkingPassword(e.target.value);
                    }}
                    onFocus={() => handleFocus("password")}
                    onBlur={validation.handleBlur && handleBlur}
                    passwordIcon={passwordIcon}
                    value={validation.values.password || ""}
                    placeholder="Enter your password"
                    onClick={togglePasswordVisibility}
                    isPasswordVisible={isPasswordVisible}
                  />
                )}
                <div
                  class={`w-full ${
                    BarPercentage > 25 ? "bg-gray-300" : "bg-[#cb7b7a40]"
                  }  rounded-full h-[3px] mt-5`}
                >
                  <div
                    class={`${
                      BarPercentage > 25 ? "bg-[#252E3A]" : "bg-[#cb7b7a]"
                    } h-[3px] rounded-full`}
                    style={{ width: `${BarPercentage}%` }}
                  ></div>
                </div>
                <ul
                  class="max-w-md text-sm mt-6 pl-2 text-black list-inside space-y-1"
                  style={{ fontFamily: "Silka" }}
                >
                  <li class="flex items-center gap-2 rtl:space-x-reverse">
                    {passwordValidation.number ? (
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5 fade-in-image text-green-700 "
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    ) : (
                      <FaInfoCircle
                        className="w-4 h-4 fade-in-image"
                        color="#252E3A"
                      />
                    )}
                    <span>contains at least one number</span>
                  </li>
                  <li class="flex items-center gap-2">
                    {passwordValidation.capital ? (
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    ) : (
                      <FaInfoCircle
                        className="w-4 h-4 fade-in-image"
                        color="#252E3A"
                      />
                    )}
                    contains at least one upper character
                  </li>
                  <li class="flex items-center gap-2">
                    {passwordValidation.special ? (
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    ) : (
                      <FaInfoCircle
                        className="w-4 h-4 fade-in-image"
                        color="#252E3A"
                      />
                    )}
                    contains at least one special character
                  </li>
                  <li class="flex items-center gap-2">
                    {passwordValidation?.length ? (
                      <svg
                        class="flex-shrink-0 w-3.5 h-3.5 text-green-700 fade-in-image"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 16 12"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M1 5.917 5.724 10.5 15 1.5"
                        />
                      </svg>
                    ) : (
                      <FaInfoCircle
                        className="w-4 h-4 fade-in-image"
                        color="#252E3A"
                      />
                    )}
                    contains at least 12 characters
                  </li>
                </ul>
                <div
                  className="flex flex-row p-2 mt-6"
                  style={{ fontFamily: "Silka" }}
                >
                  <input
                    type="checkbox"
                    required
                    className="my-auto default:ring-2 ... accent-coalColor border-2 border-black w-4 h-4"
                  />
                  <p className="ml-2 sm:text-xs  md:text-sm text-coalColor">
                    I have read and accepted the{" "}
                    <b
                      className="text-coalColor font-medium cursor-pointer"
                      style={{ fontFamily: "Silka Bold" }}
                      onClick={() => handleTermsAndPrivacy("terms")}
                    >
                      candidate terms
                    </b>{" "}
                    and{" "}
                    <b
                      className="text-coalColor font-medium cursor-pointer"
                      style={{ fontFamily: "Silka Bold" }}
                      onClick={() => handleTermsAndPrivacy("policy")}
                    >
                      privacy policy
                    </b>
                  </p>
                </div>
                <div className="mb-3 mt-6 flex justify-center items-center flex-col px-1">
                  <button
                    type="submit"
                    name="Sign up"
                    disabled={BarPercentage < 100}
                    className={` ${
                      BarPercentage < 100
                        ? "bg-[#D3D5D8] text-[#7C8289]"
                        : "bg-primaryGreen text-coalColor border border-coalColor"
                    } w-full h-12 rounded-lg font-medium sm:text-sm md:text-lg relative`}
                  >
                    Next
                  </button>
                </div>
                <span
                  className="text-coalColor text-sm text-center p-2"
                  style={{ fontFamily: "Silka" }}
                >
                  Already have an account?{" "}
                  <span
                    style={{ fontFamily: "Silka Bold" }}
                    className="text-coalColor font-medium cursor-pointer"
                    onClick={() => navigate("/candidate/login")}
                  >
                    Log in here
                  </span>
                </span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
