import http from "../../../../../http"

export const getAssessmentDetails = async (id, userID) => {
    const response = await http.get(`/assessment/candidate/result/assessment/${id}/user/${userID}`)
    const {
        webcamOpenCount,
        webcamCloseCount,
        fullscreenOpenCount,
        fullscreenCloseCount,
        mouseInAssessmentWindowOpenCount,
        mouseInAssessmentWindowCloseCount,
        TotalCount,
    } = response?.data?.evaloation_Response || {};

    const parse = (value) => parseInt(value || 0, 10);

    const webopen = (parse(webcamOpenCount) / parse(TotalCount)) * 100;
    const webclose = (parse(webcamCloseCount) / parse(TotalCount)) * 100;
    const fullscreen = (parse(fullscreenOpenCount) / parse(TotalCount)) * 100;
    const halfscreen = (parse(fullscreenCloseCount) / parse(TotalCount)) * 100;
    const mouses = (parse(mouseInAssessmentWindowOpenCount) / parse(TotalCount)) * 100;
    const mousea = (parse(mouseInAssessmentWindowCloseCount) / parse(TotalCount)) * 100;

    response.data["Webcam"] = (webopen > webclose);
    response.data["full"] = (fullscreen > halfscreen);
    response.data["mouse"] = (mouses > mousea);
    response.data["LogsLoad"] = (false);
    return response.data
}