import React, { useEffect, useState } from "react";
import Logo from "../../../../Assets/mainLOGO.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import ".././auth.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import TextField from "../../../../Components/Dexta/TextField/TextField";
import Loader from "react-loader-spinner";
import { forgetEmail } from "../../hooks/forgetEmail";
import queryString from "query-string";

const EmailSent = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parseOptions = { decode: false };
  const parsed = queryString.parse(location.search, parseOptions);

  //#region validation with formik
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Email Required"),
    }),
    onSubmit: (values) => {
      let data = JSON.stringify({
        email: values?.email,
      });
      try {
        mutate(data);
      } catch (error) {
        //onError will automatically detect
      }
    },
  });
  //#endregion

  //#region login with candidate
  const { mutate, isLoading } = useMutation(forgetEmail, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("/auth/forgot-password");
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.message;
      if (Array.isArray(errorMessage)) {
        setTimeout(() => {
          toast.error(errorMessage[0]);
        }, 500);
      } else if (typeof errorMessage === "string") {
        setTimeout(() => {
          toast.error(errorMessage);
        }, 500);
      } else {
        toast.error("An error occurred.");
      }
    },
  });
  //#endregion

  //#region opening emailbox
  const handleOpenEmailInboxButtonClick = () => {
    // Constructing the mailto link without any additional parameters
    const mailtoLink = "mailto:";

    // Opening the default email client
    window.location.href = mailtoLink;
  };
  //#endregion

  //#region api call for forget passwowrd
  const { mutate: mutateEmail, isLoading: emailLoading } = useMutation(
    forgetEmail,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/auth/forgot-password");
        if (response.message === "Reset password email sent") {
          setTimeout(() => {
            toast.success("Email Sent");
          }, 500);
        }
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;
        if (Array.isArray(errorMessage)) {
          setTimeout(() => {
            toast.error(errorMessage[0]);
          }, 500);
        } else if (typeof errorMessage === "string") {
          setTimeout(() => {
            toast.error(errorMessage);
          }, 500);
        } else {
          toast.error("An error occurred.");
        }
      },
    }
  );
  //#endregion

  //#region Submitting Email
  const handleEmailSubmit = () => {
    let data = JSON.stringify({
      email: parsed?.email,
    });
    try {
      mutateEmail(data);
    } catch (error) {}
  };
  //#endregion

  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex justify-center px-2 lg:px-0 h-screen">
        <div className="m-auto w-full lg:w-2/5 rounded-md bg-white">
          <div className="rounded-lg">
            <div className="sm:p-5 lg:p-14">
              <img src={Logo} className="h-11 w-40" alt="CP Logo" />
              <h1
                className="pt-10 text-xl font-bold"
                style={{ fontFamily: "Archia Bold" }}
              >
                Check your email
              </h1>
              <p
                className="sm:text-xs md:text-sm mt-5 text-black"
                style={{ fontFamily: "Silka" }}
              >
                We sent a password reset link to
              </p>
              <p>{parsed?.email}</p>

              <div className="mb-3 mt-6 flex justify-center items-center flex-col">
                <button
                  type="submit"
                  name="Sign up"
                  style={{ fontFamily: "Silka" }}
                  onClick={handleOpenEmailInboxButtonClick}
                  className="bg-primaryGreen text-coalColor w-full h-12 rounded-lg border border-coalColor font-medium sm:text-sm md:text-lg relative"
                >
                  {isLoading ? (
                    <span className="flex items-center justify-center">
                      <Loader
                        type="Oval"
                        color="black"
                        height={20}
                        width={20}
                      />
                      <span className="ml-2">Logging in...</span>
                    </span>
                  ) : (
                    "Open Email App"
                  )}
                </button>
              </div>
              <span
                className="text-coalColor text-sm text-center"
                style={{ fontFamily: "Silka" }}
              >
                Didn’t receive the email?{" "}
                <span
                  className="text-coalColor font-medium cursor-pointer"
                  style={{ fontFamily: "Silka Bold" }}
                  onClick={handleEmailSubmit}
                >
                  Click to resend
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailSent;
