import React, { useState } from "react";
import { getAssessmentByID } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getAssessmentByID";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { MdOutlineCopyAll } from "react-icons/md";
import { toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import Alertinfo from "../../Dexta_assets/alertinfo.png";
const InviteByLink = ({ code, setCopyCount, copyCount }) => {
  const { id } = useParams();
  const { data: assessmentData } = useQuery(["assessment", id], () =>
    getAssessmentByID(id)
  );

  const copytoClip = (code) => {
    try {
      setCopyCount(copyCount + 1);
      let url = process.env.REACT_APP_STATIC_SITE + "/confirmation/" + code;
      const textArea = document.createElement("textarea");
      const codeToCopy = url.toString();
      textArea.value = codeToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.success("The invitation link has been copied.");
    } catch (error) {
      console.error(error);
      alert("Copy failed");
    }
  };

  return (
    <>
      <div className="md:h-[25rem] mt-5 bg-[#f6f6f6] sm:px-1 rounded-lg md:px-6 py-8">
        <div className="py-3 px-3 bg-[#DBD9C0] rounded-lg flex flex-row gap-3">
          <img src={Alertinfo} className="w-5 h-5 my-auto" />
          <p style={{ fontFamily: "Silka" }}>
            Share a unique link with each candidate to track their test
            completion progress. Remember to regenerate the link for each new
            candidate
          </p>
        </div>
        <p className="text-base font-medium mt-3">URL</p>
        <div className="flex flex-row mt-3 w-full">
          <div className="relative border flex-grow select-none border-1 rounded-lg border-black bg-white">
            <input
              type="text"
              className={`w-full ${
                assessmentData?.status === "draft" ||
                assessmentData?.status === "archived"
                  ? "blur-sm"
                  : "blur-none"
              } p-4 mt-1 rounded-lg sm:text-xs md:text-sm bg-[#eef4f757] lg:block sm:hidden outline-none`}
              value={
                process.env.REACT_APP_STATIC_SITE + "/confirmation/" + code
              }
              readOnly
              style={{ userSelect: "none" }}
              onMouseDown={(e) => e.preventDefault()}
            />
            {/* Input for medium screens */}
            <input
              type="text"
              className="w-full p-4 rounded-lg sm:text-xs md:text-sm bg-[#eef4f757] lg:hidden md:block sm:hidden outline-none"
              value={process.env.REACT_APP_STATIC_SITE + "/..."}
              readOnly
              style={{ userSelect: "none" }}
              onMouseDown={(e) => e.preventDefault()}
            />
            {/* Input for small screens */}
            <input
              type="text"
              className="w-full p-4 rounded-lg sm:text-xs md:text-sm bg-[#eef4f757] sm:block md:hidden outline-none"
              value={process.env.REACT_APP_STATIC_SITE + "/..."}
              readOnly
              style={{ userSelect: "none" }}
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>

          {/* Button Container */}
          <div className="ml-4">
            <div className="relative group">
              <CustomButton
                label="Regenerate and copy"
                icon={MdOutlineCopyAll}
                bgColor="#252E3A"
                textColor="#C0FF06"
                onClickButton={() => copytoClip(code)}
                disableField={
                  assessmentData?.status === "draft" ||
                  assessmentData?.status === "archived"
                }
                borderCustom="border border-coalColor text-white w-auto"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                paddingY="0.9rem"
                Isrelative={false}
              />
              {assessmentData?.status === "draft" ||
              assessmentData?.status === "archived" ? (
                <div className="tooltip2 w-[12rem] text-center font-medium hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 bg-white border border-gray-300 p-2 rounded shadow-lg">
                  {assessmentData?.status === "draft" ||
                  assessmentData?.status === "archived"
                    ? `This action is not available for ${
                        assessmentData?.status === "draft"
                          ? "draft"
                          : "archived"
                      } tests.`
                    : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {/* <p className="text-base mt-8 font-medium">
          COPY AND ADD THIS TEXT TO YOUR JOB POST
        </p> */}
        <div className="border border-black p-5 w-full mt-3 bg-white">
          <p>
            We want to ensure that all applicants have a fair and equal chance,
            so we're using a Dexta (
            <a
              className="cursor-pointer text-coalColor"
              href={process.env.REACT_APP_STATIC_SITE}
            >
              {process.env.REACT_APP_STATIC_SITE}
            </a>
            ). This minimizes unconscious bias in our hiring process. Successful
            candidates will be invited to a job interview.
          </p>
          <div className="flex flex-col justify-between mt-4">
            <div className="w-full sm:w-auto">
              <p className="break-words">
                Ready to begin your test? Start by clicking here.
              </p>
              <div className="flex justify-between lg:flex-row sm:flex-col lg:space-x-4 whitespace-nowrap">
                <a
                  href={
                    assessmentData?.status !== "draft"
                      ? `${process.env.REACT_APP_STATIC_SITE}/confirmation/${code}`
                      : null
                  }
                  className={`text-coalColor select-none block break-all truncate ${
                    assessmentData?.status === "draft" ||
                    assessmentData?.status === "archived"
                      ? "blur-sm"
                      : "blur-none"
                  } w-full sm:w-auto`}
                >
                  {assessmentData?.status !== "draft" ||
                  assessmentData?.status !== "archived"
                    ? `${process.env.REACT_APP_STATIC_SITE}/confirmation/${code}`
                    : `${process.env.REACT_APP_STATIC_SITE}/confirmation/${code}`}
                </a>
                <div className="relative group ml-auto">
                  <button
                    className={`border-b border-black my-auto block ${
                      assessmentData?.status === "draft" ||
                      assessmentData?.status === "archived"
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={() => copytoClip(code)}
                    disabled={
                      assessmentData?.status === "draft" ||
                      assessmentData?.status === "archived"
                    }
                  >
                    <span>Regenerate and copy</span>
                    <span className="ml-1">invitation</span>
                  </button>
                  {assessmentData?.status === "draft" ||
                  assessmentData?.status === "archived" ? (
                    <div className="tooltip2 w-[12rem] text-center font-medium hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mt-2 bg-white border border-gray-300 p-2 rounded shadow-lg">
                      {assessmentData?.status === "draft" ||
                      assessmentData?.status === "archived"
                        ? `This action is not available for ${
                            assessmentData?.status === "draft"
                              ? "draft"
                              : "archived"
                          } tests.`
                        : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteByLink;
