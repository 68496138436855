import React, { useState, useRef, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import { roles } from "../../Pages/Profile/MyAssessments/Assessments-main/data";
import { IoMdArrowDropdown } from "react-icons/io";
import TextFieldCustom from "../TextField/TextFieldCustom";
import { FiPlusCircle } from "react-icons/fi";
import { IoSettingsOutline } from "react-icons/io5";
import CustomButton from "../CustomButton/CustomButton";
import "./fadeIn.css";
import TeamMember from "./AddNewMember";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { getTeamMembers } from "../hooks/getTeamMemebers";
import { IoMdCheckmark } from "react-icons/io";
import Scrollbars from "react-custom-scrollbars";
import { getSelectedModules } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getSelectedModulesByID";
import { useParams } from "react-router-dom";
import SearhBar from "../../Dexta_assets/searchBar.png";
import useWindowSize from "../../Helpers/useWindowSize";
import GeneralModal from "../Modals/GeneralModal";
import { useSelector } from "react-redux";
import King from "../../Assets/preee.png";
import bin from "../../Dexta_assets/bin.png";
import Premium from "../Modals/Premium";
import { Deleteteammember } from "../hooks/Deleteteammember";
import { toast } from "react-toastify";

const TeamMembers = ({ margin = null, settingsBtn = false }) => {
  const roleRef = useRef(null);
  const [searchTeamMember, setSearchTeamMember] = useState("");
  const [roleDropdown, setRoleDropdown] = useState(false);
  const [selecteditem, setselecteditem] = useState("All Roles");
  const [selectedJob, setSelectedJob] = useState("");
  const [teamMemberModal, setTeamMemberModal] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);
  const [generalModal, setGeneralModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [description, setdescription] = useState("");
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  const { id } = useParams();
  const [premiumOpen, setPremiumOpen] = useState(false);
  const queryClient = useQueryClient();

  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    Deleteteammember,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/users/delete-team-member");
        toast.success("Member removed");
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;
        if (Array.isArray(errorMessage)) {
          setTimeout(() => {
            toast.error(errorMessage[0]);
          }, 500);
        } else if (typeof errorMessage === "string") {
          setTimeout(() => {
            toast.error(errorMessage);
          }, 500);
        } else {
          setTimeout(() => {
            toast.error("An error occurred.");
          }, 500);
        }
      },
    }
  );
  //#endregion

  const {
    data: TeamData,
    isloading: TeamLoading,
    error: TeamError,
  } = useQuery({
    queryKey: ["/users/fetch-team-members", selectedJob, searchTeamMember],
    queryFn: () => getTeamMembers(selectedJob, searchTeamMember),
  });

  const DeleteMember = (ID) => {
    deleteMutate(ID);
  };
  useEffect(() => {
    function handleoutsideField(event) {
      if (
        roleRef.current &&
        !roleRef.current.contains(event.target) &&
        event.target.id !== "role" &&
        !event.target.closest(".rolesClass")
      ) {
        setRoleDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideField);
    return () => {
      document.removeEventListener("mousedown", handleoutsideField);
    };
  }, [roleRef]);

  return (
    <React.Fragment>
      <TeamMember
        teamMemberModal={teamMemberModal}
        setTeamMemberModal={setTeamMemberModal}
        TeamAllowed={TeamData?.data?.length}
      />
      <GeneralModal
        generalModal={generalModal}
        setGeneralModal={setGeneralModal}
        heading={heading}
        description={description}
      />
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <div className="sm:mt-0 md:mt-10 bg-white fade-in-div">
        <div className="flex md:flex-row sm:flex-col justify-between md:mt-6">
          <h1
            className="text-base mt-5 md:ml-2 font-bold"
            style={{ fontFamily: "Archia Bold" }}
          >
            TEAM MEMBERS
          </h1>
          <div className="flex sm:flex-col md:flex-row gap-4 my-auto">
            <div className="md:w-[25rem] sm:mt-4 md:mt-0">
              <TextFieldCustom
                label="Search assessments"
                value={searchTeamMember}
                onChangeValue={(e) => setSearchTeamMember(e.target.value)}
                backgroundTextColor="bg-[#F0F0F0]"
                thickBorder="border-[1px]"
                topTextWeight="font-medium"
                imgIcon={SearhBar}
                placeholder="Search"
              />
            </div>
            <div className="flex flex-col gap-3 relative">
              <TextFieldCustom
                id="role"
                label="Role"
                value={selecteditem}
                backgroundTextColor="bg-[#F0F0F0]"
                thickBorder="border-[1px]"
                topTextWeight="font-medium"
                icon={IoMdArrowDropdown}
                onClick={() => setRoleDropdown(!roleDropdown)}
                addionalClasses="cursor-pointer user-select-none caret-transparent"
              />
              {roleDropdown && (
                <div
                  id="education-drop"
                  className="rolesClass absolute z-40 border border-coalColor right-0 top-full h-[11.4rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                  ref={roleRef}
                >
                  {roles.map((i) => (
                    <ul
                      key={i.title}
                      className="text-sm text-coalColor rounded hover:bg-coalColor hover:text-white cursor-pointer"
                      onClick={() => {
                        setselecteditem(i?.title);
                        setSelectedJob(i?.value);
                        setRoleDropdown(false);
                      }}
                    >
                      <li>
                        <a className="block px-5 py-2 text-sm font-medium">
                          {i?.title}
                        </a>
                      </li>
                    </ul>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <body
          class={`flex w-full ${
            isMobile && "hidden"
          } h-[25rem] px-2 mt-8 bg-white`}
        >
          <div class="flex flex-col w-full sm:rounded-lg">
            <div
              class="flex flex-shrink-0 px-6 bg-[#F6F7F7] text-black text-sm uppercase font-medium"
              style={{ fontFamily: "Archia Semibold" }}
            >
              <div
                scope="col"
                class="flex items-center flex-grow sm:w-[20%] md:w-[12%] h-12"
              >
                Name
              </div>
              <div scope="col" class="flex items-center flex-grow w-[12%] h-12">
                Email
              </div>
              <div scope="col" class="flex items-center flex-grow w-0 h-12">
                Role
              </div>
              <div scope="col" class="flex items-center flex-grow w-0 h-12">
                Invitation
              </div>
              <div scope="col" class="flex items-center flex-grow w-0 h-12">
                Action
              </div>
            </div>
            <div
              class={`overflow-y-scroll h-[25rem] ${
                TeamData?.data?.length > 5 && "enable-scrollbar2"
              }`}
              style={{ fontFamily: "Silka" }}
            >
              <Scrollbars
                style={{ width: "100%", height: "100%", overflowX: "hidden" }}
              >
                {TeamData?.data?.map((i) => (
                  <div class="flex flex-shrink-0 py-4 px-6 text-sm">
                    <div class="flex items-center flex-grow sm:w-[20%] md:w-[12%] h-10 border-black">
                      {i?.firstName} {i?.lastName}
                    </div>
                    <div class="flex items-center flex-grow w-[12%] h-10 border-black">
                      {i?.email}
                    </div>
                    <div class="flex items-center flex-grow w-0 h-10 border-black">
                      <div
                        className={`border ${
                          i?.accountType === "owner" &&
                          "border-[#0B5B23] text-coalColor"
                        } ${
                          i?.accountType === "admin" &&
                          "border-[#FFB500] text-coalColor"
                        } ${
                          i?.accountType === "recruiter" &&
                          "border-[#FF5812] text-coalColor"
                        } ${
                          i?.accountType === "hiring-manager" &&
                          "border-[#C0FF06] text-coalColor"
                        } w-fit px-4 py-0.5 rounded-md`}
                      >
                        {i?.accountType === "owner" && "Owner"}
                        {i?.accountType === "admin" && "Admin"}
                        {i?.accountType === "recruiter" && "Editor"}
                        {i?.accountType === "hiring-manager" && "Viewer"}
                      </div>
                    </div>
                    <div class="flex items-center flex-grow w-0 h-10 border-black">
                      <IoMdCheckmark className="mr-2 w-5 h-5 my-auto" />
                      Sent
                    </div>
                    {i?.accountType === "owner" ? (
                      <div class="flex items-center flex-grow w-0 h-10 border-black"></div>
                    ) : (
                      <div
                        class="flex items-center flex-grow w-0 h-10 border-black cursor-pointer"
                        onClick={() => DeleteMember(i?.id)}
                      >
                        <img src={bin} className="mr-2 w-5 h-5 my-auto" />
                        Remove member
                      </div>
                    )}
                  </div>
                ))}
              </Scrollbars>
            </div>
          </div>
        </body>
        <body class="flex w-full md:hidden h-[20rem] px-2 mt-8 bg-white">
          <div class="flex flex-col w-full sm:rounded-lg">
            <div
              class={`overflow-y-auto h-[25rem] ${
                TeamData?.data?.length > 5 && "enable-scrollbar2"
              }`}
              style={{ fontFamily: "Silka" }}
            >
              <Scrollbars
                style={{ width: "100%", height: "100%", overflowX: "auto" }}
              >
                <table class="min-w-full">
                  <thead>
                    <tr
                      class="bg-white font-bold"
                      style={{ fontFamily: "Archia Bold" }}
                    >
                      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900 ">
                        Name
                      </td>
                      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        Email
                      </td>
                      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        Role
                      </td>
                      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        Invitation
                      </td>
                      <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                        Action
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {TeamData?.data?.map((i) => (
                      <tr class="bg-white">
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 ">
                          {i?.firstName} {i?.lastName}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {i?.email}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <div
                            className={`border ${
                              i?.accountType === "owner" &&
                              "border-[#0B5B23] text-coalColor"
                            } ${
                              i?.accountType === "admin" &&
                              "border-[#FFB500] text-coalColor"
                            } ${
                              i?.accountType === "recruiter" &&
                              "border-[#FF5812] text-coalColor"
                            } ${
                              i?.accountType === "hiring-manager" &&
                              "border-[#C0FF06] text-coalColor"
                            } w-fit px-4 py-0.5 rounded-md`}
                          >
                            {i?.accountType === "owner" && "Owner"}
                            {i?.accountType === "admin" && "Admin"}
                            {i?.accountType === "recruiter" && "Editor"}
                            {i?.accountType === "hiring-manager" && "Viewer"}
                          </div>
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          <IoMdCheckmark className="mr-2 w-5 h-5 my-auto" />
                          Sent
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </body>
        <div className="mt-10 flex justify-between">
          <div className={`flex flex-row ${margin && margin}`}>
            {settingsBtn ? (
              <div className="relative">
                <CustomButton
                  label="Add team member"
                  iconWidth={5}
                  iconHeight={5}
                  textSize="font-medium"
                  hoverBgColor="#252E3A"
                  hoverTextColor="#FFFFFF"
                  bgColor="#C0FF06"
                  widthButton="w-full"
                  icon={FiPlusCircle}
                  borderCustom="border border-coalColor text-coalColor"
                  onClickButton={() => {
                    if (user_package_check === "free") {
                      setPremiumOpen(true);
                    } else {
                      if (
                        user_package_check === "pro" &&
                        TeamData?.data?.length === 5
                      ) {
                        setHeading("You’ve reached your limit!");
                        setdescription(
                          user_package_check === "free"
                            ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                            : "To add more than 5 team members, upgrade your subscription package to Enterprise"
                        );
                        setGeneralModal(true);
                      } else {
                        setTeamMemberModal(true);
                      }
                    }
                  }}
                />
                {(user_package_check === "free" ||
                  (user_package_check === "pro" &&
                    TeamData?.data?.length === 5)) && (
                  <span className="absolute -top-2 right-0 -mr-2">
                    <img src={King} alt="Premium Icon" className="w-5 h-5" />
                  </span>
                )}
              </div>
            ) : (
              <div className="relative">
                <CustomButton
                  label="Add team member"
                  iconWidth={5}
                  iconHeight={5}
                  textSize="font-medium"
                  hoverBgColor="#252E3A"
                  hoverTextColor="#FFFFFF"
                  bgColor="#C0FF06"
                  widthButton="w-full"
                  icon={FiPlusCircle}
                  borderCustom="border border-coalColor text-coalColor"
                  onClickButton={() => {
                    if (user_package_check === "free") {
                      setPremiumOpen(true);
                    } else {
                      if (
                        user_package_check === "pro" &&
                        TeamData?.data?.length === 5
                      ) {
                        setHeading("You’ve reached your limit!");
                        setdescription(
                          user_package_check === "free"
                            ? "Explore For Free subscription allows 1 Active Test, either change the status of your Active Test to ‘Draft’ or upgrade your subscription package"
                            : "To add more than 5 team members, upgrade your subscription package to Enterprise"
                        );
                        setGeneralModal(true);
                      } else {
                        setTeamMemberModal(true);
                      }
                    }
                  }}
                />
                {(user_package_check === "free" ||
                  (user_package_check === "pro" &&
                    TeamData?.data?.length === 5)) && (
                  <span className="absolute -top-2 right-0 -mr-2">
                    <img src={King} alt="Premium Icon" className="w-5 h-5" />
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="flex flex-row gap-4"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamMembers;
