import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getSections } from "../hooks/getSections";
import { FaCheck, FaCircle } from "react-icons/fa";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import "../../../Components/Loading/Loading4.css";
import "../Ready/ready.css";

const Preview_Ready = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState(10);
  const assessmentID = localStorage.getItem("CANDIDATE-ASSESSMENT-ID");
  const practice_section = JSON?.parse(
    localStorage.getItem("practice_section")
  );
  const practice_module_data = JSON?.parse(
    localStorage.getItem("practice_module_data")
  );
  const timecheck = JSON?.parse(localStorage.getItem("practice_time"));
  const [isIntervalRunning, setIsIntervalRunning] = useState(true);
  const [value, setValue] = useState(new Date());
  const [isHovered, setIsHovered] = useState(false);
  const theme = JSON?.parse(localStorage.getItem("theme"));
  const completion_check = localStorage.getItem("test_completed");
  const user_exists = localStorage.getItem("user_exists");
  //#region Resetting module time
  useEffect(() => {
    timecheck.timeLeft = 0;
    timecheck.seconds = 0;
    localStorage.setItem("practice_time", JSON.stringify(timecheck));
  }, []);
  //#endregion

  //#region Timer for test
  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 50000000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    let interval;
    if (isIntervalRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time === 0) {
      const currentSection = JSON?.parse(
        localStorage.getItem("practice_section")
      );
      localStorage.setItem("practice_section", JSON.stringify(currentSection));
      currentSection.pageQuestion = 1;
      localStorage.setItem("practice_section", JSON.stringify(currentSection));
      console.log(currentSection.CurrentModule);
      navigate("/practice-questions");
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, navigate, isIntervalRunning]);

  const toggleInterval = () => {
    setIsIntervalRunning((prevIsIntervalRunning) => !prevIsIntervalRunning);
    if (!isIntervalRunning) {
      setTime(0);
    }
  };

  //#endregion

  //#region handle back browser
  useEffect(() => {
    const handlePopState = (event) => {
      window.history.pushState(null, null, window.location.href);
    };

    // Push the current state to the history stack initially
    window.history.pushState(null, null, window.location.href);

    // Listen for popstate events
    window.addEventListener("popstate", handlePopState);

    // Push the state whenever the user attempts to navigate back
    handlePopState();

    return () => {
      // Cleanup the event listener
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  //#endregion

  //#region Fetching section details
  const { data, error, isLoading } = useQuery(
    ["/section/byAssessmentId", assessmentID],
    () => getSections(assessmentID)
  );

  //#endregion

  //#region Hover animation on "Take break" button
  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    background: isHovered ? `rgba(${theme.color}, 1.9)` : theme.color,
    transition: "background-color 0.3s, transform 0.6s",
    transform: isHovered ? "scale(1.03)" : "scale(1)",
  };
  //#endregion

  //#region Functions to Lighten or Darken color
  function DarkenColor(col, amt) {
    var usePound = false;

    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) - amt;

    if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) - amt;

    if (b < 0) b = 0;

    var g = (num & 0x0000ff) - amt;

    if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  }
  var DarkenedColor = DarkenColor(theme.color, 50);
  //#endregion

  //#region useEffect to handle local states
  useEffect(() => {
    data?.data?.forEach((i, ind) => {
      i?.section.forEach((j, index) => {
        practice_module_data.last = i?.section?.length;
        localStorage.setItem(
          "practice_module_data",
          JSON.stringify(practice_module_data)
        );

        if (index === parseInt(practice_section.CurrentModule)) {
          if (j?.isPreview === true) {
            practice_module_data.current = index + 1;
            localStorage.setItem(
              "practice_module_data",
              JSON.stringify(practice_module_data)
            );

            const ModuleID = JSON?.parse(
              localStorage.getItem("practice_section")
            );
            ModuleID.ModuleID = j.id;
            localStorage.setItem("practice_section", JSON.stringify(ModuleID));

            const ModuleName = JSON?.parse(
              localStorage.getItem("practice_section")
            );
            ModuleName.module_Name = j.name;
            localStorage.setItem(
              "practice_section",
              JSON.stringify(ModuleName)
            );

            const timeMod = JSON?.parse(
              localStorage.getItem("practice_section")
            );
            timeMod.time = 5;
            localStorage.setItem("practice_section", JSON.stringify(timeMod));

            const minsec = JSON?.parse(
              localStorage.getItem("practice_min_sec")
            );
            minsec.secleft = 5 * 60;
            localStorage.setItem("practice_min_sec", JSON.stringify(minsec));
          } else {
            practice_module_data.current = index + 1;
            localStorage.setItem(
              "practice_module_data",
              JSON.stringify(practice_module_data)
            );
            navigate("/get-ready");
          }
        }
      });
    });
  }, [data, practice_section, practice_module_data, navigate]);
  //#endregion

  //#region check if user completed feedback
  useEffect(() => {
    if (completion_check === "yes") {
      navigate("/feedback");
    } else if (user_exists === "yes") {
      navigate("/candidate/dashboard");
    } else if (user_exists === "no") {
      navigate("/candidate/login");
    }
  }, []);
  //#endregion

  document.title = "Get Ready | Dexta";
  return (
    <>
      <nav
        className={`xl:fixed  2xl:fixed w-full z-20 top-0 left-0 py-2 bg-white`}
      >
        <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
          <div className="grid grid-cols-3 container mx-auto">
            <img
              src={theme.logo}
              className=" object-contain"
              style={{ height: "70px" }}
            />
          </div>
        </div>
      </nav>
      <div className="bg-[#F0F0F0]">
        <div className="mx-auto lg:container">
          <div className="flex justify-center h-screen xl:max-xxl:mt-10 sm:max-md:px-4">
            <div className="m-auto sm:max-md:w-full w-2/3 2xl:w-1/2 rounded-md bg-white relative overflow-hidden">
              <div className="rounded-lg">
                {isLoading ? (
                  <div class="loader-container-3 col-span-6">
                    <div class="loader-3"></div>
                  </div>
                ) : (
                  <div className="lg:p-8 lg:px-12 sm:max-md:p-6">
                    <p className="text-[#767676] gap-1 flex flex-row">
                      {Array.from(
                        { length: practice_module_data.current },
                        (_, index) => (
                          <FaCheck
                            key={index}
                            className="text-green-500 w-4 h-4"
                          />
                        )
                      )}
                      {Array.from(
                        {
                          length:
                            practice_module_data.last -
                            practice_module_data.current,
                        },
                        (_, index) => (
                          <FaCircle key={index} className="text-gray-400" />
                        )
                      )}
                    </p>{" "}
                    <b
                      className="text-coalColor font-bold mt-1"
                      style={{ fontFamily: "Silka" }}
                    >
                      This is the practice module:
                    </b>
                    {data?.data?.map((i, ind) => (
                      <React.Fragment key={ind}>
                        {i?.section.map((j, index) => (
                          <React.Fragment key={index}>
                            {index ===
                              parseInt(practice_section.CurrentModule) && (
                              <h1
                                className="text-base mt-7"
                                style={{ fontFamily: "Silka" }}
                              >
                                <span className="py-0.5 lg:px-8 sm:max-md:px-2 rounded-xl bg-[#252E3A1A]">
                                  {j?.name}
                                </span>
                              </h1>
                            )}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                    <p
                      className="text-[#767676] mt-1"
                      style={{ fontFamily: "Silka" }}
                    >
                      The practice module starts now.
                    </p>
                    <Clock value={value} className="mx-auto mt-3 " />
                    <div>
                      {isIntervalRunning && (
                        <h1
                          className="text-lg font-medium text-center mt-5"
                          style={{ fontFamily: "Silka Light" }}
                        >
                          The module will start in{" "}
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            {time}{" "}
                          </span>
                          <span
                            className="text-coalColor"
                            style={{ fontFamily: "Silka Bold" }}
                          >
                            seconds
                          </span>
                        </h1>
                      )}
                    </div>
                    <p
                      className="text-coalColor mt-8"
                      style={{ fontFamily: "Silka" }}
                    >
                      Please stay on this screen. The timer for your next module
                      has started, and it cannot be paused.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Preview_Ready;
